var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.config.name != 'shippingsHistories' && _vm.config.name != 'stockHistories' && _vm.config.name != 'statusHistories' ? _c('div-filter', {
    attrs: {
      "name": _vm.config.name,
      "config": _vm.config,
      "filters": _vm.filters,
      "currentUser": _vm.currentUser,
      "idWharhouse": _vm.idWharhouse
    },
    on: {
      "reset": _vm.reset,
      "filtrer": _vm.filtrer,
      "search": _vm.search,
      "searchSellers": _vm.searchSellers,
      "searchCouriers": _vm.searchCouriers
    }
  }) : _vm._e(), _c('div', {
    class: _vm.config.class
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow"
  }, [_vm.config.name == 'expeditions' && (_vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO') || _vm.config.name == 'products' ? _c('div', [_c('div', {
    staticClass: "py-4 cursor-pointer bg-grey-lighter hover:bg-grey-light text-indigo-darker rounded rounded-t-none text-center uppercase font-bold flex items-center justify-center text-green-600",
    on: {
      "click": function click($event) {
        _vm.openAnalytics = !_vm.openAnalytics;
      }
    }
  }, [_c('span', {
    staticClass: "block border border-gray-400 w-1/3 mr-2"
  }), _c('button', {
    staticClass: "border border-teal-500 text-teal-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("Show analytics")) + " "), _c('i', {
    staticClass: "material-icons text-green-600 ml-3"
  }, [_vm._v("timeline")])]), _c('span', {
    staticClass: "block border border-gray-400 w-1/3 ml-2"
  })]), _vm.openAnalytics && _vm.config.name == 'expeditions' ? _c('chart-expeditions', {
    attrs: {
      "currentUser": _vm.currentUser
    }
  }) : _vm._e(), _vm.openAnalytics && _vm.config.name == 'products' ? _c('chart-products', {
    attrs: {
      "currentUser": _vm.currentUser
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6"
  }, [_c('div', {
    staticClass: "flex w-1/3"
  }, [_vm.config.icone ? _c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v(_vm._s(_vm.config.icone))]) : _vm._e(), _c('span', {
    staticClass: "mx-4 text-2xl font-bold text-green-600"
  }, [_vm._v(_vm._s(_vm.$t(_vm.config.title)))]), _vm.config.name == 'shippings' || _vm.config.name == 'orders' ? _c('div', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.awayLimit,
      expression: "awayLimit"
    }]
  }, [_c('button', {
    staticClass: "vs-con-dropdown parent-dropdown cursor-pointer",
    on: {
      "click": function click($event) {
        _vm.openLimit = !_vm.openLimit;
      }
    }
  }, [_c('div', {
    staticClass: "p-2 shadow rounded-lg cursor-pointer flex items-end justify-center text-sm font-medium"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.paginate.currentpage || 1) + " - " + _vm._s(_vm.data.length) + " / " + _vm._s(_vm.paginate.total || _vm.data.length) + " ")]), _c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-chevron-down h-4 w-4",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "6 9 12 15 18 9"
    }
  })])])])]), _c('div', {
    staticClass: "relative"
  }, [_vm.openLimit ? _c('div', {
    staticClass: "absolute focus:ring-2 focus:ring-blue-600 right-0 dropdown-action-limit w-auto mt-3 z-10 origin-top-right rounded-md shadow-lg"
  }, [_c('div', {
    staticClass: "bg-white rounded-md shadow dark-mode:bg-gray-800 dropdown-limit"
  }, [_c('a', {
    staticClass: "block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline",
    on: {
      "click": function click($event) {
        return _vm.Changelimit(50);
      }
    }
  }, [_vm._v("50")]), _c('a', {
    staticClass: "block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline",
    on: {
      "click": function click($event) {
        return _vm.Changelimit(100);
      }
    }
  }, [_vm._v("100")]), _c('a', {
    staticClass: "block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline",
    on: {
      "click": function click($event) {
        return _vm.Changelimit(200);
      }
    }
  }, [_vm._v("200")]), _c('a', {
    staticClass: "block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline",
    on: {
      "click": function click($event) {
        return _vm.Changelimit(300);
      }
    }
  }, [_vm._v("300")])]), _c('div', {
    staticClass: "vs-dropdown--limit--after"
  })]) : _vm._e()])]) : _vm._e()]), _c('div', {
    staticClass: "w-4/6 flex relative justify-end space-x-4"
  }, [_vm.config.options.actions.isActive && (!_vm.config.options.actions.method || _vm.config.options.actions.link) && _vm.getAccess('create', null, null, true) ? _c('div', {
    staticClass: "w-1/2 flex justify-end space-x-4"
  }, _vm._l(_vm.config.options.actions.buttons, function (item, index) {
    return item.link ? _c('router-link', {
      key: index,
      staticClass: "rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
      attrs: {
        "to": item.link
      }
    }, [_c('i', {
      staticClass: "material-icons mr-2"
    }, [_vm._v(_vm._s(item.icon))]), _vm._v(" " + _vm._s(_vm.$t(item.text)))]) : _vm._e();
  }), 1) : _vm._e(), _vm.config.name == 'orders' || _vm.config.name == 'shippings' ? _c('div', {
    staticClass: "ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.downloadExcel
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("file_download")]), _vm._v(" Excel ")]) : _vm._e(), _vm.config.name == 'shippings' && (_vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'StockManager') ? _c('div', {
    staticClass: "ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopupModal('open', 'dauchette');
      }
    }
  }, [_c('i', {
    staticClass: "material-icons mr-2"
  }, [_vm._v("perm_scan_wifi ")]), _vm._v(" Douchette ")]) : _vm._e(), _vm.config.name == 'shippings' && (_vm.currentUser.type == 'CEO' || _vm.currentUser.email == 'oumaimacodinafrica@gmail.com') ? _c('div', {
    staticClass: "ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopupModal('open', 'dauchette', 'previous');
      }
    }
  }, [_c('svg', {
    staticClass: "fill-current mr-1",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z"
    }
  })]), _vm._v(" Previous status ")]) : _vm._e(), _vm.config.options.actions.isActive && _vm.config.options.actions.method && (_vm.config.name == 'shippings' || _vm.config.name == 'orders' || _vm.config.name == 'payments' || _vm.config.name == 'products') && _vm.getAccessLinks(null) ? _c('div', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.away,
      expression: "away"
    }]
  }, [_c('button', {
    staticClass: "vs-con-dropdown parent-dropdown cursor-pointer",
    on: {
      "click": function click($event) {
        _vm.open = !_vm.open;
      }
    }
  }, [_c('div', {
    staticClass: "p-3 shadow rounded-lg cursor-pointer flex items-end justify-center text-lg font-medium"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-more-vertical h-4 w-4",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "12",
      "cy": "12",
      "r": "1"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "5",
      "r": "1"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "19",
      "r": "1"
    }
  })])])])]), _vm.open ? _c('div', {
    staticClass: "absolute focus:ring-2 focus:ring-green-600 right-0 dropdown-action w-full mt-5 z-10 origin-top-right rounded-md shadow-lg",
    attrs: {
      "x-transition:enter": "transition ease-out duration-100",
      "x-transition:enter-start": "transform opacity-0 scale-95",
      "x-transition:enter-end": "transform opacity-100 scale-100",
      "x-transition:leave": "transition ease-in duration-75",
      "x-transition:leave-start": "transform opacity-100 scale-100",
      "x-transition:leave-end": "transform opacity-0 scale-95"
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-md shadow dark-mode:bg-gray-800"
  }, _vm._l(_vm.config.options.actions.buttons, function (item, index) {
    return _c('a', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.getAccessLinks(item.action) && !item.link,
        expression: "getAccessLinks(item.action) && !item.link"
      }],
      key: index,
      staticClass: "block cursor-pointer px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline relative loidingconfirm",
      attrs: {
        "disabled": _vm.loadingpdf
      },
      on: {
        "click": function click($event) {
          return _vm.EmitMethodAction(item.action);
        }
      }
    }, [item.action === 'printdDelivry' ? _c('loading', {
      attrs: {
        "active": _vm.loadingpdf,
        "is-full-page": _vm.fullPage
      },
      on: {
        "update:active": function updateActive($event) {
          _vm.loadingpdf = $event;
        }
      }
    }) : _vm._e(), _c('i', {
      staticClass: "material-icons align-bottom mr-2"
    }, [_vm._v(_vm._s(item.icon))]), _vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")], 1);
  }), 0)]) : _vm._e()]) : _vm._e()]), _vm.config.options.actions.isActive && _vm.config.options.actions.method && _vm.config.name == 'statusHistories' ? _c('div', {
    staticClass: "w-1/2 flex justify-end space-x-4"
  }, _vm._l(_vm.config.options.actions.buttons, function (item, index) {
    return _c('button', {
      key: index,
      staticClass: "rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
      on: {
        "click": _vm.downloadAnalytics
      }
    }, [_c('i', {
      staticClass: "material-icons mr-2"
    }, [_vm._v(_vm._s(item.icon))]), _vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]);
  }), 0) : _vm._e()]), _vm.isFilterCourier && _vm.config.name == 'shippings' ? _c('div', {
    staticClass: "flex justify-center border-gray-200"
  }, [_c('div', {
    staticClass: "w-5/12 bg-green-100 border-t-4 border-green-600 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2",
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('i', {
    staticClass: "large material-icons mr-5"
  }, [_vm._v("account_balance_wallet")]), _c('div', [_vm.CourierInfo.courier ? _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("courier")) + " : "), _c('span', [_vm._v(_vm._s(_vm.CourierInfo.courier))])]) : _vm._e(), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_orders")) + " "), _c('span', [_vm._v(_vm._s(_vm.CourierInfo.count))])]), _c('p', {
    staticClass: "font-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("total")) + " : "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.CourierInfo.total) + " ")])])])])]) : _vm._e(), _c('div', {
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c('table', {
    staticClass: "min-w-full w-full",
    attrs: {
      "id": "loading"
    }
  }, [_c('thead', {
    staticClass: "relative"
  }, [_c('tr', {
    staticClass: "bg-gray-400 text-gray-600 uppercase text-sm leading-normal"
  }, [_vm._l(_vm.config.columns, function (item, key) {
    return _c('th', {
      key: key,
      staticClass: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider",
      class: item.class
    }, [item.type && _vm.config.name != 'products' ? _c('label', {
      staticClass: "text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
    }, [_c('input', {
      staticClass: "form-checkbox checkallbbox focus:outline-none focus:shadow-outline",
      attrs: {
        "type": "checkbox"
      },
      on: {
        "click": function click($event) {
          return _vm.selectAllCheckbox($event);
        }
      }
    })]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t(item.name)) + " ")])]);
  }), _vm.config.actions && _vm.config.actions.elements && _vm.config.actions.elements.length != 0 ? _c('th', {
    staticClass: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider"
  }, [_vm._v(" " + _vm._s(_vm.config.actions.name) + " ")]) : _vm._e()], 2)]), _c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _vm._l(_vm.data, function (item, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', {
      staticClass: "border-b border-gray-200 hover:bg-gray-100",
      class: index % 2 != 0 ? 'bg-gray-100' : ''
    }, [_vm._l(_vm.config.columns, function (col, ind) {
      return _c('td', {
        key: ind,
        staticClass: "px-5 py-2 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_vm._l(col.elements, function (element, indElm) {
        return _c('div', {
          key: indElm
        }, _vm._l(element.items, function (itemst, itemIndex) {
          return _c('div', {
            key: itemIndex,
            class: itemst.name == 'status' ? 'text-center' : ''
          }, [!itemst.elements ? _c('div', [itemst.name == 'status' || itemst.name == 'previousStatus' ? _c('div', [!itemst.data ? _c('div', [_vm.config.name != 'shippingsHistories' ? _c('div', [_c('button', {
            class: itemst.class,
            style: itemst.options(_vm.data[index][itemst.name]),
            on: {
              "click": function click($event) {
                return _vm.ShowPopUpInformation(index, _vm.data[index][itemst.name], item);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.data[index][itemst.name]) + " ")]), _vm.config.name === 'shippings' && _vm.data[index][itemst.name] && (_vm.data[index][itemst.name] === 'paid' || _vm.data[index][itemst.name] === 'processed') ? _c('div', [_c('span', {
            staticClass: "jvectormap-tip text-white",
            class: _vm.data[index][itemst.name] === 'paid' ? 'label-paid' : 'label-accept'
          }, [_vm._v(_vm._s(_vm.data[index][itemst.name] === "paid" ? "PaidAt" : "ProcessedAt") + " (" + _vm._s(_vm.formattedDate(_vm.data[index][itemst.name] === "paid" ? _vm.data[index]["paidAt"] : _vm.data[index]["updatedAt"], "yyyy-MM-DD")) + ")")])]) : _vm._e()]) : _c('div', [_c('button', {
            class: itemst.class,
            style: itemst.options(_vm.data[index][itemst.name])
          }, [_vm._v(" " + _vm._s(_vm.data[index][itemst.name]) + " ")])])]) : _c('div', [_c('button', {
            class: itemst.class,
            style: {
              color: _vm.data[index][itemst.name] ? _vm.data[index][itemst.name][itemst.champs.color] : '',
              borderColor: _vm.data[index][itemst.name] ? _vm.data[index][itemst.name][itemst.champs.color] : ''
            },
            attrs: {
              "disabled": !_vm.currentUser.type == 'Admin' || !_vm.currentUser.type == 'CEO' || !_vm.currentUser.type == 'TeleConsultant' || !_vm.currentUser.type == 'NRP'
            },
            on: {
              "click": function click($event) {
                return _vm.ShowPopUpInformation(index, null, item);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.data[index][itemst.name] ? _vm.data[index][itemst.name][itemst.champs.name] : "") + " ")]), _vm.config.name === 'orders' && _vm.data[index][itemst.name] && _vm.data[index][itemst.name][itemst.champs.name] ? [_vm.data[index]['sameOrderUnreached'] && _vm.data[index]['sameOrderUnreached'].length > 0 ? _c('span', {
            staticClass: "flex items-center justify-center sous-s"
          }, [_c('svg', {
            staticClass: "fill-current mr-3",
            attrs: {
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "15",
              "height": "15",
              "viewBox": "0 0 24 24"
            }
          }, [_c('path', {
            attrs: {
              "d": "M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z"
            }
          })]), _c('span', [_vm._v("Unreached (" + _vm._s(_vm.data[index]["sameOrderUnreached"].length) + ")")]), _vm.data[index][itemst.name][itemst.champs.name] === 'Pending' && _vm.data[index]['lastUnreachedDate'] ? _c('span', [_c('svg', {
            staticClass: "fill-current ml-2 align-sub inline-block",
            attrs: {
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "15",
              "height": "15",
              "viewBox": "0 0 24 24"
            }
          }, [_c('path', {
            attrs: {
              "d": "M12 12h3v2h-5v-6h2v4zm9-1h-1.07c-.251-2.006-1.218-3.792-2.647-5.093-1.283-1.166-1.228-2.247-1.856-5.907h-7.854c-.628 3.66-.573 4.741-1.856 5.907-1.67 1.52-2.717 3.701-2.717 6.124 0 2.424 1.048 4.606 2.72 6.126 1.28 1.164 1.232 2.267 1.853 5.843h7.854c.622-3.576.573-4.679 1.854-5.843 1.445-1.314 2.421-3.124 2.66-5.157h1.059v-2zm-9.5 7.5c-3.589 0-6.5-2.911-6.5-6.5s2.911-6.5 6.5-6.5 6.5 2.911 6.5 6.5-2.911 6.5-6.5 6.5z"
            }
          })]), _vm._v(" " + _vm._s(_vm.formattedDate(_vm.data[index]["lastUnreachedDate"], "yyyy-MM-DD HH:mm")))]) : _vm._e()]) : _vm._e(), _vm.data[index]['remind'] && (_vm.data[index][itemst.name][itemst.champs.name] === 'Pending' || _vm.data[index][itemst.name][itemst.champs.name] === 'OutOfStock') ? _c('span', {
            staticClass: "flex items-center justify-center sous-s"
          }, [_c('svg', {
            staticClass: "fill-current mr-3",
            attrs: {
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "15",
              "height": "15",
              "viewBox": "0 0 24 24"
            }
          }, [_c('path', {
            attrs: {
              "d": "M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z"
            }
          })]), _c('span', [_vm._v("Reminded (To: " + _vm._s(_vm.$moment(_vm.data[index]["remind"]).format("yyyy-MM-DD HH:mm")) + ")")])]) : _vm._e()] : _vm._e(), _vm.config.name === 'orders' && _vm.data[index][itemst.name] && _vm.data[index][itemst.name][itemst.champs.name] === 'Cancelled' ? [_vm.data[index]['validated_status'] ? _c('span', {
            staticClass: "jvectormap-tip text-white label-accept"
          }, [_vm._v("Validated (" + _vm._s(_vm.formattedDate(_vm.data[index]["validated_status"], "yyyy-MM-DD")) + ")")]) : _vm._e(), _vm.data[index]['comment'] ? _c('span', {
            staticClass: "jvectormap-tip text-white label-accept"
          }, [_vm._v(_vm._s(_vm.data[index]["comment"]))]) : _vm._e()] : _vm._e(), _vm.config.name === 'orders' && _vm.data[index][itemst.name] && _vm.data[index][itemst.name][itemst.champs.name] === 'A transférer' ? [_vm.data[index]['is_transfere'] > 1 ? _c('span', {
            staticClass: "jvectormap-tip text-white label-accept"
          }, [_vm._v("À transférer plusieur fois (" + _vm._s(_vm.data[index].is_transfere) + ")")]) : _vm._e()] : _vm._e()], 2)]) : itemst.name == 'type' && _vm.config.name != 'stockHistories' ? _c('div', [_c('span', {
            style: itemst.options(_vm.data[index][itemst.name])
          }, [_vm._v(_vm._s(_vm.data[index][itemst.name]))])]) : _c('div', {
            class: itemst.class
          }, [itemst.array ? _c('div', [_vm._v(" " + _vm._s(_vm.data[index][itemst.name].length) + " ")]) : itemst.date ? _c('div', [_vm._v(" " + _vm._s(_vm.formattedDate(_vm.data[index][itemst.name])) + " ")]) : itemst.history ? _c('div', [_c('span', {
            class: _vm.data[index]['status'] == 'in' ? 'text-green-600' : 'text-red-600'
          }, [_vm.data[index]['status'] == 'in' ? _c('span', [_c('i', {
            staticClass: "material-icons"
          }, [_vm._v("arrow_drop_up")])]) : _c('span', [_c('i', {
            staticClass: "material-icons"
          }, [_vm._v("arrow_drop_down")])]), _vm._v(" " + _vm._s(_vm.data[index][itemst.name]))])]) : itemst.type == 'checkbox' ? _c('div', [_c('label', {
            staticClass: "text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
          }, [_c('input', {
            staticClass: "form-checkbox rowCheckbox focus:outline-none focus:shadow-outline",
            class: 'rowCheckboxProduct-' + item._id,
            attrs: {
              "type": "checkbox",
              "status": item.status ? item.status.name || item.status : '',
              "orderObjet": item.orderObjet ? JSON.stringify(item.orderObjet) : '',
              "is_paid": item.is_paid ? item.is_paid : '',
              "order": item.order ? item.order._id : '',
              "sellerid": item.seller ? item.seller._id || item.seller : '',
              "statusid": item.status ? item.status._id || item.status : '',
              "seller": item.seller && item.seller.source ? item.seller.source : 'nosource',
              "id": item._id
            },
            on: {
              "click": function click($event) {
                return _vm.getRowDetail(item, item._id);
              }
            }
          })])]) : _c('div', [itemst.name == 'country' || itemst.name == 'country_to' ? _c('div', {
            staticClass: "flex"
          }, [Array.isArray(_vm.data[index][itemst.name]) ? _c('span', {
            staticClass: "flex"
          }, _vm._l(_vm.data[index][itemst.name], function (countr, r) {
            return _c('img', {
              key: r,
              staticClass: "w-4 h-4 rounded-full mr-2",
              attrs: {
                "src": _vm.$f.getCountryCode(countr)
              }
            });
          }), 0) : _c('span', [_vm._v(_vm._s(_vm.$f.getCountryNameByCode(_vm.dataCountry, _vm.data[index][itemst.name])))])]) : itemst.name == 'address' || itemst.name == 'name' || itemst.name == 'source' ? _c('div', [_vm._v(" " + _vm._s(_vm.exceptText(_vm.data[index][itemst.name])) + " ")]) : _c('div', [itemst.arrayD ? _c('div', {
            staticClass: "text-center"
          }, [_c('span', {
            staticClass: "bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs"
          }, [_vm._v("Total: " + _vm._s(_vm.data[index][itemst.name]))]), _vm._l(_vm.data[index][itemst.arrayD], function (wharhouse, i) {
            return _vm.idWharhouse && wharhouse.warehouse._id == _vm.idWharhouse._id.toString() ? _c('div', {
              key: i,
              staticClass: "flex justify-center items-center py-1"
            }, [_c('div', {
              staticClass: "mr-2"
            }, [_c('img', {
              staticClass: "w-4 h-4 rounded-full",
              attrs: {
                "src": wharhouse.country
              }
            })]), _c('span', [_vm._v(_vm._s(wharhouse.quantity[itemst.name]))])]) : _vm._e();
          })], 2) : _c('div', {
            class: itemst.name == 'id' || itemst.name == 'order_id' ? 'normal-case' : ''
          }, [itemst.name === 'oldObject' || itemst.name === 'newObject' ? _c('span', [_vm.data[index][itemst.name] && _vm.data[index][itemst.name].settings && _vm.data[index][itemst.name].settings.length ? _c('span', _vm._l(_vm.data[index][itemst.name].settings, function (setting, i) {
            return _c('div', {
              key: i,
              staticClass: "flex flex-col lg:flex-row mb-2 items-center"
            }, [_c('img', {
              staticClass: "w-4 h-4 rounded-full mr-2",
              attrs: {
                "src": setting.flag
              }
            }), _c('div', {
              staticClass: "intro-y mr-1 flex-1 box-1 py-1",
              class: setting.confirmation ? 'active' : '',
              staticStyle: {
                "transform": "inherit"
              },
              attrs: {
                "id": "confirmation"
              }
            }, [_c('div', {
              staticClass: "text-smmm font-medium text-center"
            }, [_vm._v(" Confirmation ")])]), _c('div', {
              staticClass: "intro-y mr-1 flex-1 box-1 py-1",
              class: setting.fulfillement ? 'active' : '',
              staticStyle: {
                "transform": "inherit"
              },
              attrs: {
                "id": "Warehouse"
              }
            }, [_c('div', {
              staticClass: "text-smmm font-medium text-center"
            }, [_vm._v(" Warehouse ")])]), _c('div', {
              staticClass: "intro-y flex-1 box-1 py-1",
              class: setting.shipping ? 'active' : '',
              staticStyle: {
                "transform": "inherit"
              },
              attrs: {
                "id": "Shipping"
              }
            }, [_c('div', {
              staticClass: "text-smmm font-medium text-center"
            }, [_vm._v(" Shipping ")])])]);
          }), 0) : _vm.data[index][itemst.name] && _vm.data[index][itemst.name].countries && _vm.data[index][itemst.name].countries.length ? _c('span', {
            staticClass: "flex"
          }, _vm._l(_vm.data[index][itemst.name].countries, function (images, r) {
            return _c('span', {
              key: r
            }, [_c('img', {
              staticClass: "w-4 h-4 rounded-full mr-2",
              attrs: {
                "src": 'https://static.dwcdn.net/css/flag-icons/flags/4x3/' + images.toLowerCase() + '.svg'
              }
            })]);
          }), 0) : _vm.data[index][itemst.name] && _vm.data[index][itemst.name].permissions && _vm.data[index][itemst.name].permissions.length ? _c('span', [_c('table', {
            staticClass: "table w-full text-center"
          }, [_vm._m(0, true), _c('tbody', _vm._l(_vm.data[index][itemst.name].permissions, function (el, mm) {
            return _c('tr', {
              key: mm,
              class: mm % 2 == 0 ? 'bg-gray-100' : ''
            }, [_c('td', {
              staticClass: "px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
            }, [_c('p', {
              staticClass: "text-gray-700 capitalize",
              staticStyle: {
                "font-size": "11px"
              }
            }, [_vm._v(" " + _vm._s(el.model) + " ")])]), _c('td', {
              staticClass: "px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
            }, [_c('p', {
              staticClass: "text-gray-600 ml-3 text-semibold capitalize"
            }, [_c('label', {
              staticClass: "inline-flex text-sm items-center capitalize"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: el.access.create,
                expression: "el.access.create"
              }],
              staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
              attrs: {
                "disabled": "",
                "type": "checkbox"
              },
              domProps: {
                "checked": Array.isArray(el.access.create) ? _vm._i(el.access.create, null) > -1 : el.access.create
              },
              on: {
                "change": function change($event) {
                  var $$a = el.access.create,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(el.access, "create", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(el.access, "create", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(el.access, "create", $$c);
                  }
                }
              }
            })])])]), _c('td', {
              staticClass: "px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
            }, [_c('p', {
              staticClass: "text-gray-600 ml-3 text-semibold capitalize"
            }, [_c('label', {
              staticClass: "inline-flex text-sm items-center capitalize"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: el.access.watch,
                expression: "el.access.watch"
              }],
              staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
              attrs: {
                "type": "checkbox",
                "disabled": ""
              },
              domProps: {
                "checked": Array.isArray(el.access.watch) ? _vm._i(el.access.watch, null) > -1 : el.access.watch
              },
              on: {
                "change": function change($event) {
                  var $$a = el.access.watch,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(el.access, "watch", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(el.access, "watch", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(el.access, "watch", $$c);
                  }
                }
              }
            })])])]), _c('td', {
              staticClass: "px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
            }, [_c('p', {
              staticClass: "text-gray-600 ml-2 text-semibold capitalize"
            }, [_c('label', {
              staticClass: "inline-flex text-sm items-center capitalize"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: el.access.read,
                expression: "el.access.read"
              }],
              staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
              attrs: {
                "type": "checkbox",
                "disabled": ""
              },
              domProps: {
                "checked": Array.isArray(el.access.read) ? _vm._i(el.access.read, null) > -1 : el.access.read
              },
              on: {
                "change": function change($event) {
                  var $$a = el.access.read,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(el.access, "read", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(el.access, "read", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(el.access, "read", $$c);
                  }
                }
              }
            })])])]), _c('td', {
              staticClass: "px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
            }, [_c('p', {
              staticClass: "text-gray-600 ml-3 text-semibold capitalize"
            }, [_c('label', {
              staticClass: "inline-flex text-sm items-center capitalize"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: el.access.update,
                expression: "el.access.update"
              }],
              staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
              attrs: {
                "type": "checkbox",
                "disabled": ""
              },
              domProps: {
                "checked": Array.isArray(el.access.update) ? _vm._i(el.access.update, null) > -1 : el.access.update
              },
              on: {
                "change": function change($event) {
                  var $$a = el.access.update,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(el.access, "update", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(el.access, "update", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(el.access, "update", $$c);
                  }
                }
              }
            })])])]), _c('td', {
              staticClass: "px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm"
            }, [_c('p', {
              staticClass: "text-gray-600 ml-3 text-semibold capitalize"
            }, [_c('label', {
              staticClass: "inline-flex text-sm items-center capitalize"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: el.access.delete,
                expression: "el.access.delete"
              }],
              staticClass: "form-checkbox border-gray-500 h-4 w-4 text-green-500",
              attrs: {
                "type": "checkbox",
                "disabled": ""
              },
              domProps: {
                "checked": Array.isArray(el.access.delete) ? _vm._i(el.access.delete, null) > -1 : el.access.delete
              },
              on: {
                "change": function change($event) {
                  var $$a = el.access.delete,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(el.access, "delete", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(el.access, "delete", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(el.access, "delete", $$c);
                  }
                }
              }
            })])])])]);
          }), 0)])]) : _c('span', [_vm._v(" " + _vm._s(_vm.data[index][itemst.name]) + " ")])]) : _c('span', {
            domProps: {
              "innerHTML": _vm._s(_vm.data[index][itemst.name])
            }
          }), itemst.name == 'totalPrice' && _vm.idWharhouse ? _c('sup', {
            staticClass: "text-black uppercase ml-1"
          }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])])])]) : _c('div', _vm._l(itemst.elements, function (ele, itemelem) {
            return _c('div', {
              key: itemelem
            }, _vm._l(ele.items, function (refit, ind) {
              return _c('div', {
                key: ind
              }, [_c('div', {
                class: refit.class
              }, [refit.name == 'picture' ? _c('div', [_c('img', {
                staticClass: "h-12 w-12",
                attrs: {
                  "src": _vm.data[index][itemst.name][refit.name] || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                }
              })]) : _c('div', [refit.name == 'city' ? _c('span', [refit.icone ? _c('i', {
                staticClass: "material-icons tiny"
              }, [_vm._v(_vm._s(refit.icone))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.data[index][itemst.name]["customer"][refit.name]) + " ")]) : _c('span', [_vm.data[index][itemst.name] && !_vm.isEmpty(_vm.data[index][itemst.name][refit.name]) ? _c('span', [_vm.data[index][itemst.name][refit.name] !== 'camex' && _vm.data[index][itemst.name][refit.name] !== 'firstdelivery' ? _c('div', [refit.icone ? _c('i', {
                staticClass: "material-icons tiny"
              }, [_vm._v("contacts")]) : _vm._e(), refit.name == 'shippingAddress' || refit.name == 'fullName' ? _c('span', [_vm._v(_vm._s(_vm.exceptText(_vm.data[index][itemst.name][refit.name], "fullName")))]) : _c('span', [_vm._v(_vm._s(_vm.data[index][itemst.name][refit.name]))])]) : _c('div', {
                staticClass: "flex items-center justify-start w-full"
              }, [_vm.data[index][itemst.name][refit.name] == 'camex' ? _c('img', {
                staticStyle: {
                  "height": "35px",
                  "width": "50px",
                  "border-radius": "5px"
                },
                attrs: {
                  "src": _vm.camexImage
                }
              }) : _c('img', {
                staticStyle: {
                  "height": "50px",
                  "width": "70px",
                  "object-fit": "contain"
                },
                attrs: {
                  "src": _vm.FirstDeliveryImage
                }
              })])]) : _vm._e()])])])]);
            }), 0);
          }), 0)]);
        }), 0);
      }), col.model && col.model == 'expedition' || col.model && col.model == 'order' || col.model && col.model == 'payments' || col.model && col.model == 'shippings' ? _c('div', [_c('div', {
        staticClass: "flex"
      }, [col.model && col.model == 'expedition' || col.model && col.model == 'order' || col.model && col.model == 'payments' ? _c('div', [_c('p', [_c('span', {
        staticClass: "font-semibold mr-1 text-green-600"
      }, [col.model == 'order' ? _c('span', [_vm._v(_vm._s(_vm.QuantityTotalProductOrderOnly(_vm.data[index][col.name])))]) : _c('span', [_vm._v(_vm._s(_vm.data[index][col.name].length) + " ")])]), _c('b', [_vm._v(_vm._s(_vm.$t(col.title)))])]), _c('span', {
        staticClass: "font-light text-gray-700"
      }, [_vm._v(" " + _vm._s(_vm.$moment(_vm.data[index]["date"]).format("yyyy-MM-DD")) + " ")])]) : _c('div', [_c('p', [_c('span', {
        staticClass: "font-bold mr-1 text-green-600"
      }, [_vm._v(" " + _vm._s(_vm.data[index].order.details.length) + " ")]), _vm._v(_vm._s(_vm.$t("products")) + " ")]), _c('span', {
        staticClass: "font-light text-gray-700"
      }, [_vm._v(_vm._s(_vm.$t("quantity")) + ": ")]), _c('b', [_vm._v(_vm._s(_vm.QuantityTotalProductOrder(_vm.data[index])))])]), _vm.config.sousTable ? _c('button', {
        staticClass: "bg-gray-100 hover:bg-green-100 hover:text-white rounded-full mr-2 flex items-center text-2xl focus:outline-none p-1 ml-1",
        class: _vm.$colors.newColor,
        on: {
          "click": function click($event) {
            return _vm.showSousTable(index);
          }
        }
      }, [_c('i', {
        staticClass: "material-icons",
        staticStyle: {
          "transition": "0.3s"
        },
        style: _vm.element && _vm.element._id == item._id ? 'transform: rotate(180deg)' : ''
      }, [_vm._v("keyboard_arrow_down")])]) : _vm._e()])]) : _vm._e()], 2)]);
    }), _vm.config.actions && _vm.config.actions.elements && _vm.config.actions.elements.length != 0 ? _c('td', {
      staticClass: "px-6 py-4 whitespace-no-wrap border-b border-gray-200"
    }, [_c('div', {
      staticClass: "flex",
      class: _vm.config.actions.class
    }, _vm._l(_vm.config.actions.elements, function (action, ind) {
      return _c('div', {
        key: ind
      }, [_vm.getAccess(action.key, _vm.data[index]['status'], _vm.data[index]['type'], action.role, _vm.data[index]['lastUnreachedDate'] || _vm.data[index]['remind'] || null) && action.role ? _c('div', {
        class: _vm.$colors.actionTable,
        on: {
          "click": function click($event) {
            return _vm.getAccess(action.key, _vm.data[index]['status'], _vm.data[index]['type'], action.role, _vm.data[index]['lastUnreachedDate'] || _vm.data[index]['remind'] || null);
          }
        }
      }, [action.link ? _c('div', [_c('router-link', {
        class: _vm.config.actions.class,
        attrs: {
          "to": {
            path: "".concat(action.link, "/").concat(_vm.data[index]['_id'])
          }
        }
      }, [_c('i', {
        staticClass: "material-icons mx-2"
      }, [_vm._v(" " + _vm._s(action.icone))])])], 1) : _c('div', [_c('button', {
        class: _vm.config.actions.class,
        on: {
          "click": function click($event) {
            return _vm.emitFunction(action.key, _vm.data[index]['_id'], _vm.data[index]);
          }
        }
      }, [_c('i', {
        staticClass: "material-icons mx-2"
      }, [_vm._v(_vm._s(action.icone))])])])]) : _vm._e()]);
    }), 0)]) : _vm._e()], 2), _vm.config.sousTable && _vm.element && _vm.element._id == _vm.data[index]['_id'] ? _c('tr', [_c('td', {
      staticClass: "border",
      attrs: {
        "colspan": _vm.config.columns.length + 1
      }
    }, [_c('div', {
      staticClass: "w-full rounded-2xl"
    }, [_c('table', {
      staticClass: "table w-full text-center",
      class: _vm.config.name == 'shippings' ? 'table-shipp' : ''
    }, [_c('thead', {
      staticClass: "bg-gray-100 text-black"
    }, [_c('tr', _vm._l(_vm.config.columnsDialog.elements, function (col, ind) {
      return _c('th', {
        key: ind,
        staticClass: "px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider"
      }, [_vm._v(" " + _vm._s(_vm.$t(col.name)) + " ")]);
    }), 0)]), _vm.config.name == 'expeditions' || _vm.config.name == 'orders' || _vm.config.name == 'payments' ? _c('tbody', _vm._l(_vm.element[_vm.config.columnsDialog.details], function (row, j) {
      return _c('tr', {
        key: j,
        staticClass: "bg-light-green-200"
      }, _vm._l(_vm.config.columnsDialog.elements, function (col, ind) {
        return _c('td', {
          key: ind,
          staticClass: "text-xs leading-5 capitalize text-gray-900"
        }, _vm._l(col.items, function (item, indeitem) {
          return _c('div', {
            key: indeitem
          }, [_vm.config.name == 'expeditions' ? _c('div', [col.attribut == 'product' ? _c('span', [item.name == 'picture' ? _c('div', [_c('img', {
            staticClass: "w-8 my-2 rounded-md mx-auto",
            attrs: {
              "src": [row.product][0][item.name] || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
            }
          })]) : _c('div', [_vm._v(" " + _vm._s([row.product][0][item.name]) + " ")])]) : _c('span', [item.name == 'received' ? _c('div', [_c('table', {
            staticClass: "min-w-full"
          }, [_c('thead', [_c('tr', [_c('th', {
            staticClass: "text-center bg-gray-200 px-6 py-1 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
          }, [_vm._v(" " + _vm._s(_vm.$t("ok_good")) + " ")]), _c('th', {
            staticClass: "text-center bg-gray-200 px-6 py-1 border border-gray-300 text-left leading-4 text-green-500 tracking-wider"
          }, [_vm._v(" " + _vm._s(_vm.$t("defective")) + " ")])])]), _c('tbody', {
            staticClass: "bg-white"
          }, [_c('tr', {
            staticClass: "bg-light-green-200"
          }, [_c('td', {
            staticClass: "text-xs leading-5 capitalize text-gray-900"
          }, [_vm._v(" " + _vm._s([row.quantity][0][item.name]) + " ")]), _c('td', {
            staticClass: "text-xs leading-5 capitalize text-gray-900"
          }, [_vm._v(" " + _vm._s([row.quantity][0][item.name_1]) + " ")])])])])]) : _c('div', [item.name == 'merchandise_value' ? _c('div', [_vm._v(" " + _vm._s([row][0][item.name]) + " "), _vm.idWharhouse ? _c('sup', {
            staticClass: "text-black uppercase"
          }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()]) : _c('div', [_vm._v(" " + _vm._s([row.quantity][0][item.name]) + " ")])])])]) : _vm.config.name == 'orders' ? _c('div', [col.referent ? _c('div', [col.referent == 'product' ? _c('span', [item.name == 'picture' ? _c('div', [_c('img', {
            staticClass: "w-8 my-2 rounded-md mx-auto",
            attrs: {
              "src": [row.product][0][item.name] || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
            }
          })]) : _c('div', [_vm._v(" " + _vm._s([row.product][0][item.name]) + " ")])]) : _c('span', [_vm._v(_vm._s([row.category][0][item.name]))])]) : _c('div', [_vm._v(" " + _vm._s([row][0][item.name]) + " "), item.name == 'unitPrice' && _vm.idWharhouse ? _c('sup', {
            staticClass: "text-black uppercase"
          }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])]) : _c('div', [item.referent ? _c('div', [item.field ? _c('div', [_vm._v(" " + _vm._s(row[item.referent][item.field][item.name]) + " ")]) : _c('div', [_vm._v(" " + _vm._s(row[item.referent][item.name]) + " "), item.name == 'totalPrice' && _vm.config.name == 'payments' && _vm.idWharhouse ? _c('sup', {
            staticClass: "text-black uppercase"
          }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])]) : _c('div', [_c('div', {
            class: (item.name == 'shipping_refund_vat' || item.name == 'shipping_cancel_vat') && row[item.name] == 0 && _vm.config.name == 'payments' ? 'empty-row' : ''
          }, [_vm._v(" " + _vm._s(row[item.name]) + " "), _vm.config.name == 'payments' && _vm.idWharhouse ? _c('sup', {
            staticClass: "text-black uppercase"
          }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])])]);
        }), 0);
      }), 0);
    }), 0) : _c('tbody', [_c('tr', {
      staticClass: "bg-light-green-200"
    }, _vm._l(_vm.config.columnsDialog.elements, function (col, ind) {
      return _c('td', {
        key: ind,
        staticClass: "text-xs leading-5 capitalize text-gray-900"
      }, _vm._l(col.items, function (item, indeitem) {
        return _c('div', {
          key: indeitem
        }, [col.attribut ? _c('div', [!item.elements ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.data[index][col.referent][col.attribut][item.name]))])]) : _c('div', _vm._l(item.elements, function (ele, itemelem) {
          return _c('div', {
            key: itemelem
          }, _vm._l(ele.items, function (refit, ind) {
            return _c('div', {
              key: ind
            }, [_c('div', {
              class: ele.class
            }, [col.attribut != 'seller' ? _c('div', [refit.icone ? _c('i', {
              staticClass: "material-icons tiny"
            }, [_vm._v(_vm._s(refit.icone))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.data[index][col.referent][col.attribut][refit.name]) + " ")]) : _c('div', [!_vm.isEmpty(_vm.data[index][_vm.config.columnsDialog.details][_vm.config.columnsDialog.name][0].product.seller[refit.name]) ? _c('span', [refit.icone ? _c('i', {
              staticClass: "material-icons tiny"
            }, [_vm._v(_vm._s(refit.icone))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.data[index][_vm.config.columnsDialog.details][_vm.config.columnsDialog.name][0].product.seller[refit.name]))]) : _vm._e()])])]);
          }), 0);
        }), 0)]) : _c('div', [_vm._v(" " + _vm._s(_vm.data[index][col.referent][item.name]) + " "), item.name == 'totalPrice' && _vm.idWharhouse ? _c('sup', {
          staticClass: "text-black uppercase"
        }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])]);
      }), 0);
    }), 0)]), _c('thead', {
      staticClass: "bg-gray-100 text-black"
    }, [_c('tr', _vm._l(_vm.config.columnsDialog.sousElements, function (col, ind) {
      return _c('th', {
        key: ind,
        staticClass: "px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider"
      }, [_vm._v(" " + _vm._s(_vm.$t(col.name)) + " ")]);
    }), 0)]), _c('tbody', _vm._l(_vm.data[index][_vm.config.columnsDialog.details][_vm.config.columnsDialog.name], function (prod, k) {
      return _c('tr', {
        key: k,
        staticClass: "bg-light-green-200"
      }, _vm._l(_vm.config.columnsDialog.sousElements, function (col, ind) {
        return _c('td', {
          key: ind,
          staticClass: "text-xs leading-5 capitalize text-gray-900"
        }, _vm._l(col.items, function (item, indeitem) {
          return _c('div', {
            key: indeitem
          }, [col.referent ? _c('div', [item.name == 'picture' ? _c('div', [_c('img', {
            staticClass: "w-8 my-2 rounded-md mx-auto",
            attrs: {
              "src": prod[col.referent][item.name] || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
            }
          })]) : _c('div', [_vm._v(" " + _vm._s(prod[col.referent][item.name]) + " ")])]) : _c('div', [_c('span', [_vm._v(_vm._s(prod[item.name]) + " "), item.name == 'unitPrice' && _vm.idWharhouse ? _c('sup', {
            staticClass: "text-black uppercase"
          }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])]);
        }), 0);
      }), 0);
    }), 0)])])])]) : _vm._e(), _vm.config.name == 'orders' ? _c('vue-modal-2', {
      attrs: {
        "name": 'modal1_' + index,
        "headerOptions": {
          title: _vm.$t('order_information')
        },
        "noFooter": true,
        "modalSize": "xl"
      },
      on: {
        "on-close": function onClose($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_c('modal-info-order', {
      attrs: {
        "item": item,
        "index": index,
        "idWharhouse": _vm.idWharhouse,
        "cities": _vm.cities,
        "feesShipp": _vm.feesShipp,
        "CheckQteDefecteuseorderexist": _vm.CheckQteDefecteuseorderexist,
        "courier": _vm.courier,
        "currentUser": _vm.currentUser,
        "shipping": _vm.shipping,
        "config": _vm.config,
        "statusOrder": _vm.statusOrder,
        "zones": _vm.zones
      },
      on: {
        "getFeesShippBycity": _vm.getFeesShippBycity,
        "CheckQteDefecteuseOrder": _vm.CheckQteDefecteuseOrder,
        "getCourierZone": _vm.getCourierZone,
        "OrderChange": _vm.OrderChange
      }
    }), _c('div', {
      staticClass: "modal-footer px-5 border0-t text-right"
    }, [_c('button', {
      staticClass: "flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
      on: {
        "click": function click($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_vm._v(" Cancel ")]), _c('button', {
      staticClass: "flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm",
      attrs: {
        "disabled": _vm.loadingcreateShipp
      },
      on: {
        "click": function click($event) {
          return _vm.ConfirmOperationUpdate(item, index);
        }
      }
    }, [_c('loading', {
      attrs: {
        "active": _vm.loadingcreateShipp,
        "is-full-page": _vm.fullPage
      },
      on: {
        "update:active": function updateActive($event) {
          _vm.loadingcreateShipp = $event;
        }
      }
    }), _vm._v("Confirm ")], 1)])], 1) : _vm._e(), _vm.config.name == 'expeditions' ? _c('vue-modal-2', {
      attrs: {
        "name": 'modal1_' + index,
        "headerOptions": {
          title: _vm.$t('expedition_information')
        },
        "noFooter": true,
        "modalSize": "xl"
      },
      on: {
        "on-close": function onClose($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_c('modal-info-expo', {
      attrs: {
        "item": item,
        "index": index,
        "config": _vm.config,
        "CheckExpeditionRecived": _vm.CheckExpeditionRecived,
        "statusExpedition": _vm.statusExpedition,
        "transports": _vm.transports
      },
      on: {
        "CheckQteSentExist": _vm.CheckQteSentExist
      }
    }), _c('div', {
      staticClass: "modal-footer px-5 border0-t text-right"
    }, [_c('button', {
      staticClass: "flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
      on: {
        "click": function click($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_vm._v(" Cancel ")]), _c('button', {
      staticClass: "flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm",
      attrs: {
        "disabled": _vm.loadingcreateShipp
      },
      on: {
        "click": function click($event) {
          return _vm.ConfirmOperationUpdate(item, index);
        }
      }
    }, [_c('loading', {
      attrs: {
        "active": _vm.loadingcreateShipp,
        "is-full-page": _vm.fullPage
      },
      on: {
        "update:active": function updateActive($event) {
          _vm.loadingcreateShipp = $event;
        }
      }
    }), _vm._v("Confirm ")], 1)])], 1) : _vm._e(), _vm.config.name == 'shippings' ? _c('vue-modal-2', {
      attrs: {
        "name": 'modal1_' + index,
        "headerOptions": {
          title: _vm.$t('shipping_informations')
        },
        "noFooter": true,
        "modalSize": "xl"
      },
      on: {
        "on-close": function onClose($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_c('modal-info-shipp', {
      attrs: {
        "item": item,
        "index": index,
        "dataCountry": _vm.dataCountry,
        "feesShipp": _vm.feesShipp,
        "couriers": _vm.couriers,
        "currentUser": _vm.currentUser,
        "config": _vm.config,
        "CheckQteDefecteuseexist": _vm.CheckQteDefecteuseexist,
        "CheckQteReturnedexist": _vm.CheckQteReturnedexist,
        "CheckQteDefecteuseorderexist": _vm.CheckQteDefecteuseorderexist,
        "zones": _vm.zones,
        "statusShipp": _vm.statusShipp,
        "typesShipp": _vm.typesShipp,
        "idWharhouse": _vm.idWharhouse
      },
      on: {
        "ChangeQuantityOrder": _vm.ChangeQuantityOrder,
        "searchCouriers": _vm.searchCouriers,
        "OpenClosePopupModalReporte": _vm.OpenClosePopupModalReporte,
        "CheckQteDefecteuse": _vm.CheckQteDefecteuse,
        "CheckQteReturned": _vm.CheckQteReturned,
        "CheckQteDefecteuseOrder": _vm.CheckQteDefecteuseOrder
      }
    }), _c('div', {
      staticClass: "modal-footer px-5 border0-t text-right"
    }, [_c('button', {
      staticClass: "flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right",
      on: {
        "click": function click($event) {
          return _vm.closePopUpInformation(index);
        }
      }
    }, [_vm._v(" Cancel ")]), _c('button', {
      staticClass: "flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right loidingconfirm relative",
      class: _vm.loadingcreateShipp ? 'noselect' : '',
      on: {
        "click": function click($event) {
          return _vm.ConfirmOperationUpdate(item, index);
        }
      }
    }, [_c('loading', {
      attrs: {
        "active": _vm.loadingcreateShipp,
        "is-full-page": _vm.fullPage
      },
      on: {
        "update:active": function updateActive($event) {
          _vm.loadingcreateShipp = $event;
        }
      }
    }), _vm._v("Confirm ")], 1)])], 1) : _vm._e(), _vm.config.name == 'shippings' ? _c('vue-modal-2', {
      staticClass: "printing",
      attrs: {
        "name": "print-shippings-delivry",
        "headerOptions": {
          title: _vm.$t('shipping_informations')
        },
        "noFooter": true,
        "modalSize": "xl"
      }
    }, [_c('modal-info-shipp-print', {
      attrs: {
        "item": item,
        "index": index,
        "idWharhouse": _vm.idWharhouse,
        "config": _vm.config
      }
    })], 1) : _vm._e()], 1);
  })], 2)]), _vm.data.length <= 0 ? _c('div', {
    staticClass: "bg-green-100 py-8"
  }, [_c('span', {
    staticClass: "w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('i', {
    staticClass: "material-icons mx-3"
  }, [_vm._v("error")]), _c('span', {}, [_vm._v(_vm._s(_vm.$t("no_rows_selected")))])])])]) : _vm._e()]), _vm.paginate.lastpage > 1 ? _c('div', {
    staticClass: "p-1"
  }, [_c('div', {
    staticClass: "flex flex-col items-center my-12"
  }, [_c('div-paginate', {
    attrs: {
      "paginate": _vm.paginate
    },
    on: {
      "paginatation": _vm.paginatation
    }
  })], 1)]) : _vm._e(), _vm.config.name == 'shippings' ? _c('vue-modal-2', {
    staticClass: "modalreporteshipp",
    attrs: {
      "name": "ModalReporteShipp",
      "headerOptions": {
        title: _vm.$t('postponed_shippings')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.OpenClosePopupModalReporte('close', 'postponed');
      }
    }
  }, [_c('div', {
    staticClass: "w-full bg-white rounded-lg px-4 pt-2"
  }, [_c('div', {
    staticClass: "flex flex-wrap -mx-3 mb-6"
  }, [_c('div', {
    staticClass: "w-full md:w-full px-3 mb-2 mt-2"
  }, [_c('datetime-v2', {
    attrs: {
      "min-datetime": _vm.$moment(Date.now()).format('yyyy-MM-DD'),
      "placeholder": _vm.$t('postponed_date'),
      "input-class": "focus:outline-none shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
    },
    model: {
      value: _vm.postponed.date,
      callback: function callback($$v) {
        _vm.$set(_vm.postponed, "date", $$v);
      },
      expression: "postponed.date"
    }
  })], 1), _c('div', {
    staticClass: "w-full md:w-full px-3 mb-2 mt-2"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.postponed.comment,
      expression: "postponed.comment"
    }],
    staticClass: "bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white",
    attrs: {
      "placeholder": "Your Comment"
    },
    domProps: {
      "value": _vm.postponed.comment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.postponed, "comment", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "w-full md:w-full flex items-start md:w-full px-3"
  }, [_c('div', {
    staticClass: "-mr-1"
  }, [_c('button', {
    staticClass: "rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": function click($event) {
        return _vm.ConfirmReporte('reporte');
      }
    }
  }, [_vm._v(" Confirm ")])])])])])]) : _vm._e(), _vm.config.name == 'shippings' || _vm.config.name == 'orders' ? _c('vue-modal-2', {
    attrs: {
      "name": "ModalChangeStatus",
      "headerOptions": {
        title: _vm.$t('change_status')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.OpenClosePopupModal('close');
      }
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingChange,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingChange = $event;
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-1/5 flex justify-center items-center"
  }, [_c('span', {
    staticClass: "w-16 h-16 bg-green-600 text-white p-3 rounded-full"
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "40px"
    }
  }, [_vm._v("update")])])]), _c('div', {
    staticClass: "w-full pt-9 pr-4"
  }, [_c('h3', {
    staticClass: "font-bold text-green-700 mb-8"
  }, [_vm._v(" " + _vm._s(_vm.$t("change_status")) + " ")]), _c('div', {
    staticClass: "mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))])])]), _c('p', [_vm.config.name == 'shippings' ? _c('v-select', {
    attrs: {
      "options": _vm.statusShippChange
    },
    model: {
      value: _vm.change_status.statusTochange,
      callback: function callback($$v) {
        _vm.$set(_vm.change_status, "statusTochange", $$v);
      },
      expression: "change_status.statusTochange"
    }
  }) : _c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.statusShippChange
    },
    model: {
      value: _vm.change_status.statusTochange,
      callback: function callback($$v) {
        _vm.$set(_vm.change_status, "statusTochange", $$v);
      },
      expression: "change_status.statusTochange"
    }
  })], 1)]), _vm.config.name == 'shippings' ? _c('div', {
    staticClass: "flex items-center mb-5 justify-between"
  }, [_c('span', {
    staticClass: "w-1/5 border-b dark:border-gray-600 md:w-1/4"
  }), _c('span', {
    staticClass: "text-xs text-green-600 font-bold cursor-pointer uppercase dark:text-blue-500 hover:underline",
    on: {
      "click": function click($event) {
        _vm.showcourier = !_vm.showcourier;
      }
    }
  }, [_c('i', {
    staticClass: "material-icons mr-2",
    staticStyle: {
      "font-size": "15px"
    }
  }, [_vm._v("person_add")]), _vm._v(" " + _vm._s(_vm.$t("select_courier")))]), _c('span', {
    staticClass: "w-1/5 border-b dark:border-gray-600 md:w-1/4"
  })]) : _vm._e(), _vm.showcourier && _vm.config.name == 'shippings' ? _c('div', {
    staticClass: "border mb-4 h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "category"
    }
  }, [_vm._v(_vm._s(_vm.$t("courier")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "fullName",
      "options": _vm.couriers
    },
    on: {
      "search": function search($event) {
        return _vm.searchCouriers($event, 'hh');
      }
    },
    model: {
      value: _vm.change_status.courier,
      callback: function callback($$v) {
        _vm.$set(_vm.change_status, "courier", $$v);
      },
      expression: "change_status.courier"
    }
  })], 1)]) : _vm._e()])]), _vm.config.name == 'shippings' ? _c('div', {
    staticClass: "tab-cc mx-4 s-sidebarwidget s-sidebarwidget__yellow s-anchors s-anchors__grayscale"
  }, [_c('div', {
    staticClass: "d-block p-0 m-0"
  }, [_c('div', {
    staticClass: "s-sidebarwidget--header flex s-sidebarwidget__small-bold-text fc-light d:fc-black-900 bb bbw1"
  }, [_c('svg', {
    staticClass: "fill-current mr-2",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
    }
  })]), _vm._v(" Change in order of cases ")]), _c('div', {
    staticClass: "s-sidebarwidget--item"
  }, [_c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('b', [_vm._v("To prepare")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("OutOfStock")])])]), _c('tr', [_c('td', [_c('b', [_vm._v("OutOfStock")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("To prepare")])])])])]), _c('hr', {
    staticClass: "hr-grey"
  }), _c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('b', [_vm._v("To prepare")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Prepared")])])]), _c('tr', [_c('td', [_c('b', [_vm._v("Prepared")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Shipped")])])]), _c('tr', [_c('td', [_c('b', [_vm._v("Shipped")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Delivered")])])]), _c('tr', [_c('td', [_c('b', [_vm._v("Delivered")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Paid")])])])])]), _c('hr', {
    staticClass: "hr-grey"
  }), _c('table', {
    staticClass: "w-full"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('b', [_vm._v("Shipped Or Delivered")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Cancelled")])])]), _c('tr', [_c('td', [_c('b', [_vm._v("Shipped Or Delivered")])]), _c('td', [_c('span', [_c('svg', {
    staticClass: "fill-current",
    attrs: {
      "width": "24",
      "height": "24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
    }
  })])])]), _c('td', {
    staticClass: "text-right"
  }, [_c('b', [_vm._v("Refused")])])])])])])])]) : _vm._e(), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopupModal('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.confirmChange
    }
  }, [_vm._v(_vm._s(_vm.$t("confirm")))])])])], 1) : _vm._e(), _vm.config.name == 'shippings' ? _c('vue-modal-2', {
    attrs: {
      "name": "ModalChangeStatusByDauchette",
      "headerOptions": {
        title: _vm.$t('change_status') + (_vm.newShipp.status ? " (".concat(_vm.newShipp.status, " : ").concat(_vm.newShipp.shippings.length, " orders)") : '')
      },
      "noFooter": true,
      "modalSize": "xl"
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-lg mb-20"
  }, [_c('div', {
    staticClass: "bg-dauch mb-2 px-3 mx-3 p-4 rounded-lg bg-white rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-1/5 flex justify-center items-center"
  }, [_c('span', {
    staticClass: "w-16 h-16 bg-green-600 text-white p-3 rounded-full"
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "40px"
    }
  }, [_vm._v("update")])])]), _c('div', {
    staticClass: "w-full pt-9 pr-4"
  }, [_c('h3', {
    staticClass: "font-bold text-green-700 mb-6"
  }, [_vm._v(" " + _vm._s(_vm.$t("change_status")) + " ")]), _c('div', {
    staticClass: "mb-2 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.newShipp.previous ? _vm.statusPrevious : _vm.statusShippChange
    },
    on: {
      "input": _vm.checkChangeStatusPrevieus
    },
    model: {
      value: _vm.newShipp.status,
      callback: function callback($$v) {
        _vm.$set(_vm.newShipp, "status", $$v);
      },
      expression: "newShipp.status"
    }
  })], 1)])])]), _vm.newShipp.status ? _c('div', {
    staticClass: "bg-white rounded-lg mb-14"
  }, [_c('div', {
    staticClass: "flex flex-col px-3 mx-3 p-4 rounded-lg bg-white bg-dauch"
  }, [_c('div', {
    staticClass: "flex flex-row"
  }, [_c('div', {
    staticClass: "flex w-full mb-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchTextShipp,
      expression: "searchTextShipp"
    }],
    staticClass: "upload-dropzone-border text-gray-700 text-xs border-b border-l border-r rounded-l-md rounded-r-md w-full focus:outline-none py-2 px-4",
    attrs: {
      "id": "inputshipping",
      "placeholder": "Scan qr code here or Traking Number ...",
      "type": "search",
      "autofill": "off"
    },
    domProps: {
      "value": _vm.searchTextShipp
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.searchTextShipp = $event.target.value;
      }, function ($event) {
        return _vm.searchShippByTrack($event);
      }]
    }
  })])]), _c('div', {
    staticStyle: {
      "overflow-x": "auto"
    }
  }, [_c('table', {
    staticClass: "min-w-full w-full"
  }, [_c('thead', {
    staticClass: "relative"
  }, [_c('tr', {
    staticClass: "bg-gray-400 text-gray-600 uppercase text-sm leading-normal"
  }, [_c('th', {
    staticClass: "px-3 w-10 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('label', {
    staticClass: "text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
  }, [_c('input', {
    staticClass: "form-checkbox checkallbboxShipp focus:outline-none focus:shadow-outline",
    attrs: {
      "type": "checkbox"
    },
    on: {
      "click": function click($event) {
        return _vm.selectAllCheckboxShipp($event);
      }
    }
  })])]), _c('th', {
    staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Order id ")])]), _c('th', {
    staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Seller ")])]), _c('th', {
    staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Custumer ")])]), _c('th', {
    staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Shipping Date ")])]), _c('th', {
    staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Traking number ")])]), _c('th', {
    staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Order ")])]), _c('th', {
    staticClass: "px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
  }, [_c('span', [_vm._v(" Status ")])])])]), _c('loading', {
    attrs: {
      "active": _vm.newShipp.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        return _vm.$set(_vm.newShipp, "loading", $event);
      }
    }
  }), _c('tbody', _vm._l(_vm.newShipp.shippings, function (item, index) {
    return _c('tr', {
      key: index,
      staticClass: "border-b border-gray-200 hover:bg-gray-100"
    }, [_c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
    }, [_c('label', {
      staticClass: "text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"
    }, [_c('input', {
      staticClass: "form-checkbox rowCheckboxShipp focus:outline-none focus:shadow-outline",
      class: 'rowProduct-' + item._id,
      attrs: {
        "type": "checkbox",
        "status": item.status ? item.status : '',
        "trackingNumber": item.trackingNumber,
        "id": item._id,
        "is_paid": item.is_paid ? item.is_paid : '',
        "orderobjet": item.orderObjet ? JSON.stringify(item.orderObjet) : '',
        "order": item.order ? item.order._id : '',
        "seller": item.seller && item.seller.source ? item.seller.source : 'nosource'
      },
      on: {
        "click": function click($event) {
          return _vm.getRowDetailShipp(item, item._id);
        }
      }
    })])]), _c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.orderObjet.id) + " ")])]), _c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.seller.fullName || item.seller.email) + " ")])]), _c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
    }, [_c('div', {
      staticClass: "leading-5 capitalize rounded-full text-gray-900",
      staticStyle: {
        "font-size": "10px",
        "line-height": "15px"
      }
    }, [_c('span', [_vm._v(_vm._s(item.orderObjet.customer.fullName))]), _c('span', {
      staticClass: "block"
    }, [_vm._v(_vm._s(item.orderObjet.customer.phone))])])]), _c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(_vm.$moment(item.date).format("yyyy-MM-DD")) + " ")])]), _c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
    }, [_vm._v(" " + _vm._s(item.trackingNumber) + " ")])]), _c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
    }, [item.order ? _c('span', {
      staticClass: "font-bold mr-1 text-blue-600"
    }, [_vm._v(_vm._s(item.order.details.length) + " Products")]) : _vm._e()])]), _c('td', {
      staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300 text-center"
    }, [_c('div', {
      staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
    }, [_c('button', {
      staticClass: "border capitalize rounded-full text-gray-600 text-xs px-4 bg-white hover:border-gray-500 focus:outline-none appearance-none",
      style: _vm.$f.ColorsStatusShippings(item.status)
    }, [_vm._v(" " + _vm._s(item.status) + " ")])])])]);
  }), 0)], 1), _vm.newShipp.shippings.length == 0 ? _c('div', {
    staticClass: "bg-blue-100 py-8"
  }, [_c('span', {
    staticClass: "w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
  }, [_c('img', {
    staticClass: "w-20 ml-4",
    attrs: {
      "alt": "...",
      "src": _vm.imageScannerShipp
    }
  })])]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "p-4 flex space-x-4 modal-footer"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    class: _vm.loadingdatashipp ? 'noselect' : '',
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopupModal('close', 'dauchette');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative",
    class: _vm.loadingdatashipp ? 'noselect' : '',
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.EmitActionNewShipp
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingdatashipp,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingdatashipp = $event;
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")], 1)])])]) : _vm._e(), _vm.config.name == 'products' ? _c('vue-modal-2', {
    attrs: {
      "name": "ModalChangeQte",
      "headerOptions": {
        title: _vm.$t('change_quantity')
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.OpenClosePopupModal('close');
      }
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-full pt-9 px-4"
  }, [_c('h3', {
    staticClass: "uppercase font-bold text-sm text-green-700 pt-2 mb-4 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("change_quantity")) + " ")]), _vm.selectProduct.name ? _c('div', {
    staticClass: "py-3 px-5 mx-2 mb-6 bg-gray-100 text-gray-900 rounded-md text-sm border border-gray-200",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t("product")) + ": " + _vm._s(_vm.selectProduct.name) + " ")])]), _c('p', {
    staticClass: "mb-3"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("current_quantity")) + ": ")])]), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-2/6 text-center px-2"
  }, [_c('div', {
    staticClass: "bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
  }, [_c('div', {
    staticClass: "w-1/3 bg-transparent flex items-center justify-center icon-step"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_in_stock")) + " ")]), _c('div', {
    staticClass: "w-2/3 bg-gray-200 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
  }, [_c('h2', {
    staticClass: "font-bold text-sm"
  }, [_vm._v(" Total: " + _vm._s(_vm.selectProduct.quantity.inStock) + " ")]), _c('div', {
    staticClass: "flex py-1"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_c('img', {
    staticClass: "w-4 h-4 rounded-full",
    attrs: {
      "src": _vm.$f.getCountryCode(_vm.producWarhouse.warehouse.country)
    }
  })]), _c('span', [_vm._v(_vm._s(_vm.producWarhouse.quantity.inStock))])])])])]), _c('div', {
    staticClass: "w-2/6 text-center px-2"
  }, [_c('div', {
    staticClass: "bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
  }, [_c('div', {
    staticClass: "w-1/3 bg-transparent flex items-center justify-center icon-step"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_total")) + " ")]), _c('div', {
    staticClass: "w-2/3 bg-gray-200 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
  }, [_c('h2', {
    staticClass: "font-bold text-sm"
  }, [_vm._v(" Total: " + _vm._s(_vm.selectProduct.quantity.total) + " ")]), _c('div', {
    staticClass: "flex py-1"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_c('img', {
    staticClass: "w-4 h-4 rounded-full",
    attrs: {
      "src": _vm.$f.getCountryCode(_vm.producWarhouse.warehouse.country)
    }
  })]), _c('span', [_vm._v(_vm._s(_vm.producWarhouse.quantity.total))])])])])]), _c('div', {
    staticClass: "w-2/6 text-center px-2"
  }, [_c('div', {
    staticClass: "bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200"
  }, [_c('div', {
    staticClass: "w-1/3 bg-transparent flex items-center justify-center icon-step"
  }, [_vm._v(" " + _vm._s(_vm.$t("quantity_defective")) + " ")]), _c('div', {
    staticClass: "w-2/3 bg-gray-200 flex flex-col items-center justify-center px-1 rounded-r-lg body-step"
  }, [_c('h2', {
    staticClass: "font-bold text-sm"
  }, [_vm._v(" Total: " + _vm._s(_vm.selectProduct.quantity.defective) + " ")]), _c('div', {
    staticClass: "flex py-1"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_c('img', {
    staticClass: "w-4 h-4 rounded-full",
    attrs: {
      "src": _vm.$f.getCountryCode(_vm.producWarhouse.warehouse.country)
    }
  })]), _c('span', [_vm._v(_vm._s(_vm.producWarhouse.quantity.defective))])])])])])])]) : _vm._e(), _c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-1/3 px-2"
  }, [_c('div', {
    staticClass: "mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "status"
    }
  }, [_vm._v(_vm._s(_vm.$t("type_operation")))])])]), _c('p', [_c('v-select', {
    staticClass: "v-instock",
    attrs: {
      "options": _vm.typeoperations
    },
    model: {
      value: _vm.ChangeQte.operation,
      callback: function callback($$v) {
        _vm.$set(_vm.ChangeQte, "operation", $$v);
      },
      expression: "ChangeQte.operation"
    }
  })], 1)])]), _c('div', {
    staticClass: "w-full lg:w-1/3 px-2"
  }, [_c('div', {
    staticClass: "mb-1 border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "status"
    }
  }, [_vm._v("Type stock")])])]), _c('p', [_c('v-select', {
    staticClass: "v-instock",
    attrs: {
      "options": _vm.typeostocks
    },
    model: {
      value: _vm.ChangeQte.stock,
      callback: function callback($$v) {
        _vm.$set(_vm.ChangeQte, "stock", $$v);
      },
      expression: "ChangeQte.stock"
    }
  })], 1)])]), _c('div', {
    staticClass: "w-full lg:w-1/3 px-2"
  }, [_c('div', {
    staticClass: "mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "quantity"
    }
  }, [_vm._v(_vm._s(_vm.$t("quantity")))])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.ChangeQte.quantity,
      expression: "ChangeQte.quantity"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "quantity",
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.ChangeQte.quantity
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.ChangeQte, "quantity", $event.target.value);
      }
    }
  })])])])]), _vm.expeditionData.warhouseParent.exist ? _c('section', {
    staticClass: "warehouse"
  }, [_c('div', {
    staticClass: "flex mt-10 mb-5 items-center text-center"
  }, [_c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  }), _c('label', {
    staticClass: "block font-medium text-sm cursor-pointer text-green-500 w-full"
  }, [_c('span', {
    staticClass: "font-medium text-base text-green-800 dark:text-green-200"
  }, [_vm._v("Choisir warehouse")])]), _c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  })]), _c('div', {
    staticClass: "bg-ww"
  }, [_c('h2', {
    staticClass: "text-sm font-bold truncate mb-5"
  }, [_vm._v(" Définir le warehouse pour ce produit ")]), _c('div', {
    staticClass: "w-full"
  }, _vm._l(_vm.checkWarehousingParent, function (it) {
    return _c('div', {
      key: it._id,
      staticClass: "border border-gray-600 intro-y flex-1 box-1 cursor-pointer zoom-in p-2 mb-2 pl-10",
      class: {
        active: it.fullOf
      },
      attrs: {
        "id": it._id
      },
      on: {
        "click": function click($event) {
          return _vm.activateWarehouse(it);
        }
      }
    }, [_c('div', {
      staticClass: "check-left"
    }, [_c('span', {
      staticClass: "feather-icon select-none relative"
    }, [_c('svg', {
      staticClass: "feather feather-check p-1 w-5 h-5 text-white rounded-full shadow",
      class: it.fullOf ? 'bg-green-600' : 'bg-gray-500',
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "width": "24px",
        "height": "24px",
        "viewBox": "0 0 24 24",
        "fill": "none",
        "stroke": "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }, [_c('polyline', {
      attrs: {
        "points": "20 6 9 17 4 12"
      }
    })])])]), _c('div', {
      staticClass: "text-sm font-bold text-left"
    }, [_vm._v(" " + _vm._s(it.name) + " "), it.is_primary ? _c('span', [_vm._v("(Principal)")]) : _vm._e()])]);
  }), 0)])]) : _vm._e(), _vm.expeditionData.setting.exist ? _c('section', [_c('div', {
    staticClass: "flex mt-10 mb-5 items-center text-center"
  }, [_c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  }), _c('label', {
    staticClass: "block font-medium text-sm cursor-pointer text-green-500 w-full"
  }, [_c('span', {
    staticClass: "font-medium text-base text-green-800 dark:text-green-200"
  }, [_vm._v("Choisir les services")])]), _c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  })]), _c('div', {
    staticClass: "bg-ww"
  }, [_c('h2', {
    staticClass: "text-sm font-bold truncate mb-5"
  }, [_vm._v(" Définir les services pour le nouveau warehouse de ce seller ")]), _c('div', {
    staticClass: "flex flex-col lg:flex-row"
  }, [_c('div', {
    staticClass: "border border-gray-600 intro-y flex-1 box-1 py-10 cursor-pointer zoom-in",
    class: {
      active: _vm.expeditionData.setting.confirmation
    },
    attrs: {
      "id": "confirmation"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('confirmation');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Confirmation ")])]), _c('div', {
    staticClass: "border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in",
    class: {
      active: _vm.expeditionData.setting.fulfillement
    },
    attrs: {
      "id": "fulfillement"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('fulfillement');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Warehouse ")])]), _c('div', {
    staticClass: "border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in",
    class: {
      active: _vm.expeditionData.setting.shipping
    },
    attrs: {
      "id": "shipping"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('shipping');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Shipping ")])])])])]) : _vm._e()])]), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('button', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    on: {
      "click": function click($event) {
        return _vm.OpenClosePopupModal('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative",
    class: _vm.ChangeQte.loading ? 'noselect' : '',
    on: {
      "click": _vm.confirmChangeQte
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.ChangeQte.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        return _vm.$set(_vm.ChangeQte, "loading", $event);
      }
    }
  }), _vm._v(_vm._s(_vm.$t("confirm")) + " ")], 1)])])], 1) : _vm._e(), _vm.config.name == 'orders' ? _c('dialog', {
    staticClass: "printingExcel"
  }, [_c('modal-export-excel-orders', {
    attrs: {
      "items": _vm.data
    }
  })], 1) : _vm._e(), _vm.config.name == 'shippings' ? _c('dialog', {
    staticClass: "printingExcel"
  }, [_c('modal-export-excel-shippings', {
    attrs: {
      "items": _vm.data
    }
  })], 1) : _vm._e(), _vm.config.name == 'expeditions' ? _c('vue-modal-2', {
    attrs: {
      "name": "ModalChangeSetSettingsSeller",
      "headerOptions": {
        title: 'Définir les services pour le nouveau warehouse de ce seller'
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.$vm2.close('ModalChangeSetSettingsSeller');
      }
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.expeditionData.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        return _vm.$set(_vm.expeditionData, "loading", $event);
      }
    }
  }), _c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-full p-4"
  }, [_c('div', {
    staticClass: "change-status border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "settings"
    }
  }, [_vm._v("Country (warhousing)")])])]), _c('p', [_vm.expeditionData.country ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.expeditionData.country,
      expression: "expeditionData.country"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.expeditionData.country
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.expeditionData, "country", $event.target.value);
      }
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "flex mt-10 mb-5 items-center text-center"
  }, [_c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  }), _c('label', {
    staticClass: "block font-medium text-sm cursor-pointer text-green-500 w-full"
  }, [_c('span', {
    staticClass: "font-medium text-base text-green-800 dark:text-green-200"
  }, [_vm._v("Choisir les services")])]), _c('hr', {
    staticClass: "border-gray-300 border-1 w-full rounded-md"
  })]), _c('div', {
    staticClass: "flex flex-col lg:flex-row"
  }, [_c('div', {
    staticClass: "border intro-y flex-1 box-1 py-10 cursor-pointer zoom-in",
    class: {
      active: _vm.expeditionData.setting.confirmation
    },
    attrs: {
      "id": "confirmation"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('confirmation');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Confirmation ")])]), _c('div', {
    staticClass: "border intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in",
    class: {
      active: _vm.expeditionData.setting.fulfillement
    },
    attrs: {
      "id": "fulfillement"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('fulfillement');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Warehouse ")])]), _c('div', {
    staticClass: "border intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in",
    class: {
      active: _vm.expeditionData.setting.shipping
    },
    attrs: {
      "id": "shipping"
    },
    on: {
      "click": function click($event) {
        return _vm.activate('shipping');
      }
    }
  }, [_c('div', {
    staticClass: "check"
  }, [_c('span', {
    staticClass: "feather-icon select-none relative"
  }, [_c('svg', {
    staticClass: "feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('polyline', {
    attrs: {
      "points": "20 6 9 17 4 12"
    }
  })])])]), _c('svg', {
    staticClass: "feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24px",
      "height": "24px",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
    }
  }), _c('line', {
    attrs: {
      "x1": "3",
      "y1": "6",
      "x2": "21",
      "y2": "6"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10a4 4 0 0 1-8 0"
    }
  })]), _c('div', {
    staticClass: "text-xl font-medium text-center mt-10"
  }, [_vm._v(" Shipping ")])])])])]), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.$vm2.close('ModalChangeSetSettingsSeller');
      }
    }
  }, [_vm._v("Cancel")]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.confirmChangeExpedition
    }
  }, [_vm._v("Confirme")])])])])], 1) : _vm._e(), _c('vue-modal-2', {
    attrs: {
      "name": "messages",
      "headerOptions": {
        title: 'Info messages'
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.closePopup('messages');
      }
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-lg p-2"
  }, [!_vm.messagesInfo.previous ? _c('div', {
    staticClass: "rounded-lg"
  }, [_vm.messagesInfo.messages.length > 0 ? _c('span', _vm._l(_vm.messagesInfo.messages, function (msg, ll) {
    return _c('p', {
      key: ll,
      staticClass: "Note mx-2"
    }, [_c('strong', [_vm._v("Note")]), _vm._v(": " + _vm._s(msg) + " ")]);
  }), 0) : _vm._e(), _vm.messagesInfo.info.length > 0 ? _c('div', {
    staticClass: "overflow-x-auto w-full px-4"
  }, [_c('table', {
    staticClass: "table w-full table-messages"
  }, [_c('thead', [_c('tr', {
    staticClass: "bg-gray-700 dark:bg-dark-1 text-white text-left"
  }, [_c('th', {
    staticClass: "whitespace-nowrap p-2"
  }, [_vm._v("Product")]), _c('th', {
    staticClass: "whitespace-nowrap p-2"
  }, [_vm._v(" Quantity " + _vm._s(_vm.messagesInfo.status == "paid" ? "Total" : "in Stock") + " ")]), _c('th', {
    staticClass: "whitespace-nowrap p-2"
  }, [_vm._v("NB orders")]), _c('th', {
    staticClass: "whitespace-nowrap p-2"
  }, [_vm._v("NB quantity orders")]), _c('th', {
    staticClass: "whitespace-nowrap p-2"
  }, [_vm._v("Info")]), _c('th', {
    staticClass: "whitespace-nowrap p-2"
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.messagesInfo.info, function (item, index) {
    return _c('tr', {
      key: index,
      class: 'bg-' + item.type
    }, [_c('td', {
      staticClass: "border-b dark:border-dark-5 p-2"
    }, [_vm._v(" " + _vm._s(item.productName) + " ")]), _c('td', {
      staticClass: "border-b dark:border-dark-5 p-2"
    }, [_vm._v(" " + _vm._s(item.inStock) + " ")]), _c('td', {
      staticClass: "border-b dark:border-dark-5 p-2"
    }, [_vm._v(" " + _vm._s(item.count) + " ")]), _c('td', {
      staticClass: "border-b dark:border-dark-5 p-2"
    }, [_vm._v(" " + _vm._s(item.qte) + " ")]), _c('td', {
      staticClass: "border-b p-2 dark:border-dark-5",
      class: item.type
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('td', {
      staticClass: "border-b p-2 dark:border-dark-5",
      class: item.type
    }, [_vm._v(" " + _vm._s(item.type) + " ")])]);
  }), 0)])]) : _vm._e()]) : _c('div', {
    staticStyle: {
      "overflow-x": "auto"
    }
  }, _vm._l(_vm.messagesInfo.messages, function (msg, ll) {
    return _c('table', {
      key: ll,
      staticClass: "min-w-full w-full"
    }, [_c('caption', {
      staticStyle: {
        "background-color": "#20827b",
        "color": "#fff",
        "margin-bottom": "10px",
        "text-transform": "uppercase"
      }
    }, [_vm._v(" " + _vm._s(msg.module) + " ")]), _c('thead', {
      staticClass: "relative"
    }, [msg.module == 'shippings' ? _c('tr', {
      staticClass: "bg-gray-400 text-gray-600 uppercase text-sm leading-normal"
    }, [_c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Order id ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Traking number ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Old Status ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" status ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Message ")])]), _c('th', {
      staticClass: "px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Action ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" View ")])])]) : _vm._e(), msg.module == 'payments' ? _c('tr', {
      staticClass: "bg-gray-400 text-gray-600 uppercase text-sm leading-normal"
    }, [_c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Payment id ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Old data ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" New data ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Message ")])]), _c('th', {
      staticClass: "px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" Action ")])]), _c('th', {
      staticClass: "px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider"
    }, [_c('span', [_vm._v(" View ")])])]) : _vm._e()]), _c('loading', {
      attrs: {
        "active": _vm.loadingdatashipp,
        "is-full-page": _vm.fullPage
      },
      on: {
        "update:active": function updateActive($event) {
          _vm.loadingdatashipp = $event;
        }
      }
    }), msg.module == 'shippings' ? _c('tbody', _vm._l(msg.datas, function (item, index) {
      return _c('tr', {
        key: index,
        staticClass: "border-b border-gray-200 hover:bg-gray-100"
      }, [_c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_vm._v(" " + _vm._s(item.orderId) + " ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_vm._v(" " + _vm._s(item.trackingNumber) + " ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_c('button', {
        staticClass: "border capitalize rounded-full text-gray-600 text-xs px-2 bg-white hover:border-gray-500 focus:outline-none appearance-none",
        style: _vm.$f.ColorsStatusShippings(item.oldStatus) + ';font-size: 10px;line-height: 15px;'
      }, [_vm._v(" " + _vm._s(item.oldStatus) + " ")])])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_c('button', {
        staticClass: "border capitalize rounded-full text-gray-600 text-xs px-2 bg-white hover:border-gray-500 focus:outline-none appearance-none",
        style: _vm.$f.ColorsStatusShippings(item.status) + ';font-size: 10px;line-height: 15px;'
      }, [_vm._v(" " + _vm._s(item.status) + " ")])])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900",
        staticStyle: {
          "font-size": "10px"
        }
      }, [_vm._v(" " + _vm._s(item.text) + " ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900",
        class: item.type
      }, [_vm._v(" " + _vm._s(item.type) + " ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_c('router-link', {
        attrs: {
          "target": "_blank",
          "to": {
            path: "/shippings?name=".concat(item.trackingNumber, "&country=").concat(_vm.idWharhouse.country)
          }
        }
      }, [_c('i', {
        staticClass: "material-icons"
      }, [_vm._v(" remove_red_eye")])])], 1)])]);
    }), 0) : _vm._e(), msg.module == 'payments' ? _c('tbody', _vm._l(msg.datas, function (item, index) {
      return _c('tr', {
        key: index,
        staticClass: "border-b border-gray-200 hover:bg-gray-100"
      }, [_c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_vm._v(" " + _vm._s(item.id) + " ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_vm._v(" " + _vm._s(item.oldCount) + " orders ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_vm._v(" " + _vm._s(item.newCount) + " orders ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900",
        staticStyle: {
          "font-size": "10px"
        }
      }, [_vm._v(" " + _vm._s(item.text) + " ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [_c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900",
        class: item.type
      }, [_vm._v(" " + _vm._s(item.type) + " ")])]), _c('td', {
        staticClass: "px-3 py-1 whitespace-no-wrap border-b border-gray-300"
      }, [!item.isDeleted ? _c('div', {
        staticClass: "text-xs leading-5 capitalize rounded-full text-gray-900"
      }, [_c('router-link', {
        attrs: {
          "target": "_blank",
          "to": {
            path: "/payments/detail/".concat(item._id)
          }
        }
      }, [_c('i', {
        staticClass: "material-icons"
      }, [_vm._v(" remove_red_eye")])])], 1) : _vm._e()])]);
    }), 0) : _vm._e()], 1);
  }), 0), _c('div', {
    staticClass: "px-4 py-2 flex float-right space-x-4"
  }, [_c('a', {
    staticClass: "px-10 py-2 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.closePopup('messages');
      }
    }
  }, [_vm._v("OK")])])])]), _vm.config.name == 'shippings' && _vm.orderToUpdate.orderObjet ? _c('vue-modal-2', {
    attrs: {
      "name": "updateorders",
      "headerOptions": {
        title: "Update details order (id: ".concat(_vm.orderToUpdate.orderObjet.id, ")")
      },
      "noFooter": true,
      "modalSize": "lg"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.closePopup('updateorders');
      }
    }
  }, [_c('div', {
    staticClass: "bg-white rounded-lg"
  }, [_c('div', {
    staticClass: "rounded-lg flex"
  }, [_c('div', {
    staticClass: "w-1/4 flex justify-center items-center"
  }, [_c('span', {
    staticClass: "w-16 h-16 bg-green-600 text-white p-3 rounded-full"
  }, [_c('i', {
    staticClass: "material-icons",
    staticStyle: {
      "font-size": "40px"
    }
  }, [_vm._v("update")])])]), _c('div', {
    staticClass: "w-full pr-4 mt-4"
  }, [_vm._l(_vm.orderToUpdate.orderObjet.details, function (it, indx) {
    return _c('div', {
      key: indx,
      staticClass: "grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4"
    }, [_c('div', {
      staticClass: "grid grid-cols-1"
    }, [_c('div', {
      staticClass: "flex items-center ml-auto"
    }, [_c('div', {
      staticClass: "flex-shrink-0 w-10 h-10"
    }, [_c('img', {
      staticClass: "h-10 w-10 rounded-md mx-auto",
      attrs: {
        "alt": "...",
        "src": it.picture ? it.picture : 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })]), _c('div', {
      staticClass: "ml-3"
    }, [_vm._v(_vm._s(it.name))])])]), _c('div', {
      staticClass: "grid grid-cols-1"
    }, [_c('div', {
      staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
    }, [_c('div', {
      staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
    }, [_c('p', [_c('label', {
      staticClass: "bg-white text-gray-700 px-1",
      attrs: {
        "for": "fullname"
      }
    }, [_vm._v(_vm._s(_vm.$t("unit_price")) + " "), _vm.idWharhouse ? _c('sup', [_vm._v("(" + _vm._s(_vm.idWharhouse.currency) + ")")]) : _vm._e()])])]), _c('p', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.unitPrice,
        expression: "it.unitPrice"
      }],
      staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": it.unitPrice
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "unitPrice", $event.target.value);
        }
      }
    })])])]), _c('div', {
      staticClass: "grid grid-cols-1"
    }, [_c('div', {
      staticClass: "border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
    }, [_c('div', {
      staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
    }, [_c('p', [_c('label', {
      staticClass: "bg-white text-gray-700 px-1",
      attrs: {
        "for": "Email"
      }
    }, [_vm._v(_vm._s(_vm.$t("quantity")))])])]), _c('p', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: it.quantity,
        expression: "it.quantity"
      }],
      staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
      attrs: {
        "type": "number"
      },
      domProps: {
        "value": it.quantity
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(it, "quantity", $event.target.value);
        }
      }
    })])])])]);
  }), _c('div', {
    staticClass: "flex justify-end mt-4"
  }, [_c('div', {
    staticClass: "text-danger flex items-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("total_price")) + ": " + _vm._s(_vm.TotalPrice(_vm.orderToUpdate.orderObjet)) + " "), _vm.idWharhouse ? _c('sup', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.idWharhouse.currency))]) : _vm._e()])])], 2)]), _c('div', {
    staticClass: "p-4 flex space-x-4"
  }, [_c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.closePopup('updateorders');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('a', {
    staticClass: "w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative",
    class: _vm.loadingupdateorder ? 'noselect' : '',
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        return _vm.UpdateOrder();
      }
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.loadingupdateorder,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loadingupdateorder = $event;
      }
    }
  }), _vm._v(_vm._s(_vm.$t("confirme")) + " ")], 1)])])]) : _vm._e()], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "bg-gray-100 text-black"
  }, [_c('tr', [_c('th', {
    staticClass: "px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" Model ")]), _c('th', {
    staticClass: "px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" Create ")]), _c('th', {
    staticClass: "px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" Watch ")]), _c('th', {
    staticClass: "px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" Read ")]), _c('th', {
    staticClass: "px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" Update ")]), _c('th', {
    staticClass: "px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider"
  }, [_vm._v(" Delete ")])])]);

}]

export { render, staticRenderFns }