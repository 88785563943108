<template>
  <div>
    <!--filter bar -->
    <div-filter v-if="
      config.name != 'shippingsHistories' &&
      config.name != 'stockHistories' &&
      config.name != 'statusHistories'
    " :name="config.name" :config="config" :filters="filters" :currentUser="currentUser" :idWharhouse="idWharhouse"
      @reset="reset" @filtrer="filtrer" @search="search" @searchSellers="searchSellers"
      @searchCouriers="searchCouriers">
    </div-filter>
    <!-- end filter bar -->
    <div :class="config.class">
      <div class="bg-white w-full rounded-lg shadow">
        <div v-if="
          (config.name == 'expeditions' &&
            (currentUser.type == 'Admin' || currentUser.type == 'CEO')) ||
          config.name == 'products'
        ">
          <div @click="openAnalytics = !openAnalytics"
            class="py-4 cursor-pointer bg-grey-lighter hover:bg-grey-light text-indigo-darker rounded rounded-t-none text-center uppercase font-bold flex items-center justify-center text-green-600">
            <span class="block border border-gray-400 w-1/3 mr-2"></span>
            <button
              class="border border-teal-500 text-teal-500 block rounded-sm font-bold py-4 px-6 mr-2 flex items-center hover:bg-teal-500 hover:text-white">
              {{ $t("Show analytics") }}
              <i class="material-icons text-green-600 ml-3">timeline</i></button><span
              class="block border border-gray-400 w-1/3 ml-2"></span>
          </div>
          <chart-expeditions v-if="openAnalytics && config.name == 'expeditions'" :currentUser="currentUser" />
          <chart-products v-if="openAnalytics && config.name == 'products'" :currentUser="currentUser" />
        </div>
        <div class="h-20 py-3 flex items-center border-gray-200 p-6">
          <div class="flex w-1/3">
            <i v-if="config.icone" class="material-icons p-3 text-green-400">{{
              config.icone
            }}</i>
            <span class="mx-4 text-2xl font-bold text-green-600">{{
              $t(config.title)
            }}</span>
            <!-- <span v-if="config.name=='statusHistories' && emailCurrentCall" class="mx-4 text-2xl semi-bold text-black-500">({{ emailCurrentCall }})</span> -->
            <div v-on-clickaway="awayLimit" v-if="config.name == 'shippings' || config.name == 'orders'">
              <button @click="openLimit = !openLimit" class="vs-con-dropdown parent-dropdown cursor-pointer">
                <div class="p-2 shadow rounded-lg cursor-pointer flex items-end justify-center text-sm font-medium">
                  <span class="mr-2">{{ paginate.currentpage || 1 }} - {{ data.length }} /
                    {{ paginate.total || data.length }}
                  </span>
                  <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px"
                      height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down h-4 w-4">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg></span>
                </div>
              </button>
              <div class="relative">
                <div v-if="openLimit"
                  class="absolute focus:ring-2 focus:ring-blue-600 right-0 dropdown-action-limit w-auto mt-3 z-10 origin-top-right rounded-md shadow-lg">
                  <div class="bg-white rounded-md shadow dark-mode:bg-gray-800 dropdown-limit">
                    <a @click="Changelimit(50)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">50</a>
                    <a @click="Changelimit(100)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">100</a>
                    <a @click="Changelimit(200)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">200</a>
                    <a @click="Changelimit(300)"
                      class="block cursor-pointer px-2 text-center py-1 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">300</a>
                  </div>
                  <div class="vs-dropdown--limit--after"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-4/6 flex relative justify-end space-x-4">
            <div v-if="
              config.options.actions.isActive &&
              (!config.options.actions.method ||
                config.options.actions.link) &&
              getAccess('create', null, null, true)
            " class="w-1/2 flex justify-end space-x-4">
              <router-link v-for="(item, index) in config.options.actions.buttons" :key="index" v-if="item.link"
                :to="item.link"
                class="rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"><i
                  class="material-icons mr-2">{{ item.icon }}</i>
                {{ $t(item.text) }}</router-link>
            </div>

            <div v-if="config.name == 'orders' || config.name == 'shippings'" @click="downloadExcel"
              class="ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
              <i class="material-icons">file_download</i> Excel
            </div>
            <div v-if="
              config.name == 'shippings' &&
              (currentUser.type == 'Admin' ||
                currentUser.type == 'CEO' ||
                currentUser.type == 'StockManager')
            " @click="OpenClosePopupModal('open', 'dauchette')"
              class="ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
              <i class="material-icons mr-2">perm_scan_wifi </i> Douchette
            </div>
            <div v-if="
              config.name == 'shippings' &&
              (currentUser.type == 'CEO' ||
                currentUser.email == 'oumaimacodinafrica@gmail.com')
            " @click="OpenClosePopupModal('open', 'dauchette', 'previous')"
              class="ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
              <svg class="fill-current mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24">
                <path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z" />
              </svg>
              Previous status
            </div>
            <div v-on-clickaway="away" v-if="
              config.options.actions.isActive &&
              config.options.actions.method &&
              (config.name == 'shippings' ||
                config.name == 'orders' ||
                config.name == 'payments' ||
                config.name == 'products') &&
              getAccessLinks(null)
            ">
              <button @click="open = !open" class="vs-con-dropdown parent-dropdown cursor-pointer">
                <div class="p-3 shadow rounded-lg cursor-pointer flex items-end justify-center text-lg font-medium">
                  <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px"
                      height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical h-4 w-4">
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="12" cy="5" r="1"></circle>
                      <circle cx="12" cy="19" r="1"></circle>
                    </svg></span>
                </div>
              </button>
              <div v-if="open" x-transition:enter="transition ease-out duration-100"
                x-transition:enter-start="transform opacity-0 scale-95"
                x-transition:enter-end="transform opacity-100 scale-100"
                x-transition:leave="transition ease-in duration-75"
                x-transition:leave-start="transform opacity-100 scale-100"
                x-transition:leave-end="transform opacity-0 scale-95"
                class="absolute focus:ring-2 focus:ring-green-600 right-0 dropdown-action w-full mt-5 z-10 origin-top-right rounded-md shadow-lg">
                <div class="bg-white rounded-md shadow dark-mode:bg-gray-800">
                  <a v-for="(item, index) in config.options.actions.buttons" :key="index"
                    v-show="getAccessLinks(item.action) && !item.link" @click="EmitMethodAction(item.action)"
                    class="block cursor-pointer px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline relative loidingconfirm"
                    :disabled="loadingpdf">
                    <loading v-if="item.action === 'printdDelivry'" :active.sync="loadingpdf" :is-full-page="fullPage">
                    </loading><i class="material-icons align-bottom mr-2">{{
                      item.icon
                    }}</i>
                    {{ $t(item.text) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="
            config.options.actions.isActive &&
            config.options.actions.method &&
            config.name == 'statusHistories'
          " class="w-1/2 flex justify-end space-x-4">
            <button @click="downloadAnalytics" v-for="(item, index) in config.options.actions.buttons" :key="index"
              class="rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
              <i class="material-icons mr-2">{{ item.icon }}</i>
              {{ $t(item.text) }}
            </button>
          </div>
        </div>
        <div v-if="isFilterCourier && config.name == 'shippings'" class="flex justify-center border-gray-200">
          <div
            class="w-5/12 bg-green-100 border-t-4 border-green-600 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2"
            role="alert">
            <div class="flex">
              <i class="large material-icons mr-5">account_balance_wallet</i>
              <div>
                <p v-if="CourierInfo.courier" class="font-bold">
                  {{ $t("courier") }} : <span>{{ CourierInfo.courier }}</span>
                </p>
                <p class="font-bold">
                  {{ $t("total_orders") }} <span>{{ CourierInfo.count }}</span>
                </p>
                <p class="font-bold">
                  {{ $t("total") }} :
                  <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup>
                  {{ CourierInfo.total }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style="overflow-x: auto">
          <table id="loading" class="min-w-full w-full">
            <thead class="relative">
              <tr class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                <th v-for="(item, key) in config.columns" :key="key" :class="item.class"
                  class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                  <label v-if="item.type && config.name != 'products'"
                    class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                    <input type="checkbox" @click="selectAllCheckbox($event)"
                      class="form-checkbox checkallbbox focus:outline-none focus:shadow-outline" />
                  </label>
                  <span v-else>
                    {{ $t(item.name) }}
                  </span>
                </th>
                <th v-if="
                  config.actions &&
                  config.actions.elements &&
                  config.actions.elements.length != 0
                "
                  class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                  {{ config.actions.name }}
                </th>
              </tr>
            </thead>
            <loading :active.sync="loading" :is-full-page="fullPage"></loading>
            <tbody v-for="(item, index) in data" :key="index">
              <tr :class="index % 2 != 0 ? 'bg-gray-100' : ''" class="border-b border-gray-200 hover:bg-gray-100">
                <td v-for="(col, ind) in config.columns" :key="ind"
                  class="px-5 py-2 whitespace-no-wrap border-b border-gray-300">
                  <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                    <div v-for="(element, indElm) in col.elements" :key="indElm">
                      <div v-for="(itemst, itemIndex) in element.items" :key="itemIndex"
                        :class="itemst.name == 'status' ? 'text-center' : ''">
                        <div v-if="!itemst.elements">
                          <!-- Status -->
                          <div v-if="
                            itemst.name == 'status' ||
                            itemst.name == 'previousStatus'
                          ">
                            <div v-if="!itemst.data">
                              <!-- status expedition -->
                              <div v-if="config.name != 'shippingsHistories'">
                                <button @click="
                                  ShowPopUpInformation(
                                    index,
                                    data[index][itemst.name],
                                    item
                                  )
                                  " :class="itemst.class" :style="itemst.options(data[index][itemst.name])
                                    ">
                                  {{ data[index][itemst.name] }}
                                </button>
                                <div v-if="
                                  config.name === 'shippings' &&
                                  data[index][itemst.name] &&
                                  (data[index][itemst.name] === 'paid' ||
                                    data[index][itemst.name] === 'processed')
                                ">
                                  <span :class="data[index][itemst.name] === 'paid'
                                    ? 'label-paid'
                                    : 'label-accept'
                                    " class="jvectormap-tip text-white">{{
                                      data[index][itemst.name] === "paid"
                                        ? "PaidAt"
                                        : "ProcessedAt"
                                    }}
                                    ({{
                                      formattedDate(
                                        data[index][itemst.name] === "paid"
                                          ? data[index]["paidAt"]
                                          : data[index]["updatedAt"],
                                        "yyyy-MM-DD"
                                      )
                                    }})</span>
                                </div>





                              </div>
                              <div v-else>
                                <button :class="itemst.class" :style="itemst.options(data[index][itemst.name])
                                  ">
                                  {{ data[index][itemst.name] }}
                                </button>
                              </div>
                            </div>
                            <div v-else>
                              <!-- status orders -->
                              <button :disabled="!currentUser.type == 'Admin' ||
                                !currentUser.type == 'CEO' ||
                                !currentUser.type == 'TeleConsultant' ||
                                !currentUser.type == 'NRP'
                                " @click="ShowPopUpInformation(index, null, item)" :class="itemst.class" v-bind:style="{
                                  color: data[index][itemst.name]
                                    ? data[index][itemst.name][
                                    itemst.champs.color
                                    ]
                                    : '',
                                  borderColor: data[index][itemst.name]
                                    ? data[index][itemst.name][
                                    itemst.champs.color
                                    ]
                                    : '',
                                }">
                                {{
                                  data[index][itemst.name]
                                    ? data[index][itemst.name][
                                    itemst.champs.name
                                    ]
                                    : ""
                                }}
                              </button>

                              <template v-if="
                                config.name === 'orders' &&
                                data[index][itemst.name] &&
                                data[index][itemst.name][itemst.champs.name]
                              ">
                                <span v-if="
                                  data[index]['sameOrderUnreached'] &&
                                  data[index]['sameOrderUnreached'].length > 0
                                " class="flex items-center justify-center sous-s">
                                  <svg class="fill-current mr-3" xmlns="http://www.w3.org/2000/svg" width="15"
                                    height="15" viewBox="0 0 24 24">
                                    <path
                                      d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z" />
                                  </svg>
                                  <span>Unreached ({{
                                    data[index]["sameOrderUnreached"].length
                                  }})</span>
                                  <span v-if="
                                    data[index][itemst.name][
                                    itemst.champs.name
                                    ] === 'Pending' &&
                                    data[index]['lastUnreachedDate']
                                  "><svg class="fill-current ml-2 align-sub inline-block"
                                      xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
                                      <path
                                        d="M12 12h3v2h-5v-6h2v4zm9-1h-1.07c-.251-2.006-1.218-3.792-2.647-5.093-1.283-1.166-1.228-2.247-1.856-5.907h-7.854c-.628 3.66-.573 4.741-1.856 5.907-1.67 1.52-2.717 3.701-2.717 6.124 0 2.424 1.048 4.606 2.72 6.126 1.28 1.164 1.232 2.267 1.853 5.843h7.854c.622-3.576.573-4.679 1.854-5.843 1.445-1.314 2.421-3.124 2.66-5.157h1.059v-2zm-9.5 7.5c-3.589 0-6.5-2.911-6.5-6.5s2.911-6.5 6.5-6.5 6.5 2.911 6.5 6.5-2.911 6.5-6.5 6.5z" />
                                    </svg>
                                    {{
                                      formattedDate(
                                        data[index]["lastUnreachedDate"],
                                        "yyyy-MM-DD HH:mm"
                                      )
                                    }}</span>
                                </span>
                                <span v-if="
                                  data[index]['remind'] &&
                                  (data[index][itemst.name][
                                    itemst.champs.name
                                  ] === 'Pending' ||
                                    data[index][itemst.name][
                                    itemst.champs.name
                                    ] === 'OutOfStock')
                                " class="flex items-center justify-center sous-s">
                                  <svg class="fill-current mr-3" xmlns="http://www.w3.org/2000/svg" width="15"
                                    height="15" viewBox="0 0 24 24">
                                    <path
                                      d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
                                  </svg>
                                  <span>Reminded (To:
                                    {{
                                      $moment(data[index]["remind"]).format(
                                        "yyyy-MM-DD HH:mm"
                                      )
                                    }})</span>
                                </span>
                              </template>
                              <template v-if="
                                config.name === 'orders' &&
                                data[index][itemst.name] &&
                                data[index][itemst.name][
                                itemst.champs.name
                                ] === 'Cancelled'
                              ">
                                <span v-if="data[index]['validated_status']"
                                  class="jvectormap-tip text-white label-accept">Validated ({{
                                    formattedDate(
                                      data[index]["validated_status"],
                                      "yyyy-MM-DD"
                                    )
                                  }})</span>
                                <span v-if="data[index]['comment']" class="jvectormap-tip text-white label-accept">{{
                                  data[index]["comment"] }}</span>
                              </template>
                              <template v-if="
                                config.name === 'orders' &&
                                data[index][itemst.name] &&
                                data[index][itemst.name][
                                itemst.champs.name
                                ] === 'A transférer'
                              ">
                                <span v-if="data[index]['is_transfere'] > 1"
                                  class="jvectormap-tip text-white label-accept">À transférer plusieur fois ({{
                                    data[index].is_transfere
                                  }})</span>
                              </template>
                            </div>
                          </div>
                          <div v-else-if="
                            itemst.name == 'type' &&
                            config.name != 'stockHistories'
                          ">
                            <span :style="itemst.options(data[index][itemst.name])">{{ data[index][itemst.name]
                              }}</span>
                          </div>
                          <div v-else :class="itemst.class">
                            <div v-if="itemst.array">
                              <!--zones(number of cities type array)-->
                              {{ data[index][itemst.name].length }}
                            </div>
                            <div v-else-if="itemst.date">
                              <!--filed type date-->
                              {{ formattedDate(data[index][itemst.name]) }}
                            </div>
                            <div v-else-if="itemst.history">
                              <span :class="data[index]['status'] == 'in'
                                ? 'text-green-600'
                                : 'text-red-600'
                                "><span v-if="data[index]['status'] == 'in'">
                                  <i class="material-icons">arrow_drop_up</i></span><span v-else>
                                  <i class="material-icons">arrow_drop_down</i></span>
                                {{ data[index][itemst.name] }}</span>
                            </div>
                            <div v-else-if="itemst.type == 'checkbox'">
                              <label
                                class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                <input type="checkbox" :class="'rowCheckboxProduct-' + item._id" :status="item.status
                                  ? item.status.name || item.status
                                  : ''
                                  " :orderObjet="item.orderObjet
                                    ? JSON.stringify(item.orderObjet)
                                    : ''
                                    " :is_paid="item.is_paid ? item.is_paid : ''"
                                  :order="item.order ? item.order._id : ''" :sellerid="item.seller
                                    ? item.seller._id || item.seller
                                    : ''
                                    " :statusid="item.status
                                      ? item.status._id || item.status
                                      : ''
                                      " :seller="item.seller && item.seller.source
                                        ? item.seller.source
                                        : 'nosource'
                                        " :id="item._id" @click="getRowDetail(item, item._id)"
                                  class="form-checkbox rowCheckbox focus:outline-none focus:shadow-outline" />
                              </label>
                            </div>
                            <div v-else>
                              <div v-if="
                                itemst.name == 'country' ||
                                itemst.name == 'country_to'
                              " class="flex">
                                <span v-if="Array.isArray(data[index][itemst.name])" class="flex">
                                  <img v-for="(countr, r) in data[index][
                                    itemst.name
                                  ]" :key="r" :src="$f.getCountryCode(countr)" class="w-4 h-4 rounded-full mr-2" />
                                </span>
                                <span v-else>{{
                                  $f.getCountryNameByCode(
                                    dataCountry,
                                    data[index][itemst.name]
                                  )
                                }}</span>
                              </div>
                              <div v-else-if="
                                itemst.name == 'address' ||
                                itemst.name == 'name' ||
                                itemst.name == 'source'
                              ">
                                {{ exceptText(data[index][itemst.name]) }}
                              </div>
                              <div v-else>
                                <!--Producs quatite-->
                                <div v-if="itemst.arrayD" class="text-center">
                                  <span class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">Total: {{
                                    data[index][itemst.name]
                                  }}</span>
                                  <div v-for="(wharhouse, i) in data[index][
                                    itemst.arrayD]" :key="i"
                                    v-if="idWharhouse && wharhouse.warehouse._id == idWharhouse._id.toString()"
                                    class="flex justify-center items-center py-1">
                                    <div class="mr-2">
                                      <img class="w-4 h-4 rounded-full" :src="wharhouse.country" />
                                    </div>
                                    <span>{{
                                      wharhouse.quantity[itemst.name]
                                    }}</span>
                                  </div>
                                </div>
                                <!-- /Producs quatite-->
                                <div :class="itemst.name == 'id' ||
                                  itemst.name == 'order_id'
                                  ? 'normal-case'
                                  : ''
                                  " v-else>
                                  <span v-if="
                                    itemst.name === 'oldObject' ||
                                    itemst.name === 'newObject'
                                  ">
                                    <span v-if="
                                      data[index][itemst.name] &&
                                      data[index][itemst.name].settings &&
                                      data[index][itemst.name].settings.length
                                    ">
                                      <div v-for="(setting, i) in data[index][
                                        itemst.name
                                      ].settings" :key="i" class="flex flex-col lg:flex-row mb-2 items-center">
                                        <img :src="setting.flag" class="w-4 h-4 rounded-full mr-2" />
                                        <div id="confirmation" style="transform: inherit" :class="setting.confirmation ? 'active' : ''
                                          " class="intro-y mr-1 flex-1 box-1 py-1">
                                          <div class="text-smmm font-medium text-center">
                                            Confirmation
                                          </div>
                                        </div>
                                        <div style="transform: inherit" id="Warehouse" :class="setting.fulfillement ? 'active' : ''
                                          " class="intro-y mr-1 flex-1 box-1 py-1">
                                          <div class="text-smmm font-medium text-center">
                                            Warehouse
                                          </div>
                                        </div>
                                        <div id="Shipping" style="transform: inherit" :class="setting.shipping ? 'active' : ''
                                          " class="intro-y flex-1 box-1 py-1">
                                          <div class="text-smmm font-medium text-center">
                                            Shipping
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                    <span v-else-if="
                                      data[index][itemst.name] &&
                                      data[index][itemst.name].countries &&
                                      data[index][itemst.name].countries
                                        .length
                                    " class="flex">
                                      <span v-for="(images, r) in data[index][
                                        itemst.name
                                      ].countries" :key="r">
                                        <img :src="'https://static.dwcdn.net/css/flag-icons/flags/4x3/' +
                                          images.toLowerCase() +
                                          '.svg'
                                          " class="w-4 h-4 rounded-full mr-2" />
                                      </span>
                                    </span>
                                    <span v-else-if="
                                      data[index][itemst.name] &&
                                      data[index][itemst.name].permissions &&
                                      data[index][itemst.name].permissions
                                        .length
                                    ">
                                      <table class="table w-full text-center">
                                        <thead class="bg-gray-100 text-black">
                                          <tr>
                                            <th
                                              class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider">
                                              Model
                                            </th>
                                            <th
                                              class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider">
                                              Create
                                            </th>
                                            <th
                                              class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider">
                                              Watch
                                            </th>
                                            <th
                                              class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider">
                                              Read
                                            </th>
                                            <th
                                              class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider">
                                              Update
                                            </th>
                                            <th
                                              class="px-1 py-1 border-gray-200 text-left text-smmm font-medium text-gray-700 capitalize tracking-wider">
                                              Delete
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(el, mm) in data[index][
                                            itemst.name
                                          ].permissions" :key="mm" :class="mm % 2 == 0 ? 'bg-gray-100' : ''
                                            ">
                                            <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm">
                                              <p class="text-gray-700 capitalize" style="font-size: 11px">
                                                {{ el.model }}
                                              </p>
                                            </td>

                                            <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm">
                                              <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                <label class="inline-flex text-sm items-center capitalize">
                                                  <input v-model="el.access.create" disabled type="checkbox"
                                                    class="form-checkbox border-gray-500 h-4 w-4 text-green-500" />
                                                </label>
                                              </p>
                                            </td>
                                            <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm">
                                              <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                <label class="inline-flex text-sm items-center capitalize">
                                                  <input v-model="el.access.watch" type="checkbox" disabled
                                                    class="form-checkbox border-gray-500 h-4 w-4 text-green-500" />
                                                </label>
                                              </p>
                                            </td>
                                            <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm">
                                              <p class="text-gray-600 ml-2 text-semibold capitalize">
                                                <label class="inline-flex text-sm items-center capitalize">
                                                  <input v-model="el.access.read" type="checkbox" disabled
                                                    class="form-checkbox border-gray-500 h-4 w-4 text-green-500" />
                                                </label>
                                              </p>
                                            </td>
                                            <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm">
                                              <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                <label class="inline-flex text-sm items-center capitalize">
                                                  <input v-model="el.access.update" type="checkbox" disabled
                                                    class="form-checkbox border-gray-500 h-4 w-4 text-green-500" />
                                                </label>
                                              </p>
                                            </td>
                                            <td class="px-1 py-1 pt-3 text-left border-gray-200 bg-white text-sm">
                                              <p class="text-gray-600 ml-3 text-semibold capitalize">
                                                <label class="inline-flex text-sm items-center capitalize">
                                                  <input v-model="el.access.delete" type="checkbox" disabled
                                                    class="form-checkbox border-gray-500 h-4 w-4 text-green-500" />
                                                </label>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </span>
                                    <span v-else>
                                      {{ data[index][itemst.name] }}
                                    </span>
                                  </span>

                                  <span v-else v-html="data[index][itemst.name]"></span>

                                  <sup class="text-black uppercase ml-1" v-if="
                                    itemst.name == 'totalPrice' && idWharhouse
                                  ">{{ idWharhouse.currency }}</sup>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <!-- show two fieds seller(name,nemail),custumers(name,phone) ... -->
                          <div v-for="(ele, itemelem) in itemst.elements" :key="itemelem">
                            <div v-for="(refit, ind) in ele.items" :key="ind">
                              <div :class="refit.class">
                                <div v-if="refit.name == 'picture'">
                                  <img :src="data[index][itemst.name][refit.name] ||
                                    'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                    " class="h-12 w-12" />
                                </div>
                                <div v-else>
                                  <span v-if="refit.name == 'city'">
                                    <!--city order in shipping-->

                                    <i v-if="refit.icone" class="material-icons tiny">{{ refit.icone }}</i>
                                    {{
                                      data[index][itemst.name]["customer"][
                                      refit.name
                                      ]
                                    }}
                                  </span>
                                  <span v-else>
                                    <span v-if="
                                      data[index][itemst.name] &&
                                      !isEmpty(
                                        data[index][itemst.name][refit.name]
                                      )

                                    ">

                                      <div
                                        v-if="data[index][itemst.name][refit.name] !== 'camex' && data[index][itemst.name][refit.name] !== 'firstdelivery'">
                                        <i v-if="refit.icone" class="material-icons tiny">contacts</i>

                                        <span v-if="
                                          refit.name == 'shippingAddress' ||
                                          refit.name == 'fullName'
                                        ">{{
                                          exceptText(
                                            data[index][itemst.name][
                                            refit.name
                                            ],
                                            "fullName"
                                          )
                                        }}</span>
                                        <span v-else>{{
                                          data[index][itemst.name][refit.name]
                                        }}</span>
                                      </div>
                                      <div class="flex items-center justify-start w-full" v-else>
                                        <img v-if="data[index][itemst.name][refit.name] == 'camex'" :src="camexImage"
                                          style="height: 35px; width: 50px; border-radius:5px" />
                                        <img v-else :src="FirstDeliveryImage"
                                          style="height: 50px; width: 70px; object-fit: contain" />

                                      </div>

                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="
                      (col.model && col.model == 'expedition') ||
                      (col.model && col.model == 'order') ||
                      (col.model && col.model == 'payments') ||
                      (col.model && col.model == 'shippings')
                    ">
                      <!-- Expedition and Order and Shipping sous table -->
                      <div class="flex">
                        <div v-if="
                          (col.model && col.model == 'expedition') ||
                          (col.model && col.model == 'order') ||
                          (col.model && col.model == 'payments')
                        ">
                          <p>
                            <span class="font-semibold mr-1 text-green-600">
                              <span v-if="col.model == 'order'">{{
                                QuantityTotalProductOrderOnly(
                                  data[index][col.name]
                                )
                              }}</span><span v-else>{{ data[index][col.name].length }}
                              </span> </span><b>{{ $t(col.title) }}</b>
                          </p>
                          <span class="font-light text-gray-700">
                            {{
                              $moment(data[index]["date"]).format("yyyy-MM-DD")
                            }}
                          </span>
                        </div>
                        <div v-else>
                          <!--shppings -->
                          <p>
                            <span class="font-bold mr-1 text-green-600">
                              {{ data[index].order.details.length }} </span>{{ $t("products") }}
                          </p>
                          <span class="font-light text-gray-700">{{ $t("quantity") }}: </span><b>{{
                            QuantityTotalProductOrder(data[index])
                          }}</b>
                        </div>
                        <button v-if="config.sousTable" @click="showSousTable(index)" :class="$colors.newColor"
                          class="bg-gray-100 hover:bg-green-100 hover:text-white rounded-full mr-2 flex items-center text-2xl focus:outline-none p-1 ml-1">
                          <i class="material-icons" style="transition: 0.3s" :style="element && element._id == item._id
                            ? 'transform: rotate(180deg)'
                            : ''
                            ">keyboard_arrow_down</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
                <td v-if="
                  config.actions &&
                  config.actions.elements &&
                  config.actions.elements.length != 0
                " class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div class="flex" :class="config.actions.class">
                    <div v-for="(action, ind) in config.actions.elements" :key="ind">
                      <div @click="
                        getAccess(
                          action.key,
                          data[index]['status'],
                          data[index]['type'],
                          action.role,
                          data[index]['lastUnreachedDate'] ||
                          data[index]['remind'] ||
                          null
                        )
                        " v-if="
                          getAccess(
                            action.key,
                            data[index]['status'],
                            data[index]['type'],
                            action.role,
                            data[index]['lastUnreachedDate'] ||
                            data[index]['remind'] ||
                            null
                          ) && action.role
                        " :class="$colors.actionTable">
                        <div v-if="action.link">
                          <!--link view, edit -->
                          <router-link :to="{
                            path: `${action.link}/${data[index]['_id']}`,
                          }" :class="config.actions.class"><i class="material-icons mx-2">
                              {{ action.icone }}</i></router-link>
                        </div>
                        <div v-else>
                          <!--link delete -->
                          <button @click="
                            emitFunction(
                              action.key,
                              data[index]['_id'],
                              data[index]
                            )
                            " :class="config.actions.class">
                            <i class="material-icons mx-2">{{
                              action.icone
                            }}</i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="
                config.sousTable &&
                element &&
                element._id == data[index]['_id']
              ">
                <!-- sous table info expedition and order -->
                <td :colspan="config.columns.length + 1" class="border">
                  <div class="w-full rounded-2xl">
                    <table class="table w-full text-center" :class="config.name == 'shippings' ? 'table-shipp' : ''">
                      <thead class="bg-gray-100 text-black">
                        <tr>
                          <th v-for="(col, ind) in config.columnsDialog.elements" :key="ind"
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t(col.name) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="
                        config.name == 'expeditions' ||
                        config.name == 'orders' ||
                        config.name == 'payments'
                      ">
                        <tr v-for="(row, j) in element[
                          config.columnsDialog.details
                        ]" :key="j" class="bg-light-green-200">
                          <td v-for="(col, ind) in config.columnsDialog.elements" :key="ind"
                            class="text-xs leading-5 capitalize text-gray-900">
                            <div v-for="(item, indeitem) in col.items" :key="indeitem">
                              <div v-if="config.name == 'expeditions'">
                                <!-- Expedition sous table -->
                                <span v-if="col.attribut == 'product'">
                                  <div v-if="item.name == 'picture'">
                                    <img :src="[row.product][0][item.name] ||
                                      'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                      " class="w-8 my-2 rounded-md mx-auto" />
                                  </div>
                                  <div v-else>
                                    {{ [row.product][0][item.name] }}
                                  </div>
                                </span>
                                <span v-else>
                                  <div v-if="item.name == 'received'">
                                    <table class="min-w-full">
                                      <thead>
                                        <tr>
                                          <th
                                            class="text-center bg-gray-200 px-6 py-1 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">
                                            {{ $t("ok_good") }}
                                          </th>
                                          <th
                                            class="text-center bg-gray-200 px-6 py-1 border border-gray-300 text-left leading-4 text-green-500 tracking-wider">
                                            {{ $t("defective") }}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody class="bg-white">
                                        <tr class="bg-light-green-200">
                                          <td class="text-xs leading-5 capitalize text-gray-900">
                                            {{ [row.quantity][0][item.name] }}
                                          </td>
                                          <td class="text-xs leading-5 capitalize text-gray-900">
                                            {{ [row.quantity][0][item.name_1] }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div v-else>
                                    <div v-if="item.name == 'merchandise_value'">
                                      {{ [row][0][item.name] }}
                                      <sup class="text-black uppercase" v-if="idWharhouse">{{ idWharhouse.currency
                                        }}</sup>
                                    </div>
                                    <div v-else>
                                      {{ [row.quantity][0][item.name] }}
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div v-else-if="config.name == 'orders'">
                                <!-- Order sous table -->
                                <div v-if="col.referent">
                                  <span v-if="col.referent == 'product'">
                                    <div v-if="item.name == 'picture'">
                                      <img :src="[row.product][0][item.name] ||
                                        'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                        " class="w-8 my-2 rounded-md mx-auto" />
                                    </div>
                                    <div v-else>
                                      {{ [row.product][0][item.name] }}
                                    </div>
                                  </span>
                                  <span v-else>{{
                                    [row.category][0][item.name]
                                  }}</span>
                                </div>
                                <div v-else>
                                  {{ [row][0][item.name] }}
                                  <sup class="text-black uppercase" v-if="
                                    item.name == 'unitPrice' && idWharhouse
                                  ">{{ idWharhouse.currency }}</sup>
                                </div>
                              </div>
                              <div v-else>
                                <!-- payament sous tabl -->
                                <div v-if="item.referent">
                                  <div v-if="item.field">
                                    {{
                                      row[item.referent][item.field][item.name]
                                    }}
                                  </div>
                                  <div v-else>
                                    {{ row[item.referent][item.name] }}
                                    <sup class="text-black uppercase" v-if="
                                      item.name == 'totalPrice' &&
                                      config.name == 'payments' &&
                                      idWharhouse
                                    ">{{ idWharhouse.currency }}</sup>
                                  </div>
                                </div>
                                <div v-else>
                                  <div :class="(item.name == 'shipping_refund_vat' ||
                                    item.name == 'shipping_cancel_vat') &&
                                    row[item.name] == 0 &&
                                    config.name == 'payments'
                                    ? 'empty-row'
                                    : ''
                                    ">
                                    {{ row[item.name] }}
                                    <sup class="text-black uppercase" v-if="
                                      config.name == 'payments' && idWharhouse
                                    ">{{ idWharhouse.currency }}</sup>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <!--Shipping sous table order info-->
                        <tr class="bg-light-green-200">
                          <td v-for="(col, ind) in config.columnsDialog.elements" :key="ind"
                            class="text-xs leading-5 capitalize text-gray-900">
                            <div v-for="(item, indeitem) in col.items" :key="indeitem">
                              <div v-if="col.attribut">
                                <div v-if="!item.elements">
                                  <span>{{
                                    data[index][col.referent][col.attribut][
                                    item.name
                                    ]
                                  }}</span>
                                </div>
                                <div v-else>
                                  <!-- Show two fields (Custumer) -->
                                  <div v-for="(ele, itemelem) in item.elements" :key="itemelem">
                                    <div v-for="(refit, ind) in ele.items" :key="ind">
                                      <div :class="ele.class">
                                        <div v-if="col.attribut != 'seller'">

                                          <i v-if="refit.icone" class="material-icons tiny">{{ refit.icone }}</i>
                                          {{
                                            data[index][col.referent][
                                            col.attribut
                                            ][refit.name]
                                          }}
                                        </div>
                                        <div v-else>
                                          <span v-if="
                                            !isEmpty(
                                              data[index][
                                                config.columnsDialog.details
                                              ][config.columnsDialog.name][0]
                                                .product.seller[refit.name]
                                            )
                                          ">

                                            <i v-if="refit.icone" class="material-icons tiny">{{ refit.icone }}</i>
                                            {{
                                              data[index][
                                                config.columnsDialog.details
                                              ][config.columnsDialog.name][0]
                                                .product.seller[refit.name]
                                            }}</span>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                {{ data[index][col.referent][item.name] }}
                                <sup class="text-black uppercase" v-if="
                                  item.name == 'totalPrice' && idWharhouse
                                ">{{ idWharhouse.currency }}</sup>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <thead class="bg-gray-100 text-black">
                        <!--Shipping sous table product order info-->
                        <tr>
                          <th v-for="(col, ind) in config.columnsDialog
                            .sousElements" :key="ind"
                            class="px-5 py-2 bg-gray-100 text-center text-xs font-semibold text-gray-600 capitalize tracking-wider">
                            {{ $t(col.name) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(prod, k) in data[index][
                          config.columnsDialog.details
                        ][config.columnsDialog.name]" :key="k" class="bg-light-green-200">
                          <!-- sous table products in order -->
                          <td v-for="(col, ind) in config.columnsDialog
                            .sousElements" :key="ind" class="text-xs leading-5 capitalize text-gray-900">
                            <div v-for="(item, indeitem) in col.items" :key="indeitem">
                              <div v-if="col.referent">
                                <div v-if="item.name == 'picture'">
                                  <img :src="prod[col.referent][item.name] ||
                                    'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                                    " class="w-8 my-2 rounded-md mx-auto" />
                                </div>
                                <div v-else>
                                  {{ prod[col.referent][item.name] }}
                                </div>
                              </div>
                              <div v-else>
                                <span>{{ prod[item.name] }}
                                  <sup class="text-black uppercase" v-if="
                                    item.name == 'unitPrice' && idWharhouse
                                  ">{{ idWharhouse.currency }}</sup></span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              <!--**************************************Debut Modal Change Shipping Address  and Status Order***************************-->

              <vue-modal-2 v-if="config.name == 'orders'" :name="'modal1_' + index"
                :headerOptions="{ title: $t('order_information') }" :noFooter="true" modalSize="xl"
                @on-close="closePopUpInformation(index)">
                <modal-info-order :item="item" :index="index" :idWharhouse="idWharhouse" :cities="cities"
                  @getFeesShippBycity="getFeesShippBycity" :feesShipp="feesShipp"
                  :CheckQteDefecteuseorderexist="CheckQteDefecteuseorderexist"
                  @CheckQteDefecteuseOrder="CheckQteDefecteuseOrder" :courier="courier" :currentUser="currentUser"
                  @getCourierZone="getCourierZone" @OrderChange="OrderChange" :shipping="shipping" :config="config"
                  :statusOrder="statusOrder" :zones="zones" />
                <div class="modal-footer px-5 border0-t text-right">
                  <button @click="closePopUpInformation(index)"
                    class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
                    Cancel
                  </button>
                  <button @click="ConfirmOperationUpdate(item, index)"
                    class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm"
                    :disabled="loadingcreateShipp">
                    <loading :active.sync="loadingcreateShipp" :is-full-page="fullPage"></loading>Confirm
                  </button>
                </div>
              </vue-modal-2>

              <!--**************************************Debut Modal Change Shipping Address  and Status Order***************************-->
              <!--**************************************Debut Modal Change status recived expeditions***************************-->

              <vue-modal-2 v-if="config.name == 'expeditions'" :name="'modal1_' + index"
                :headerOptions="{ title: $t('expedition_information') }" :noFooter="true" modalSize="xl"
                @on-close="closePopUpInformation(index)">
                <modal-info-expo :item="item" :index="index" :config="config"
                  :CheckExpeditionRecived="CheckExpeditionRecived" @CheckQteSentExist="CheckQteSentExist"
                  :statusExpedition="statusExpedition" :transports="transports" />
                <div class="modal-footer px-5 border0-t text-right">
                  <button @click="closePopUpInformation(index)"
                    class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
                    Cancel
                  </button>
                  <button @click="ConfirmOperationUpdate(item, index)"
                    class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right relative loidingconfirm"
                    :disabled="loadingcreateShipp">
                    <loading :active.sync="loadingcreateShipp" :is-full-page="fullPage"></loading>Confirm
                  </button>
                </div>
              </vue-modal-2>

              <!--**************************************Debut Modal Change status recived expeditions***************************-->
              <!--**************************************Debut Modal Change status shipping***************************-->

              <vue-modal-2 v-if="config.name == 'shippings'" :name="'modal1_' + index"
                :headerOptions="{ title: $t('shipping_informations') }" :noFooter="true" modalSize="xl"
                @on-close="closePopUpInformation(index)">
                <modal-info-shipp :item="item" :index="index" :dataCountry="dataCountry" :feesShipp="feesShipp"
                  :couriers="couriers" @ChangeQuantityOrder="ChangeQuantityOrder" @searchCouriers="searchCouriers"
                  :currentUser="currentUser" :config="config" @OpenClosePopupModalReporte="OpenClosePopupModalReporte"
                  :CheckQteDefecteuseexist="CheckQteDefecteuseexist" :CheckQteReturnedexist="CheckQteReturnedexist"
                  @CheckQteDefecteuse="CheckQteDefecteuse" @CheckQteReturned="CheckQteReturned"
                  :CheckQteDefecteuseorderexist="CheckQteDefecteuseorderexist"
                  @CheckQteDefecteuseOrder="CheckQteDefecteuseOrder" :zones="zones" :statusShipp="statusShipp"
                  :typesShipp="typesShipp" :idWharhouse="idWharhouse" />
                <div class="modal-footer px-5 border0-t text-right">
                  <button @click="closePopUpInformation(index)"
                    class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
                    Cancel
                  </button>
                  <button @click="ConfirmOperationUpdate(item, index)"
                    class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600 py-2 px-8 items-center focus:outline-none md:float-right loidingconfirm relative"
                    :class="loadingcreateShipp ? 'noselect' : ''">
                    <loading :active.sync="loadingcreateShipp" :is-full-page="fullPage"></loading>Confirm
                  </button>
                </div>
              </vue-modal-2>

              <!--**************************************/Debut Modal Change status shipping***************************-->
              <!--**************************************Debut Modal Change shipping print***************************-->
              <vue-modal-2 v-if="config.name == 'shippings'" class="printing" name="print-shippings-delivry"
                :headerOptions="{ title: $t('shipping_informations') }" :noFooter="true" modalSize="xl">
                <modal-info-shipp-print :item="item" :index="index" :idWharhouse="idWharhouse" :config="config" />
              </vue-modal-2>
              <!--**************************************/Debut Modal Change shipping print***************************-->
            </tbody>
          </table>
        </div>
        <div v-if="data.length <= 0" class="bg-green-100 py-8">
          <span class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base">
            <p class="font-semibold flex text-green-500 whitespace-no-wrap">
              <i class="material-icons mx-3">error</i>
              <span class>{{ $t("no_rows_selected") }}</span>
            </p>
          </span>
        </div>
      </div>
      <!--pagination -->
      <div class="p-1" v-if="paginate.lastpage > 1">
        <div class="flex flex-col items-center my-12">
          <div-paginate :paginate="paginate" @paginatation="paginatation" />
        </div>
      </div>
      <!--end pagination -->
      <!--Modal reporté shipping-->
      <vue-modal-2 v-if="config.name == 'shippings'" class="modalreporteshipp" name="ModalReporteShipp"
        :headerOptions="{ title: $t('postponed_shippings') }" :noFooter="true" modalSize="lg"
        @on-close="OpenClosePopupModalReporte('close', 'postponed')">
        <div class="w-full bg-white rounded-lg px-4 pt-2">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-full px-3 mb-2 mt-2">
              <datetime-v2 :min-datetime="$moment(Date.now()).format('yyyy-MM-DD')" v-model="postponed.date"
                :placeholder="$t('postponed_date')"
                input-class="focus:outline-none shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"></datetime-v2>
            </div>
            <div class="w-full md:w-full px-3 mb-2 mt-2">
              <textarea
                class="bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white"
                v-model="postponed.comment" placeholder="Your Comment"></textarea>
            </div>
            <div class="w-full md:w-full flex items-start md:w-full px-3">
              <div class="-mr-1">
                <button @click="ConfirmReporte('reporte')"
                  class="rounded flex focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </vue-modal-2>
      <!--Modal change status Shipping -->
      <vue-modal-2 v-if="config.name == 'shippings' || config.name == 'orders'" name="ModalChangeStatus"
        :headerOptions="{ title: $t('change_status') }" :noFooter="true" modalSize="lg"
        @on-close="OpenClosePopupModal('close')">
        <loading :active.sync="loadingChange" :is-full-page="fullPage"></loading>
        <div class="bg-white rounded-lg">
          <div class="rounded-lg flex">
            <div class="w-1/5 flex justify-center items-center">
              <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons"
                  style="font-size: 40px">update</i></span>
            </div>
            <div class="w-full pt-9 pr-4">
              <h3 class="font-bold text-green-700 mb-8">
                {{ $t("change_status") }}
              </h3>
              <div
                class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                  <p>
                    <label for="status" class="bg-white text-gray-700 px-1">{{
                      $t("status")
                    }}</label>
                  </p>
                </div>
                <p>
                  <v-select v-if="config.name == 'shippings'" v-model="change_status.statusTochange"
                    :options="statusShippChange"></v-select>
                  <v-select v-else label="name" v-model="change_status.statusTochange"
                    :options="statusShippChange"></v-select>
                </p>
              </div>
              <div v-if="config.name == 'shippings'" class="flex items-center mb-5 justify-between">
                <span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
                <span @click="showcourier = !showcourier"
                  class="text-xs text-green-600 font-bold cursor-pointer uppercase dark:text-blue-500 hover:underline"><i
                    class="material-icons mr-2" style="font-size: 15px">person_add</i>
                  {{ $t("select_courier") }}</span>
                <span class="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
              </div>
              <div v-if="showcourier && config.name == 'shippings'"
                class="border mb-4 h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                  <p>
                    <label for="category" class="bg-white text-gray-700 px-1">{{ $t("courier") }}
                      <span :class="$colors.required">*</span></label>
                  </p>
                </div>
                <p>
                  <v-select label="fullName" @search="searchCouriers($event, 'hh')" v-model="change_status.courier"
                    :options="couriers"></v-select>
                </p>
              </div>
            </div>
          </div>
          <div v-if="config.name == 'shippings'"
            class="tab-cc mx-4 s-sidebarwidget s-sidebarwidget__yellow s-anchors s-anchors__grayscale">
            <div class="d-block p-0 m-0">
              <div
                class="s-sidebarwidget--header flex s-sidebarwidget__small-bold-text fc-light d:fc-black-900 bb bbw1">
                <svg xmlns="http://www.w3.org/2000/svg" class="fill-current mr-2" width="20" height="20"
                  viewBox="0 0 24 24">
                  <path
                    d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z" />
                </svg>
                Change in order of cases
              </div>
              <div class="s-sidebarwidget--item">
                <table class="w-full">
                  <tbody>
                    <tr>
                      <td><b>To prepare</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>OutOfStock</b></td>
                    </tr>
                    <tr>
                      <td><b>OutOfStock</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>To prepare</b></td>
                    </tr>
                  </tbody>
                </table>
                <hr class="hr-grey" />
                <table class="w-full">
                  <tbody>
                    <tr>
                      <td><b>To prepare</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Prepared</b></td>
                    </tr>
                    <tr>
                      <td><b>Prepared</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Shipped</b></td>
                    </tr>
                    <tr>
                      <td><b>Shipped</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Delivered</b></td>
                    </tr>
                    <tr>
                      <td><b>Delivered</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Paid</b></td>
                    </tr>
                  </tbody>
                </table>
                <hr class="hr-grey" />
                <table class="w-full">
                  <tbody>
                    <tr>
                      <td><b>Shipped Or Delivered</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Cancelled</b></td>
                    </tr>
                    <tr>
                      <td><b>Shipped Or Delivered</b></td>
                      <td>
                        <span><svg class="fill-current" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                          </svg></span>
                      </td>
                      <td class="text-right"><b>Refused</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="p-4 flex space-x-4">
            <a href="#" @click="OpenClosePopupModal('close')"
              class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm">{{
                $t("cancel") }}</a>
            <a href="#" @click="confirmChange"
              class="w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm">{{
                $t("confirm") }}</a>
          </div>
        </div>
      </vue-modal-2>

      <!-- end modal change status shipping -->
      <!--Modal change status Shipping By dauchette -->
      <vue-modal-2 v-if="config.name == 'shippings'" name="ModalChangeStatusByDauchette" :headerOptions="{
        title:
          $t('change_status') +
          (newShipp.status
            ? ` (${newShipp.status} : ${newShipp.shippings.length} orders)`
            : ''),
      }" :noFooter="true" modalSize="xl">
        <div class="bg-white rounded-lg mb-20">
          <div class="bg-dauch mb-2 px-3 mx-3 p-4 rounded-lg bg-white rounded-lg flex">
            <div class="w-1/5 flex justify-center items-center">
              <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons"
                  style="font-size: 40px">update</i></span>
            </div>
            <div class="w-full pt-9 pr-4">
              <h3 class="font-bold text-green-700 mb-6">
                {{ $t("change_status") }}
              </h3>
              <div
                class="mb-2 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                  <p>
                    <label for="status" class="bg-white text-gray-700 px-1">{{
                      $t("status")
                    }}</label>
                  </p>
                </div>
                <p>
                  <v-select @input="checkChangeStatusPrevieus" v-model="newShipp.status" :options="newShipp.previous ? statusPrevious : statusShippChange
                    "></v-select>
                </p>
              </div>
            </div>
          </div>
          <div v-if="newShipp.status" class="bg-white rounded-lg mb-14">
            <div class="flex flex-col px-3 mx-3 p-4 rounded-lg bg-white bg-dauch">
              <div class="flex flex-row">
                <div class="flex w-full mb-5">
                  <input id="inputshipping" @input="searchShippByTrack($event)"
                    placeholder="Scan qr code here or Traking Number ..." v-model="searchTextShipp" type="search"
                    autofill="off"
                    class="upload-dropzone-border text-gray-700 text-xs border-b border-l border-r rounded-l-md rounded-r-md w-full focus:outline-none py-2 px-4" />
                </div>
              </div>
              <div style="overflow-x: auto">
                <table class="min-w-full w-full">
                  <thead class="relative">
                    <tr class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                      <th
                        class="px-3 w-10 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <label
                          class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"><input
                            type="checkbox" @click="selectAllCheckboxShipp($event)"
                            class="form-checkbox checkallbboxShipp focus:outline-none focus:shadow-outline" /></label>
                      </th>
                      <th
                        class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <span> Order id </span>
                      </th>
                      <th
                        class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <span> Seller </span>
                      </th>
                      <th
                        class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <span> Custumer </span>
                      </th>
                      <th
                        class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <span> Shipping Date </span>
                      </th>
                      <th
                        class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <span> Traking number </span>
                      </th>
                      <th
                        class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <span> Order </span>
                      </th>
                      <th
                        class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                        <span> Status </span>
                      </th>
                    </tr>
                  </thead>
                  <loading :active.sync="newShipp.loading" :is-full-page="fullPage"></loading>
                  <tbody>
                    <tr v-for="(item, index) in newShipp.shippings" :key="index"
                      class="border-b border-gray-200 hover:bg-gray-100">
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                        <label
                          class="text-lg text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer"><input
                            type="checkbox" @click="getRowDetailShipp(item, item._id)"
                            :status="item.status ? item.status : ''" :trackingNumber="item.trackingNumber"
                            :id="item._id" :is_paid="item.is_paid ? item.is_paid : ''" :orderobjet="item.orderObjet
                              ? JSON.stringify(item.orderObjet)
                              : ''
                              " :order="item.order ? item.order._id : ''" :seller="item.seller && item.seller.source
                                ? item.seller.source
                                : 'nosource'
                                " :class="'rowProduct-' + item._id"
                            class="form-checkbox rowCheckboxShipp focus:outline-none focus:shadow-outline" /></label>
                      </td>
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                          {{ item.orderObjet.id }}
                        </div>
                      </td>
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                          {{ item.seller.fullName || item.seller.email }}
                        </div>
                      </td>
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                        <div style="font-size: 10px; line-height: 15px"
                          class="leading-5 capitalize rounded-full text-gray-900">
                          <span>{{ item.orderObjet.customer.fullName }}</span><span class="block">{{
                            item.orderObjet.customer.phone
                          }}</span>
                        </div>
                      </td>
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                          {{ $moment(item.date).format("yyyy-MM-DD") }}
                        </div>
                      </td>
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                          {{ item.trackingNumber }}
                        </div>
                      </td>
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                          <span v-if="item.order" class="font-bold mr-1 text-blue-600">{{ item.order.details.length }}
                            Products</span>
                        </div>
                      </td>
                      <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300 text-center">
                        <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                          <button
                            class="border capitalize rounded-full text-gray-600 text-xs px-4 bg-white hover:border-gray-500 focus:outline-none appearance-none"
                            :style="$f.ColorsStatusShippings(item.status)">
                            {{ item.status }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="newShipp.shippings.length == 0" class="bg-blue-100 py-8">
                  <span class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base">
                    <img alt="..." class="w-20 ml-4" :src="imageScannerShipp" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="p-4 flex space-x-4 modal-footer">
            <a href="#" @click="OpenClosePopupModal('close', 'dauchette')" :class="loadingdatashipp ? 'noselect' : ''"
              class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm">{{
                $t("cancel") }}</a>
            <a href="#" @click="EmitActionNewShipp" :class="loadingdatashipp ? 'noselect' : ''"
              class="w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative">
              <loading :active.sync="loadingdatashipp" :is-full-page="fullPage"></loading>
              {{ $t("confirm") }}
            </a>
          </div>
        </div>
      </vue-modal-2>

      <!-- end modal change status shipping -->
      <!--Modal change status Shipping -->
      <vue-modal-2 v-if="config.name == 'products'" name="ModalChangeQte"
        :headerOptions="{ title: $t('change_quantity') }" :noFooter="true" modalSize="lg"
        @on-close="OpenClosePopupModal('close')">
        <loading :active.sync="loading" :is-full-page="fullPage"></loading>
        <div class="bg-white rounded-lg">
          <div class="rounded-lg flex">
            <!-- <div class="w-1/3 pt-6 flex justify-center items-center">
                        <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons" style="font-size: 40px;">import_export</i>{{selectProduct.name}}</span>
                    </div> -->
            <div class="w-full pt-9 px-4">
              <h3 class="uppercase font-bold text-sm text-green-700 pt-2 mb-4 text-center">
                {{ $t("change_quantity") }}
              </h3>
              <div v-if="selectProduct.name"
                class="py-3 px-5 mx-2 mb-6 bg-gray-100 text-gray-900 rounded-md text-sm border border-gray-200"
                role="alert">
                <p>
                  <strong>{{ $t("product") }}: {{ selectProduct.name }}
                  </strong>
                </p>
                <p class="mb-3">
                  <strong>{{ $t("current_quantity") }}: </strong>
                </p>
                <div class="flex flex-wrap">
                  <div class="w-2/6 text-center px-2">
                    <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
                      <div class="w-1/3 bg-transparent flex items-center justify-center icon-step">
                        {{ $t("quantity_in_stock") }}
                      </div>
                      <div
                        class="w-2/3 bg-gray-200 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                        <h2 class="font-bold text-sm">
                          Total: {{ selectProduct.quantity.inStock }}
                        </h2>
                        <div class="flex py-1">
                          <div class="mr-2">
                            <img class="w-4 h-4 rounded-full" :src="$f.getCountryCode(
                              producWarhouse.warehouse.country
                            )
                              " />
                          </div>
                          <span>{{ producWarhouse.quantity.inStock }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-2/6 text-center px-2">
                    <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
                      <div class="w-1/3 bg-transparent flex items-center justify-center icon-step">
                        {{ $t("quantity_total") }}
                      </div>
                      <div
                        class="w-2/3 bg-gray-200 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                        <h2 class="font-bold text-sm">
                          Total: {{ selectProduct.quantity.total }}
                        </h2>
                        <div class="flex py-1">
                          <div class="mr-2">
                            <img class="w-4 h-4 rounded-full" :src="$f.getCountryCode(
                              producWarhouse.warehouse.country
                            )
                              " />
                          </div>
                          <span>{{ producWarhouse.quantity.total }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-2/6 text-center px-2">
                    <div class="bg-gray-300 rounded-lg flex items-center justify-center border border-gray-200">
                      <div class="w-1/3 bg-transparent flex items-center justify-center icon-step">
                        {{ $t("quantity_defective") }}
                      </div>
                      <div
                        class="w-2/3 bg-gray-200 flex flex-col items-center justify-center px-1 rounded-r-lg body-step">
                        <h2 class="font-bold text-sm">
                          Total: {{ selectProduct.quantity.defective }}
                        </h2>
                        <div class="flex py-1">
                          <div class="mr-2">
                            <img class="w-4 h-4 rounded-full" :src="$f.getCountryCode(
                              producWarhouse.warehouse.country
                            )
                              " />
                          </div>
                          <span>{{ producWarhouse.quantity.defective }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="w-full lg:w-1/3 px-2">
                  <div
                    class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="status" class="bg-white text-gray-700 px-1">{{ $t("type_operation") }}</label>
                      </p>
                    </div>
                    <p>
                      <v-select class="v-instock" v-model="ChangeQte.operation" :options="typeoperations"></v-select>
                    </p>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-2">
                  <div
                    class="mb-1 border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="status" class="bg-white text-gray-700 px-1">Type stock</label>
                      </p>
                    </div>
                    <p>
                      <v-select class="v-instock" v-model="ChangeQte.stock" :options="typeostocks"></v-select>
                    </p>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-2">
                  <div
                    class="mb-5 border hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="quantity" class="bg-white text-gray-700 px-1">{{ $t("quantity") }}</label>
                      </p>
                    </div>
                    <p>
                      <input id="quantity" type="number" min="0" v-model="ChangeQte.quantity"
                        class="py-2 text-xs px-2 outline-none block h-full w-full" />
                    </p>
                  </div>
                </div>
                <!-- <div v-if="checkQte" class="w-full mx-4 px-4 py-3 px-5 mb-4 bg-red-100 text-red-900 text-sm rounded-md border border-red-200 flex items-center" role="alert">
                            <div class="w-4 mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"></path>
                                </svg>
                            </div>
                         <span><strong>{{$t('quantity_instock_insufficient')}}</strong></span>
                        </div>  -->
              </div>
              <section class="warehouse" v-if="expeditionData.warhouseParent.exist">
                <div class="flex mt-10 mb-5 items-center text-center">
                  <hr class="border-gray-300 border-1 w-full rounded-md" />
                  <label class="block font-medium text-sm cursor-pointer text-green-500 w-full"><span
                      class="font-medium text-base text-green-800 dark:text-green-200">Choisir warehouse</span>
                  </label>
                  <hr class="border-gray-300 border-1 w-full rounded-md" />
                </div>
                <div class="bg-ww">
                  <h2 class="text-sm font-bold truncate mb-5">
                    Définir le warehouse pour ce produit
                  </h2>
                  <div class="w-full">
                    <div v-for="it in checkWarehousingParent" :key="it._id" :id="it._id" @click="activateWarehouse(it)"
                      :class="{ active: it.fullOf }"
                      class="border border-gray-600 intro-y flex-1 box-1 cursor-pointer zoom-in p-2 mb-2 pl-10">
                      <div class="check-left">
                        <span class="feather-icon select-none relative"><svg
                            :class="it.fullOf ? 'bg-green-600' : 'bg-gray-500'" xmlns="http://www.w3.org/2000/svg"
                            width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-check p-1 w-5 h-5 text-white rounded-full shadow">
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg></span>
                      </div>
                      <div class="text-sm font-bold text-left">
                        {{ it.name }}
                        <span v-if="it.is_primary">(Principal)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <!--services -->
              <section v-if="expeditionData.setting.exist">
                <div class="flex mt-10 mb-5 items-center text-center">
                  <hr class="border-gray-300 border-1 w-full rounded-md" />
                  <label class="block font-medium text-sm cursor-pointer text-green-500 w-full"><span
                      class="font-medium text-base text-green-800 dark:text-green-200">Choisir les services</span>
                  </label>
                  <hr class="border-gray-300 border-1 w-full rounded-md" />
                </div>
                <div class="bg-ww">
                  <h2 class="text-sm font-bold truncate mb-5">
                    Définir les services pour le nouveau warehouse de ce seller
                  </h2>
                  <div class="flex flex-col lg:flex-row">
                    <div id="confirmation" @click="activate('confirmation')"
                      :class="{ active: expeditionData.setting.confirmation }"
                      class="border border-gray-600 intro-y flex-1 box-1 py-10 cursor-pointer zoom-in">
                      <div class="check">
                        <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg"
                            width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow">
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg></span>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                        <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 0 1-8 0"></path>
                      </svg>
                      <div class="text-xl font-medium text-center mt-10">
                        Confirmation
                      </div>
                    </div>
                    <div id="fulfillement" @click="activate('fulfillement')"
                      :class="{ active: expeditionData.setting.fulfillement }"
                      class="border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                      <div class="check">
                        <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg"
                            width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow">
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg></span>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                        <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 0 1-8 0"></path>
                      </svg>
                      <div class="text-xl font-medium text-center mt-10">
                        Warehouse
                      </div>
                      <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                          <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                    </div>
                    <div id="shipping" @click="activate('shipping')"
                      :class="{ active: expeditionData.setting.shipping }"
                      class="border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in">
                      <div class="check">
                        <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg"
                            width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow">
                            <polyline points="20 6 9 17 4 12"></polyline>
                          </svg></span>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                        <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <path d="M16 10a4 4 0 0 1-8 0"></path>
                      </svg>
                      <div class="text-xl font-medium text-center mt-10">
                        Shipping
                      </div>
                      <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                          <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="p-4 flex space-x-4">
            <button @click="OpenClosePopupModal('close')"
              class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm">
              {{ $t("cancel") }}
            </button>
            <button @click="confirmChangeQte"
              class="w-1/2 px-4 py-3 text-center text-green-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative"
              :class="ChangeQte.loading ? 'noselect' : ''">
              <loading :active.sync="ChangeQte.loading" :is-full-page="fullPage"></loading>{{ $t("confirm") }}
            </button>
          </div>
        </div>
      </vue-modal-2>
      <!-- end modal change status shipping -->
      <!-- data Escel -->
      <dialog class="printingExcel" v-if="config.name == 'orders'">
        <modal-export-excel-orders :items="data" />
      </dialog>
      <dialog class="printingExcel" v-if="config.name == 'shippings'">
        <modal-export-excel-shippings :items="data" />
      </dialog>
      <!-- / end dataExcel -->

      <!--Modal set service seller Shipping -->
      <vue-modal-2 v-if="config.name == 'expeditions'" name="ModalChangeSetSettingsSeller" :headerOptions="{
        title: 'Définir les services pour le nouveau warehouse de ce seller',
      }" :noFooter="true" modalSize="lg" @on-close="$vm2.close('ModalChangeSetSettingsSeller')">
        <loading :active.sync="expeditionData.loading" :is-full-page="fullPage"></loading>
        <div class="bg-white rounded-lg">
          <div class="relative">
            <div class="rounded-lg flex">
              <div class="w-full p-4">
                <div
                  class="change-status border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                    <p>
                      <label for="settings" class="bg-white text-gray-700 px-1">Country (warhousing)</label>
                    </p>
                  </div>
                  <p>
                    <input v-if="expeditionData.country" type="text" disabled v-model="expeditionData.country"
                      class="py-2 text-xs px-2 outline-none block h-full w-full" />
                  </p>
                </div>
                <!--services -->
                <div class="flex mt-10 mb-5 items-center text-center">
                  <hr class="border-gray-300 border-1 w-full rounded-md" />
                  <label class="block font-medium text-sm cursor-pointer text-green-500 w-full"><span
                      class="font-medium text-base text-green-800 dark:text-green-200">Choisir les services</span>
                  </label>
                  <hr class="border-gray-300 border-1 w-full rounded-md" />
                </div>

                <div class="flex flex-col lg:flex-row">
                  <div id="confirmation" @click="activate('confirmation')"
                    :class="{ active: expeditionData.setting.confirmation }"
                    class="border intro-y flex-1 box-1 py-10 cursor-pointer zoom-in">
                    <div class="check">
                      <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow">
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg></span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <div class="text-xl font-medium text-center mt-10">
                      Confirmation
                    </div>
                  </div>
                  <div id="fulfillement" @click="activate('fulfillement')"
                    :class="{ active: expeditionData.setting.fulfillement }"
                    class="border intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                    <div class="check">
                      <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow">
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg></span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <div class="text-xl font-medium text-center mt-10">
                      Warehouse
                    </div>
                    <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                    <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                  </div>
                  <div id="shipping" @click="activate('shipping')" :class="{ active: expeditionData.setting.shipping }"
                    class="border intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in">
                    <div class="check">
                      <span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg"
                          width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                          class="feather feather-check w-5 h-5 text-white bg-green-600 rounded-full shadow">
                          <polyline points="20 6 9 17 4 12"></polyline>
                        </svg></span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto">
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <div class="text-xl font-medium text-center mt-10">
                      Shipping
                    </div>
                    <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                    <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4 flex space-x-4">
              <a href="#" @click="$vm2.close('ModalChangeSetSettingsSeller')"
                class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm">Cancel</a>
              <a href="#" @click="confirmChangeExpedition"
                class="w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm">Confirme</a>
            </div>
          </div>
        </div>
      </vue-modal-2>

      <vue-modal-2 name="messages" :headerOptions="{ title: 'Info messages' }" :noFooter="true" modalSize="lg"
        @on-close="closePopup('messages')">
        <div class="bg-white rounded-lg p-2">
          <div v-if="!messagesInfo.previous" class="rounded-lg">
            <span v-if="messagesInfo.messages.length > 0">
              <p v-for="(msg, ll) in messagesInfo.messages" :key="ll" class="Note mx-2">
                <strong>Note</strong>: {{ msg }}
              </p>
            </span>
            <div v-if="messagesInfo.info.length > 0" class="overflow-x-auto w-full px-4">
              <table class="table w-full table-messages">
                <thead>
                  <tr class="bg-gray-700 dark:bg-dark-1 text-white text-left">
                    <th class="whitespace-nowrap p-2">Product</th>
                    <th class="whitespace-nowrap p-2">
                      Quantity
                      {{ messagesInfo.status == "paid" ? "Total" : "in Stock" }}
                    </th>
                    <th class="whitespace-nowrap p-2">NB orders</th>
                    <th class="whitespace-nowrap p-2">NB quantity orders</th>
                    <th class="whitespace-nowrap p-2">Info</th>
                    <th class="whitespace-nowrap p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in messagesInfo.info" :key="index" :class="'bg-' + item.type">
                    <td class="border-b dark:border-dark-5 p-2">
                      {{ item.productName }}
                    </td>
                    <td class="border-b dark:border-dark-5 p-2">
                      {{ item.inStock }}
                    </td>
                    <td class="border-b dark:border-dark-5 p-2">
                      {{ item.count }}
                    </td>
                    <td class="border-b dark:border-dark-5 p-2">
                      {{ item.qte }}
                    </td>
                    <td class="border-b p-2 dark:border-dark-5" :class="item.type">
                      {{ item.text }}
                    </td>
                    <td class="border-b p-2 dark:border-dark-5" :class="item.type">
                      {{ item.type }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else style="overflow-x: auto">
            <table v-for="(msg, ll) in messagesInfo.messages" :key="ll" class="min-w-full w-full">
              <caption style="
                  background-color: #20827b;
                  color: #fff;
                  margin-bottom: 10px;
                  text-transform: uppercase;
                ">
                {{
                  msg.module
                }}
              </caption>
              <thead class="relative">
                <tr v-if="msg.module == 'shippings'" class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Order id </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Traking number </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Old Status </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> status </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Message </span>
                  </th>
                  <th
                    class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Action </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> View </span>
                  </th>
                </tr>
                <tr v-if="msg.module == 'payments'" class="bg-gray-400 text-gray-600 uppercase text-sm leading-normal">
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Payment id </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Old data </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> New data </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Message </span>
                  </th>
                  <th
                    class="px-3 text-center py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> Action </span>
                  </th>
                  <th
                    class="px-3 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                    <span> View </span>
                  </th>
                </tr>
              </thead>
              <loading :active.sync="loadingdatashipp" :is-full-page="fullPage"></loading>
              <tbody v-if="msg.module == 'shippings'">
                <tr v-for="(item, index) in msg.datas" :key="index" class="border-b border-gray-200 hover:bg-gray-100">
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      {{ item.orderId }}
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      {{ item.trackingNumber }}
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      <button
                        class="border capitalize rounded-full text-gray-600 text-xs px-2 bg-white hover:border-gray-500 focus:outline-none appearance-none"
                        :style="$f.ColorsStatusShippings(item.oldStatus) +
                          ';font-size: 10px;line-height: 15px;'
                          ">
                        {{ item.oldStatus }}
                      </button>
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      <button
                        class="border capitalize rounded-full text-gray-600 text-xs px-2 bg-white hover:border-gray-500 focus:outline-none appearance-none"
                        :style="$f.ColorsStatusShippings(item.status) +
                          ';font-size: 10px;line-height: 15px;'
                          ">
                        {{ item.status }}
                      </button>
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div style="font-size: 10px" class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      {{ item.text }}
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900" :class="item.type">
                      {{ item.type }}
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      <router-link target="_blank" :to="{
                        path: `/shippings?name=${item.trackingNumber}&country=${idWharhouse.country}`,
                      }"><i class="material-icons">
                          remove_red_eye</i></router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="msg.module == 'payments'">
                <tr v-for="(item, index) in msg.datas" :key="index" class="border-b border-gray-200 hover:bg-gray-100">
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      {{ item.id }}
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      {{ item.oldCount }} orders
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      {{ item.newCount }} orders
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div style="font-size: 10px" class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      {{ item.text }}
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div class="text-xs leading-5 capitalize rounded-full text-gray-900" :class="item.type">
                      {{ item.type }}
                    </div>
                  </td>
                  <td class="px-3 py-1 whitespace-no-wrap border-b border-gray-300">
                    <div v-if="!item.isDeleted" class="text-xs leading-5 capitalize rounded-full text-gray-900">
                      <router-link target="_blank" :to="{ path: `/payments/detail/${item._id}` }"><i
                          class="material-icons">
                          remove_red_eye</i></router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="px-4 py-2 flex float-right space-x-4">
            <a href="#" @click="closePopup('messages')"
              class="px-10 py-2 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm">OK</a>
          </div>
        </div>
      </vue-modal-2>

      <!--Update qte order-->
      <vue-modal-2 v-if="config.name == 'shippings' && orderToUpdate.orderObjet" name="updateorders" :headerOptions="{
        title: `Update details order (id: ${orderToUpdate.orderObjet.id})`,
      }" :noFooter="true" modalSize="lg" @on-close="closePopup('updateorders')">
        <div class="bg-white rounded-lg">
          <div class="rounded-lg flex">
            <div class="w-1/4 flex justify-center items-center">
              <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons"
                  style="font-size: 40px">update</i></span>
            </div>
            <div class="w-full pr-4 mt-4">
              <div v-for="(it, indx) in orderToUpdate.orderObjet.details" :key="indx"
                class="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4">
                <div class="grid grid-cols-1">
                  <div class="flex items-center ml-auto">
                    <div class="flex-shrink-0 w-10 h-10">
                      <img alt="..." :src="it.picture
                        ? it.picture
                        : 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
                        " class="h-10 w-10 rounded-md mx-auto" />
                    </div>
                    <div class="ml-3">{{ it.name }}</div>
                  </div>
                </div>
                <div class="grid grid-cols-1">
                  <div
                    class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="fullname" class="bg-white text-gray-700 px-1">{{ $t("unit_price") }}
                          <sup v-if="idWharhouse">({{ idWharhouse.currency }})</sup></label>
                      </p>
                    </div>
                    <p>
                      <input type="number" v-model="it.unitPrice"
                        class="py-2 text-xs px-2 outline-none block h-full w-full" />
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-1">
                  <div
                    class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                      <p>
                        <label for="Email" class="bg-white text-gray-700 px-1">{{ $t("quantity") }}</label>
                      </p>
                    </div>
                    <p>
                      <input type="number" v-model="it.quantity"
                        class="py-2 text-xs px-2 outline-none block h-full w-full" />
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex justify-end mt-4">
                <div class="text-danger flex items-center">
                  {{ $t("total_price") }}:
                  {{ TotalPrice(orderToUpdate.orderObjet) }}
                  <sup v-if="idWharhouse" class="uppercase">{{
                    idWharhouse.currency
                  }}</sup>
                </div>
              </div>
            </div>
          </div>
          <div class="p-4 flex space-x-4">
            <a href="#" @click="closePopup('updateorders')"
              class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400 font-bold rounded-lg text-sm">{{
                $t("cancel") }}</a>
            <a href="#" @click="UpdateOrder()"
              class="w-1/2 px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative"
              :class="loadingupdateorder ? 'noselect' : ''">
              <loading :active.sync="loadingupdateorder" :is-full-page="fullPage"></loading>{{ $t("confirme") }}
            </a>
          </div>
        </div>
      </vue-modal-2>
    </div>
  </div>
</template>

<!--  ************  -->
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import addFontNormal from "@/assets/fonts/WorkSans-normal";
import addFontBold from "@/assets/fonts/WorkSans-bold";
import imageScanner from "@/assets/scanner.png";
import { mixin as clickaway } from "vue-clickaway";
import firstdelivery from "@/assets/firstdelivery.png";
import camex from "@/assets/camex.png";
import { dispatchShipment } from '../services/shipments.service';

export default {
  mixins: [clickaway],
  props: {
    config: { type: Object },
    data: { default: [] },
    element: { type: Object },
    loading: { required: false },
    CheckQteDefecteuseexist: { required: false },
    CheckQteReturnedexist: { required: false },
    CheckQteDefecteuseorderexist: { required: false },
    CheckExpeditionRecived: { required: false },
    name: { type: String, default: "" },
    emailCurrentCall: { type: String, default: "" },
    paginate: { type: Object },
    statusOrder: { type: Array },
    zones: { type: Array },
    couriers: { type: Array },
    limit: { type: Number },
    statusExpedition: { type: Array },
    statusShipp: { type: Array },
    statusShippChange: { type: Array },
    tabId: { type: Array },
    typesShipp: { type: Array },
    transports: { type: Array },
    isFilterCourier: { required: false },
    CourierInfo: { type: Object },
    selectProduct: { type: Object },
    producWarhouse: { type: Object },
    idWharhouse: { type: Object },
    currentUser: { type: Object },
  },
  data() {
    return {
      shippingCompanies: [
        'CAMEX',
        'FIRSTDELIVERY'
      ],
      imageScannerShipp: imageScanner,
      showcourier: false,
      open: false,
      change_status: {
        statusTochange: "",
        courier: null,
      },
      shipping: {
        address: "",
        type: "normal",
        date: "",
        status: "to prepare",
        order: { type: Object },
        zone: { type: Object },
        courier: { type: Object },
      },
      camexImage: camex,
      FirstDeliveryImage: firstdelivery,
      shippingChange: {
        type: "pendingchange",
        date: "",
        status: "to prepare",
        order: { type: Object },
        zone: { type: Object },
        courier: { type: Object },
      },
      fullPage: false,
      loadingdatashipp: false,
      filters: [],
      actions: [],
      newSeller: {},
      roles: [],
      categories: [],
      // currentUser: {},
      courier: [],
      checkQte: false,
      loadingChange: false,
      custumerTest: "Moukhtar ouakat",
      ShippCode: "Moukhtar ouakat",
      ChangeQte: {
        operation: "Addition",
        stock: "inStock-total",
        quantity: 0,
        loading: false,
        warehouse: null,
        seller: null,
        warhouseParent: null,
        settings: null,
      },
      typeoperations: ["Addition", "Subtraction"],
      typeostocks: ["inStock", "total", "inStock-total", "defective"],
      dataCountry: this.$countries,
      openLimit: false,
      openAnalytics: false,
      warehouses: [],
      currency: "",
      //  idWharhouse:null,
      limitdata: 0,
      filters: {},
      feesShipp: null,
      loadingcreateShipp: false,
      postponed: {
        _id: null,
        date: null,
        comment: undefined,
        index: null,
        status: undefined,
      },
      expeditionData: {
        model: null,
        loading: false,
        data: {},
        country: null,
        warhouseParent: { exist: false, warehouse: null },
        setting: {
          exist: false,
          confirmation: false,
          fulfillement: false,
          shipping: false,
          warehouse: null,
        },
      },
      checkSetting: null,
      checkWarehousingParent: [],
      cities: [],
      loadingpdf: false,
      messagesInfo: { messages: [], info: [], status: "status" },
      searchTextShipp: "",
      orderToUpdate: { details: [] },
      loadingupdateorder: false,
      newShipp: {
        status: null,
        previous: false,
        shippings: [],
        tabId: [],
        loading: false,
      },
      statusPrevious: ["to prepare", "shipped"],
    };
  },
  async mounted() {
    // await this.getUser();
    //await this.getwharhouseId();
    // await this.getWarehouses();
    if (this.config.name == "orders") await this.getCities();
    this.filters = this.$f.QueryStringToobject();
    if (this.filters && this.filters["limit"])
      this.limitdata = this.filters["limit"];
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      //  await this.getwharhouseId();
      if (this.config.name == "orders") await this.getCities();
    },
  },
  methods: {
    checkChangeStatusPrevieus(status) {
      if (status && this.newShipp.previous) {
        let columns = document.querySelectorAll(".checkallbboxShipp");
        columns.forEach((column) => {
          column.checked = false;
        });
        this.newShipp.shippings = [];
        this.newShipp.tabId = [];
      }
    },
    async getFeesShippBycity(city) {
      this.feesShipp = null;
      const res = await this.$server.search("zones", { cities: city });
      if (res.content.results) this.feesShipp = res.content.results[0].price;
    },
    TotalPrice(data) {
      let Total = 0;
      for (const element of data.details) {
        Total += element.unitPrice * element.quantity;
      }
      data.totalPrice = Total;
      return Total;
    },
    async ChangeQuantityOrder(data) {
      if (data.orderObjet.details.length > 1) {
        alert(
          "tu peux pas modifier cette order car contient des produit different",
          "warning"
        );
        return false;
      }

      for (let i in data.orderObjet.details) {
        const editData = await this.$server.get("products", {
          id: data.orderObjet.details[i].product,
        });
        data.orderObjet.details[i].name = editData.content.name;
        data.orderObjet.details[i].picture = editData.content.picture;
      }
      this.orderToUpdate = data;
      this.openPopup("updateorders");
    },
    async UpdateOrder() {
      for (let i in this.orderToUpdate.orderObjet.details) {
        if (
          !parseInt(this.orderToUpdate.orderObjet.details[i].quantity) ||
          parseInt(this.orderToUpdate.orderObjet.details[i].quantity) == 0
        ) {
          alert("Quantité required !!!", "warning");
          return false;
        }
      }
      this.loadingupdateorder = true;

      this.orderToUpdate["UpdateQteOrder"] = true;
      const res = await this.$server.update("shippings", this.orderToUpdate);
      this.loadingupdateorder = false;

      if (res && res._id) {
        const index = this.data.findIndex(
          (shipp) => shipp._id.toString() === res._id.toString()
        );
        this.data[index].order.details[0].quantity =
          res.orderObjet.details[0].quantity;
        this.data[index].order.details[0].unitPrice =
          res.orderObjet.details[0].unitPrice;
        this.data[index].order.totalPrice = res.orderObjet.totalPrice;
        alert("Quantite order updated", "success");
        this.closePopup("updateorders");
      } else alert(res, "warning");
    },
    closePopup(name) {
      this.$vm2.close(name);
    },
    async openPopup(name) {
      this.$vm2.open(name);
    },
    async getCities() {
      const resCities = await this.$server.get("zones", {
        allCities: true,
        country: await this.warhouseSelected,
      });
      if (resCities.content.length > 0) this.cities = resCities.content;
      else this.cities = [];
    },
    activate: function (el) {
      this.expeditionData.setting[el] = !this.expeditionData.setting[el];
    },
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async getCurrency() {
      await this.$store.dispatch("wharhouse/getCurrency").then((res) => {
        this.currency = res;
      });
    },
    async getWarehouses() {
      const filter = { limit: 1000 };
      const res = await this.$server.search("warehouses", filter);
      if (res.content.results) {
        this.warehouses = res.content.results;
      } else this.warehouses = [];
    },
    setSelectedWharhouse(warehouse) {
      if (warehouse) {
        this.ChangeQte.warehouse = warehouse;
      }
    },
    away() {
      this.open = false;
    },
    awayLimit() {
      this.openLimit = false;
    },
    exceptText(text, name = null) {
      let lent = 40;
      if (name) lent = 20;
      if (text && text.length > lent) text = text.substring(0, lent) + "...";
      return text;
    },
    serchsellerFilter() {
      this.debounce(function () {
        //
      }, 500);
    },
    debounce(func, wait, immediate) {
      var timeout;
      return function () {
        var context = this,
          args = arguments;
        var later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    getElementInArray(data, value) {
      var result = data.filter(function (o) {
        let warhouse = o.warehouse;
        if (o.warehouse._id) warhouse = o.warehouse._id;

        return warhouse == value.toString();
      });
      return result ? result[0] : null; // or undefined
    },
    changedQte(event) {
      this.checkQte = false;
      if (
        this.ChangeQte.operation == "Subtraction" &&
        parseInt(ExistWarhouse.quantity.inStock) <
        parseInt(this.ChangeQte.quantity)
      ) {
        this.checkQte = true;
      }
      if (
        this.ChangeQte.operation == "Subtraction" &&
        parseInt(ExistWarhouse.quantity.total) <
        parseInt(this.ChangeQte.quantity)
      ) {
        this.checkQte = true;
      }
      if (
        this.ChangeQte.operation == "Subtraction" &&
        parseInt(ExistWarhouse.quantity.defective) <
        parseInt(this.ChangeQte.quantity)
      ) {
        this.checkQte = true;
      }
    },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    isEmpty(value) {
      if (value == null || value == "" || value == " ") return true;
      else return false;
    },
    async getCurrentCourierZone() {
      if (this.shipping._id) {
        const filter = {
          type: "Courier",
          zones: this.shipping.zone._id,
        };
        const res = await this.$server.search("users", filter);
        if (res.content.results) {
          this.courier = res.content.results;
        } else this.courier = [];
      }
    },
    async CheckDataShip(item) {
      if (item._id) {
        const editData = await this.$server.get("shippings", {
          order: item._id,
        });
        if (editData.content._id) {
          this.shipping = editData.content;
        } else {
          const nDate = new Date().toLocaleString("en-US", {
            timeZone: "Africa/Casablanca",
          });
          let newdate = new Date(nDate);
          this.shipping = {};
          this.shipping.order = item._id;
          this.shipping.seller = item.seller;
          this.shipping.status = "to prepare";
        }
      }
    },
    async getCourierZone(event) {
      this.courier = [];
      const filter = {
        type: "Courier",
        zones: event.target.options[event.target.options.selectedIndex].id,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) {
        this.courier = res.content.results;
      } else this.courier = [];
    },
    formattedDate(date, formatdate = null) {
      let format = "yyyy-MM-DD HH:mm";
      if (formatdate) format = formatdate;
      if (this.config.name == "shippings") format = "yyyy-MM-DD";
      var date = this.$moment(date).format(format);
      if (date == "Invalid date") return null;
      else return date;
    },
    async emitFunction(action, id, data) {
      if (action === "delete") {
        if (this.config.name == "products") {
          const resExp = await this.$server.search("expeditions", {
            "details.product": id,
          });
          const resOrd = await this.$server.search("orders", {
            "details.product": id,
          });
          if (resExp.content.results || resOrd.content.results)
            alert(this.$t("product_already_used"), "warning");
          else this.remove(id);
        } else {
          this.remove(id);
        }
      }
      if (action === "print") {
        this.generatePdf(id, data);
      }
    },
    paginatation: function (val) {
      this.$emit("paginatation", val);
    },
    refresh: function () {
      this.$emit("refresh");
    },
    reset: function (val) {
      this.$emit("reset", val);
    },
    filtrer: function (val) {
      this.$emit("filtrer", val);
    },
    search: function (val) {
      this.$emit("search", val);
    },
    showSousTable: function (val) {
      this.$emit("showProducts", val);
    },
    CheckQteDefecteuse: function (val) {
      this.$emit("CheckQteDefecteuse", val);
    },
    CheckQteReturned: function (val) {
      this.$emit("CheckQteReturned", val);
    },
    CheckQteDefecteuseOrder: function (val) {
      this.$emit("CheckQteDefecteuseOrder", val);
    },
    CheckQteSentExist: function (val) {
      this.$emit("CheckQteSentExist", val);
    },
    generateListPdf: function () {
      this.$emit("generateListPdf");
    },
    downloadAnalytics: function () {
      this.$emit("downloadAnalytics");
    },
    downloadCsv: function () {
      if (this.config.name == "orders") this.$emit("downloadOrders");
      if (this.config.name == "shippings") this.$emit("downloadShippings");
    },
    downloadExcel: function () {
      var nameTable = "shippings";
      if (this.config.name == "orders") nameTable = "orders";
      var htmls = "";
      var uri = "data:application/vnd.ms-excel;base64,";
      var template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
      var base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      };

      var format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
      const table = document.getElementById("p-table");
      htmls = table.outerHTML;
      var ctx = {
        worksheet: nameTable,
        table: htmls,
      };

      var link = document.createElement("a");
      link.download = nameTable + ".xls";
      link.href = uri + base64(format(template, ctx));
      link.click();
    },
    showPrConfirmOperationUpdateoducts: function (data, index) {
      this.$emit("ConfirmOperationUpdate", val);
    },
    selectAllCheckbox: function ($event) {
      this.$emit("selectAllCheckbox", $event);
    },
    getRowDetail: function (data, id) {
      this.$emit("getRowDetail", data, id);
    },
    emtyTabId: function () {
      this.$emit("emtyTabId");
    },
    searchSellers: function (value, event) {
      this.$emit("searchSellers", value, event);
    },
    searchCouriers: function (value, event) {
      this.$emit("searchCouriers", value, event);
    },
    Changelimit: function (val) {
      this.openLimit = false;
      this.$emit("Changelimit", val);
    },
    EmitMethodAction(action) {
      if (action == "addPay") this.$router.push("/payments/new");
      if (action == "printdInvoice") this.generatePdf();
      if (action == "print") this.generateListPdf();
      if (action == "changestatus") this.OpenClosePopupModal("open");
      if (action == "printdDelivry") this.generatePdf();
      if (action == "addPrd") this.$router.push("/products/new");
      if (action == "addqte") {
        this.OpenClosePopupModal("open");
      }
    },
    async ConfirmReporte(action) {
      if (!this.postponed.date) {
        alert(this.$t("postponed_date_required"), "warning");
        return false;
      }
      this.postponed.status = "remind";
      const res = await this.$server.update("shippings", this.postponed);
      if (res) {
        this.closePopUpInformation(this.postponed.index);
        this.postponed.date = null;
        this.postponed.comment = undefined;
        this.postponed.status = undefined;
        this.OpenClosePopupModalReporte("close", "postponed");
        alert(this.$t("shippings_updated"), "success");
      }
    },
    async confirmChange() {
      this.messagesInfo = { messages: [], info: [], status: "status" };
      if (this.change_status.statusTochange == "") {
        alert(this.$t("no_status_selected"), "warning");
      } else {
        if (
          !this.change_status.courier &&
          this.change_status.statusTochange == "shipped"
        ) {
          alert("Courier required !", "warning");
          return false;
        }
        if (
          this.config.name == "orders" &&
          this.change_status.statusTochange.name == "Pending"
        ) {
          if (
            this.tabId.filter(
              (obj) =>
                obj.status === "A transférer" ||
                obj.status === "Pending" ||
                obj.status === "Unreached"
            ).length != this.tabId.length
          ) {
            alert(
              "Please sélectionner seulement les ordres de status A transférer or Pending traits !",
              "warning"
            );
            return false;
          }
          //return false
        }
        this.$swal.fire({
          title: `You are sure to do this action, Total orders to ${this.change_status.statusTochange.name ||
            this.change_status.statusTochange
            } : ${this.tabId.length}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingChange = true;
            const data = {
              ids: this.tabId,
              status: this.change_status.statusTochange,
              courier: this.change_status.courier,
              warhouse: this.idWharhouse._id,
              country: this.idWharhouse.country,
            };
            if (
              this.change_status.courier &&
              this.shippingCompanies.includes(
                this.change_status.courier.fullName.toUpperCase(),
              ) &&
              this.change_status.statusTochange.toUpperCase() === 'TO PREPARE'
            ) {
              const dispatchResult = await dispatchShipment(data);

              console.log('dispatchResult.content---------------' , dispatchResult.content)

              if (dispatchResult && dispatchResult.content.success) {
                if (dispatchResult.content.success) {alert(dispatchResult.content.message, 'success');}
              } else {
                alert(dispatchResult.content.message || dispatchResult.content.exception, 'warning');
              }
              this.loadingChange = false;
              this.open = false;
              this.change_status.statusTochange = '';
              this.change_status.courier = null;
              this.showcourier = false;
              this.emtyTabId();
              this.OpenClosePopupModal('close');
              await this.refresh();
            } else {
              const res = await this.$server.updateMany('shippings', data);
              this.loadingChange = false;

              if (res && !res.error) {
                this.open = false;
                this.change_status.statusTochange = '';
                this.change_status.courier = null;
                this.showcourier = false;
                this.emtyTabId();
                this.OpenClosePopupModal('close');
                await this.refresh();
                if (res.length > 0) {
                  if (res[0].message) {
                    this.$vm2.open('messages');
                    this.messagesInfo = res;
                  } else alert(res, 'warning');
                } else alert(this.$t('shippings_updated'), 'success');
              } else alert(res.error ? res.error.message : res, 'warning');
            }
          }
        });
      }
    },

    async confirmChangeQte() {
      this.ChangeQte.warehouse = this.producWarhouse.warehouse;
      this.ChangeQte.seller = this.selectProduct.seller;
      const data = { _id: this.tabId[0], actionInstock: this.ChangeQte };
      if (!this.ChangeQte.warehouse) {
        alert("Warehouse not detected (svp actualiser la page)", "warning");
        return false;
      }
      if (
        this.expeditionData.setting.exist &&
        !this.expeditionData.setting.confirmation &&
        !this.expeditionData.setting.fulfillement &&
        !this.expeditionData.setting.shipping
      ) {
        alert("Les services de cette seller est obligatoir", "warning");
        return false;
      }
      if (
        this.expeditionData.warhouseParent.exist &&
        !this.expeditionData.warhouseParent.warehouse
      ) {
        alert("Warehouse est obligatoir", "warning");
        return false;
      }
      this.ChangeQte.loading = true;
      if (this.expeditionData.setting.exist) {
        this.ChangeQte.settings = this.expeditionData.setting;
        this.ChangeQte.settings.warehouse = this.ChangeQte.warehouse._id;
      }
      if (this.expeditionData.warhouseParent.exist)
        this.ChangeQte.warhouseParent =
          this.expeditionData.warhouseParent.warehouse;

      //return false

      // if(parseInt(this.ChangeQte.quantity)==0){
      //     alert(this.$t('quantity_greater_zero'), "warning");
      //     return false;
      // }

      const res = await this.$server.update("products", data);
      this.ChangeQte.loading = false;
      if (res) {
        //this.loading = false;
        this.checkSetting = null;
        (this.expeditionData.setting = {
          exist: false,
          confirmation: false,
          fulfillement: false,
          shipping: false,
          warehouse: null,
        }),
          (this.expeditionData.warhouseParent = {
            exist: false,
            warehouse: null,
          }),
          (this.ChangeQte = {
            operation: "Addition",
            stock: "inStock-total",
            quantity: 0,
            loading: false,
            warehouse: null,
            seller: null,
            warhouseParent: null,
            settings: null,
          });
        this.emtyTabId();
        this.OpenClosePopupModal("close");
        await this.refresh();
        alert(this.$t("product_updated"), "success");
      }
    },
    getRowDetailShipp(item, id) {
      let itemInRow = this.tabId.filter((item) => item.id === id);
      let isItemInRow = itemInRow.length > 0;

      if (isItemInRow) {
        let pos = this.newShipp.tabId
          .map(function (e) {
            return e.id;
          })
          .indexOf(id);
        this.newShipp.tabId.splice(pos, 1);
      } else {
        this.newShipp.tabId.push({
          id: id,
          source: item.seller.source ? item.seller.source : "nosource",
          order: item.order._id,
          trackingNumber: item.trackingNumber,
          is_paid: item.is_paid,
          status: item.status,
          orderobjet: JSON.stringify(item.orderObjet),
        });
      }
      if (this.newShipp.tabId.length === 0) {
        let columns = document.querySelectorAll(".checkallbboxShipp");
        columns.forEach((column) => {
          column.checked = false;
        });
      }
    },
    selectAllCheckboxShipp($event) {
      let columns = document.querySelectorAll(".rowCheckboxShipp");
      this.newShipp.tabId = [];
      if ($event.target.checked == true) {
        columns.forEach((column) => {
          column.checked = true;
          this.newShipp.tabId.push({
            id: column.id,
            source: column.getAttribute("seller"),
            order: column.getAttribute("order"),
            status: column.getAttribute("status"),
            trackingNumber: column.getAttribute("trackingNumber"),
            is_paid: column.getAttribute("is_paid"),
            orderobjet: column.getAttribute("orderobjet"),
          });
        });
      } else {
        columns.forEach((column) => {
          column.checked = false;
        });
        this.newShipp.tabId = [];
      }
    },
    searchShippByTrack: _.debounce(async function (e) {
      if (typeof this.searchTextShipp !== "undefined" && this.searchTextShipp) {
        const filters = {
          limit: 500,
          trackingNumber: this.searchTextShipp.trim().toUpperCase(),
        };
        let index = this.newShipp.shippings.findIndex(
          (element) => element.trackingNumber === this.searchTextShipp
        );

        if (index == -1) {
          this.newShipp.loading = true;
          const res = await this.$server.search("shippings", filters);
          this.newShipp.loading = false;
          if (res.content.results) {
            if (this.newShipp.status && this.newShipp.previous) {
              //check is same status
              if (res.content.results[0].status == this.newShipp.status)
                alert(
                  `Please select other shipping nin ${this.newShipp.status}`,
                  "warning"
                );
              else {
                if (
                  (this.newShipp.status == "delivered" &&
                    [
                      "processed",
                      "paid",
                      "cancelled",
                      "refused",
                      "return",
                      "outofstock",
                    ].indexOf(res.content.results[0].status) == -1) ||
                  (this.newShipp.status == "shipped" &&
                    [
                      "processed",
                      "paid",
                      "delivered",
                      "cancelled",
                      "refused",
                      "return",
                      "outofstock",
                    ].indexOf(res.content.results[0].status) == -1) ||
                  (this.newShipp.status == "prepared" &&
                    [
                      "processed",
                      "paid",
                      "delivered",
                      "shipped",
                      "cancelled",
                      "refused",
                      "return",
                      "outofstock",
                    ].indexOf(res.content.results[0].status) == -1) ||
                  (this.newShipp.status == "to prepare" &&
                    [
                      "processed",
                      "paid",
                      "delivered",
                      "shipped",
                      "prepared",
                      "cancelled",
                      "refused",
                      "return",
                      "outofstock",
                    ].indexOf(res.content.results[0].status) == -1) ||
                  (this.newShipp.status == "paid" &&
                    ["processed"].indexOf(res.content.results[0].status) == -1)
                )
                  alert(`Only to previous status accepted !`, "warning");
                else {
                  this.newShipp.shippings.push(res.content.results[0]);
                  this.searchTextShipp = "";
                }
              }
            } else {
              this.newShipp.shippings.push(res.content.results[0]);
              this.searchTextShipp = "";
            }
          } else alert("No Shipping found", "warning");
        } else {
          this.searchTextShipp = "";
        }
      }
    }, 500),
    async EmitActionNewShipp() {
      this.messagesInfo = { messages: [], info: [], status: "status" };
      if (this.newShipp.tabId.length > 0) {
        this.$swal.fire({
          title: `You are sure to do this action, Total orders to ${this.newShipp.status} : ${this.newShipp.tabId.length}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingdatashipp = true;
            let data = {
              ids: this.newShipp.tabId,
              status: this.newShipp.status,
              warhouse: this.idWharhouse._id,
            };
            if (this.newShipp.previous) data.previous = true;

            const res = await this.$server.updateMany("shippings", data);
            this.loadingdatashipp = false;

            if (res) {
              let columns = document.querySelectorAll(".checkallbboxShipp");
              if (res.messages && res.messagesStock) {
                if (res.messages.length == 0 && res.messagesStock.length == 0) {
                  alert("Shippings updated", "success");
                  this.$vm2.close("ModalChangeStatusByDauchette");
                  this.newShipp.shippings = [];
                  this.newShipp.tabId = [];
                  this.newShipp.status = null;
                  this.newShipp.previous = false;
                  await this.refresh();
                  columns.forEach((column) => {
                    column.checked = false;
                  });
                } else {
                  this.$vm2.close("ModalChangeStatusByDauchette");
                  this.$vm2.open("messages");
                  this.messagesInfo.messages = res.messages;
                  this.messagesInfo.info = res.messagesStock;
                  this.messagesInfo.status = res.status;
                  if (res.previous) {
                    this.messagesInfo.previous = true;
                    this.newShipp.shippings = [];
                    this.newShipp.tabId = [];
                    this.newShipp.status = null;
                    this.newShipp.previous = false;
                    await this.refresh();
                    columns.forEach((column) => {
                      column.checked = false;
                    });
                  }
                }
              } else alert(res, "warning");
            } else alert(res, "warning");
          }
        });
      } else alert("No shippings selected !", "warning");
    },
    OpenClosePopupModal(action, method = "", previous = null) {
      let modal_id = "ModalChangeStatus";
      if (this.config.name == "products") modal_id = "ModalChangeQte";
      if (method === "dauchette") modal_id = "ModalChangeStatusByDauchette";
      if (action === "open") {
        if (method === "dauchette") {
          this.$vm2.open(modal_id);
          if (previous) this.newShipp.previous = true;
        } else {
          if (this.tabId.length > 0) {
            //  this.$refs[modal_id].showModal()
            this.$vm2.open(modal_id);
            if (
              this.config.name == "products" &&
              modal_id === "ModalChangeQte"
            ) {
              this.checkSetting = this.getElementInArray(
                this.selectProduct.seller.settings,
                this.idWharhouse._id
              );

              if (
                !this.checkSetting ||
                (this.checkSetting &&
                  !this.checkSetting.confirmation &&
                  !this.checkSetting.fulfillement &&
                  !this.checkSetting.shipping)
              ) {
                if (
                  !this.checkSetting ||
                  (this.checkSetting &&
                    !this.checkSetting.confirmation &&
                    !this.checkSetting.fulfillement &&
                    !this.checkSetting.shipping)
                )
                  this.expeditionData.setting.exist = true;
              }
            }
          } else alert(`No ${this.config.name} selected !`, "warning");
        }
      } else {
        let columns = document.querySelectorAll(".checkallbboxShipp");
        columns.forEach((column) => {
          column.checked = false;
        });
        this.newShipp.shippings = [];
        this.newShipp.tabId = [];
        this.newShipp.status = null;
        this.newShipp.previous = false;
        this.$vm2.close(modal_id);
      }
    },
    OpenClosePopupModalReporte(action, method, idShipp, index) {
      this.postponed._id = idShipp;
      this.postponed.index = index;
      if (method == "postponed") {
        let modal_id = "ModalReporteShipp";
        if (action === "open") {
          this.$vm2.open(modal_id);
        } else {
          this.$vm2.close(modal_id);
          this.postponed._id = null;
        }
      } else {
        //unrached shipping
        this.$swal.fire({
          title: this.$t("confirm_unreachable_shipping"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loadingcreateShipp = true;
            const nDate = new Date().toLocaleString("en-US", {
              timeZone: "Africa/Casablanca",
            });
            let newdate = new Date(nDate);
            this.postponed.date = newdate.setDate(newdate.getDate() + 1);
            this.postponed.status = "unreachable";
            const data_ship = await this.$server.update(
              "shippings",
              this.postponed
            );
            this.loadingcreateShipp = false;
            if (data_ship && data_ship._id) {
              this.postponed.date = null;
              this.postponed.comment = undefined;
              this.postponed.status = undefined;
              this.closePopUpInformation(this.postponed.index);
              alert(this.$t("shippings_updated"), "success");
            } else alert(data_ship, "warning");
          }
        }
        );
      }
    },
    async ShowPopUpInformation(index, status = null, data = null) {
      if (this.config.name == "statusHistories") return false;
      if (this.config.name == "shippings") {
        if (data.country == "MA")
          await this.getFeesShippBycity(data.order.customer.city);
        this.CheckQteDefecteuse(data);
        this.CheckQteReturned(data);
        if (
          this.currentUser.type == "Courier" &&
          (status == "delivered" ||
            status == "cancelled" ||
            status == "processed")
        ) {
          return false;
        }
        if (
          this.currentUser.type == "StockManager" &&
          (status == "delivered" ||
            status == "shipped" ||
            status == "processed")
        ) {
          return false;
        }
        if (this.currentUser.type == "Accountant" && data.type == "normal") {
          return false;
        }
        if (this.currentUser.type == "Seller") {
          return false;
        }
      }
      if (this.config.name == "expeditions") {
        if (
          this.currentUser.type != "Admin" &&
          this.currentUser.type != "CEO" &&
          this.currentUser.type != "Courier" &&
          this.currentUser.type != "StockManager"
        ) {
          if (this.currentUser.type == "Seller") return false;
          else {
            if (!this.getAccessUser("expeditions", "update")) return false;
          }
        }

        this.CheckQteSentExist(data);
      }
      if (this.config.name == "orders") {
        if (data.customer.country == "MA")
          await this.getFeesShippBycity(data.customer.city);
        if (
          this.currentUser.type != "CallcenterManager" &&
          this.currentUser.type != "Admin" &&
          this.currentUser.type != "CEO" &&
          (this.currentUser.type != "TeleConsultant") &
          (this.currentUser.type != "NRP") &&
          this.currentUser.type != "Accountant"
        ) {
          if (this.currentUser.type == "Seller") return false;
          else {
            if (!this.getAccessUser("orders", "update")) return false;
          }
        }
        this.CheckQteDefecteuseOrder(data);
        await this.CheckDataShip(data);
      }
      // await this.assign(index);
      let modal_id = "modal1_" + index;

      //  this.$refs[modal_id][0].showModal();
      this.$vm2.open(modal_id);
    },
    getAccessUser(modelToCheck, accessToCheck) {
      if (this.currentUser && this.currentUser.role) {
        const permissions = this.currentUser.role.permissions;
        for (let i in permissions) {
          let model = permissions[i]["model"];
          let access = permissions[i]["access"];

          if (model == modelToCheck) {
            return access[accessToCheck];
          }
        }
      } else return false;
    },
    async confirmChangeExpedition() {

      if (
        this.expeditionData.setting.exist &&
        !this.expeditionData.setting.confirmation &&
        !this.expeditionData.setting.fulfillement &&
        !this.expeditionData.setting.shipping
      ) {
        alert("Les services est obligatoir", "warning");
        return false;
      }
      this.expeditionData.loading = true;
      const data = await this.$server.update(
        "expeditions",
        this.expeditionData
      );
      this.expeditionData.loading = false;
      if (data && data._id) {
        (this.expeditionData.setting = {
          exist: false,
          confirmation: false,
          fulfillement: false,
          shipping: false,
          warehouse: null,
        }),
          this.$vm2.close("ModalChangeSetSettingsSeller");
        this.closePopUpInformation(this.expeditionData.model);
        alert("expeditions updated", "success");
      } else alert(data, "warning");
    },
    async ConfirmOperationUpdate(dataToEdit, index) {
      if (this.config.name == "shippings") {
        dataToEdit.order.onlyCommentedit = true;
        this.loadingcreateShipp = true;
        const dataorderedit = await this.$server.update(
          "orders",
          dataToEdit.order
        );
        delete dataToEdit.order.onlyCommentedit;
        if (dataToEdit.courier && this.shippingCompanies.includes(dataToEdit.courier.fullName.toUpperCase()) && dataToEdit.status.toUpperCase() === 'TO PREPARE') {
          const dispatchResult = await dispatchShipment(dataToEdit, true);

          console.log('dispatchResult', dispatchResult)
          if (dispatchResult && dispatchResult.content) {
            if (dispatchResult.content.success) {
              alert(dispatchResult.content.message, 'success');
              this.closePopUpInformation(index);
            } else {
              if (!dispatchResult.content.success) {
                console.log('dispatchResult.content------------------',dispatchResult.content)
                alert(
                  dispatchResult.content.message ||
                  dispatchResult.content.exception  , 'warning'
                );
              }
              this.closePopUpInformation(index);
            }
          } else {
            alert(
              dispatchResult.content.message ||
              dispatchResult.content.exception, 'warning'
            );
          }
        } else {
          const data = await this.$server.update(
            this.config.name,
            dataToEdit,
          );

          if (data && data._id) {
            this.closePopUpInformation(index);
            alert(this.config.title + ' updated', 'success');
          } else {
            alert( data.error ? data.error.message || data.error : data,'warning');
            if (data.token)
              dataToEdit.courier.apiShipping.token = data.token;
          }
        }
        this.loadingcreateShipp = false;
      }

      else if (
        this.config.name == "orders" &&
        dataToEdit.status.name &&
        dataToEdit.status.name == "Confirmed"
      ) {
        this.loadingcreateShipp = true;

        if (this.shipping._id) {
          const data_shi = await this.$server.update(
            "shippings",
            this.shipping
          );
          const data_order = await this.$server.update("orders", dataToEdit);
          this.loadingcreateShipp = false;
          if (data_shi && data_shi._id) {
            this.closePopUpInformation(index);
            alert(this.$t("shipping_order_updated"), "success");
          } else alert(data_shi, "warning");
        } else {
          if (this.checkDataShipping()) {
            this.shipping.seller = dataToEdit.seller._id;
            this.shipping.country = dataToEdit.customer.country;
            this.shipping.totalPrice = dataToEdit.totalPrice;
            const checkDataShipp = await this.$server.get("orders", {
              id: dataToEdit._id,
            });

            // if(checkDataShipp.content.teleConsultant && (checkDataShipp.content.status.name == "Pending" || checkDataShipp.content.status.name == "OutOfStock")){
            //       this.loadingcreateShipp=false;
            //       alert("Shipping is already being processed in the call center !!", "warning");
            //       return false;
            // }
            if (checkDataShipp.content.status.name == "Confirmed") {
              this.loadingcreateShipp = false;
              alert("Shipping already created for this order !!", "warning");
              await this.refresh();
              return false;
            }

            dataToEdit["shipping"] = this.shipping;
            dataToEdit.creatShipInAdmin = true;
            const data_order = await this.$server.update("orders", dataToEdit);

            this.loadingcreateShipp = false;
            if (data_order && data_order._id) {
              alert(this.$t("shipping_created"), "success");
              this.closePopUpInformation(index);
            } else alert(data_order, "warning");
          } else {
            alert(this.$t("all_required_field"), "warning");
          }
        }
        this.loadingcreateShipp = false;
      } else {
        if (
          this.config.name == "orders" &&
          dataToEdit.status.name &&
          dataToEdit.status.name == "Cancelled" &&
          !dataToEdit.comment
        ) {
          alert(`Raison d'annulation (Comment) requise !`, "warning");
          return false;
        }
        if (this.config.name == "expeditions") {
          this.loadingcreateShipp = true;
          //dataToEdit.seller=dataToEdit.seller;
          if (dataToEdit.arrivalDate == "Invalid date")
            dataToEdit.arrivalDate = null;

          let checkSetting = null;
          checkSetting = this.getElementInArray(
            dataToEdit.seller.settings,
            this.idWharhouse._id
          );

          if (
            dataToEdit.status == "received" &&
            (!checkSetting ||
              (checkSetting &&
                !checkSetting.confirmation &&
                !checkSetting.fulfillement &&
                !checkSetting.shipping))
          ) {
            this.expeditionData.setting.exist = true;

            this.expeditionData.country = this.$f.getCountryNameByCode(
              this.dataCountry,
              dataToEdit.country_to
            );
            this.expeditionData.data = dataToEdit;
            this.expeditionData.model = index;
            this.$vm2.open("ModalChangeSetSettingsSeller");
            this.loadingcreateShipp = false;
            return false;
          }
        }
        const data = await this.$server.update(this.config.name, dataToEdit);

        this.loadingcreateShipp = false;
        if (data && data._id) {
          this.closePopUpInformation(index);
          alert(this.config.title + " updated", "success");
        } else alert(data, "warning");
      }
    },
    async closePopUpInformation(index) {
      let modal_id = "modal1_" + index;
      this.$vm2.close(modal_id);
      await this.refresh();
    },
    async OrderChange(index, order) {
      let modal_id = "modal1_" + index;
      //  this.$refs[modal_id][0].close();
      this.$vm2.close(modal_id);
      const DataShipp = await this.$server.get("shippings", {
        order: order._id,
        type: "normal",
      });
      if (DataShipp.content._id) {
        //
        const nDate = new Date().toLocaleString("en-US", {
          timeZone: "Africa/Casablanca",
        });
        let newdate = new Date(nDate);
        this.shippingChange.address = DataShipp.content.address;
        this.shippingChange.order = order._id;
        this.shippingChange.totalPrice = order.totalPrice;
        this.shippingChange.courier = DataShipp.content.courier._id;
        this.shippingChange.zone = DataShipp.content.zone._id;
        this.shippingChange.seller = DataShipp.content.seller;
        this.shippingChange.date = newdate;
        this.shippingChange.country = order.customer.country;
        this.$swal.fire({
          title: this.$t("confirm_craete_shipping_change"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const data_ship = await this.$server.create(
              "shippings",
              this.shippingChange
            );
            if (data_ship._id) {
              this.$router.push("/shippings");
              alert(this.$t("shipping_created"), "success");
            }
          }
        }
        );
      } else {
        alert(this.$t("no_shipping"), "error");
      }
    },
    async getCategories() {
      const res = await this.$server.search("categories");
      if (res.content) this.categories = res.content.results;
      else this.categories = [];
    },
    async getRoles() {
      const res = await this.$server.search("roles");
      if (res.content) this.roles = res.content.results;
      else this.roles = [];
    },
    async remove(id) {
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await this.$server.delete(this.config.name, { _id: id });

          if (data && this.config.name == "orders") {
            // await this.$server.deleteMany('statusHistories', { order: id });
            //const res= await this.$server.delete('shippings', { order: id });
            //  if(res) await this.$server.deleteMany('shippingsHistories', { shipping: res._id });
          }
          if (data && this.config.name == "shippings") {
            // await this.$server.deleteMany('shippingsHistories', { shipping: id });
            //await this.$server.delete('orders', { _id: data.order });
            //await this.$server.deleteMany('statusHistories', { order: data.order });
          }
          if (data && data._id) {
            alert(`${this.name} deleted !`, "success");
            await this.refreshChild();
          } else alert(data, "warning");
        }
      });
    },
    async refreshChild() {
      await this.refresh();
    },
    getAccessLinks(action) {
      const modelToCheck = this.config.name;
      const user = this.currentUser.type;
      if (modelToCheck == "shippings" && user == "Seller") return false;
      if (modelToCheck == "products" && user == "Seller" && action == "addqte")
        return false;
      if (modelToCheck == "payments" && user == "Seller" && action == "addPay")
        return false;
      if (modelToCheck == "orders" && user == "Seller") return false;
      return true;
    },
    getAccess(
      accessToCheck,
      status = null,
      type = null,
      role = null,
      orderTraits
    ) {
      if (!this.currentUser.role) return false;

      const modelToCheck = this.config.name;
      const permissions = this.currentUser.role.permissions;
      // let productExist=false;
      if (!role) {
        return false;
      }

      if (
        (modelToCheck == "shippings" || modelToCheck == "payments") &&
        accessToCheck == "print"
      )
        return true;

      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];
        if (model == modelToCheck) {
          if (
            modelToCheck == "expeditions" &&
            (accessToCheck == "update" || accessToCheck == "delete") &&
            (status == "received" || status == "cancelled")
          ) {
            if (
              this.currentUser.type == "Admin" ||
              this.currentUser.type == "CEO"
            ) {
              return true;
            } else {
              return false;
            }
          }
          if (
            modelToCheck == "shippings" &&
            type !== null &&
            this.currentUser.type == "Accountant" &&
            accessToCheck == "update" &&
            type == "normal"
          ) {
            return false;
          }
          if (
            modelToCheck == "shippings" &&
            status !== null &&
            this.currentUser.type == "Accountant" &&
            accessToCheck == "update" &&
            (status.name == "Processed" ||
              status.name == "OutOfStock" ||
              status.name == "Cancelled" ||
              status.name == "Skipped" ||
              status.name == "Pending" ||
              status.name == "Unreached")
          ) {
            return false;
          }
          if (
            modelToCheck == "orders" &&
            status !== null &&
            this.currentUser.type == "Seller" &&
            (accessToCheck == "update" || accessToCheck == "delete")
          ) {
            if (status.name == "Pending" || status.name == "OutOfStock") {
              if (orderTraits) return false;
              else return true;
            } else return false;
          }

          if (
            modelToCheck == "shippings" &&
            status !== null &&
            this.currentUser.type == "Courier" &&
            accessToCheck == "update" &&
            (status == "delivered" ||
              status == "cancelled" ||
              status == "processed")
          ) {
            return false;
          }
          //  if(modelToCheck=="shippings" && status !== null && this.currentUser.type=='StockManager' && accessToCheck=="update"){
          //     if(status!="cancelled") return true;
          //     else return false;

          //  }
          return access[accessToCheck];
        }
      }
    },
    checkNewSellerData() {
      if (
        this.newSeller.role &&
        this.newSeller.username &&
        this.newSeller.password &&
        this.newSeller.email &&
        this.newSeller.company &&
        this.newSeller.type &&
        this.newSeller.category
      )
        return true;
      else return false;
    },
    checkDataShipping() {
      if (this.shipping.address && this.shipping.order && this.shipping.date)
        return true;
      else return false;
    },
    QuantityTotalProductOrder(data) {
      var qte = 0;
      for (let i in data.order.details) {
        qte += data.order.details[i].quantity;
      }
      return qte;
    },
    QuantityTotalProductOrderOnly(data) {
      var qte = 0;
      for (let i in data) {
        qte += data[i].quantity;
      }
      return qte;
    },
    getTotalAmoutPayemnt(data) {
      let total = 0;
      for (let i in data) {
        total += data[i].amount;
      }
      return total;
    },
    async generatePdf(id, content) {
      if (this.config.name != "payments") {
        if (this.tabId.length == 0) {
          alert(`No Shipping selected !`, "warning");
          return false;
        }

        //   var printContents = '<table class="min-w-full" style="page-break-after: avoid; page-break-before: avoid;"><tbody class="bg-white">';
        //   if(this.tabId.length==1){
        //       let modalPr_id = "Modalprint_" + this.tabId[0];
        //       printContents += '<tr style="height: 51mm;" class="bg-white">';
        //       printContents += '<td style="width:50%;" class="px-3 py-1 align-top border border-black">'+document.getElementById(modalPr_id).innerHTML+'</td>';
        //       printContents += '<td style="width:50%;" class="px-3 py-1 align-top"></td></tr>';
        //   }else{
        //    for(let i in this.tabId){
        //        let modalPr_id = "Modalprint_" + this.tabId[i];
        //        if(i % 2 == 0){
        //             printContents += '<tr style="height: 51mm;" class="bg-white">';
        //        }
        //        printContents += '<td style="width:50%;" class="px-1 py-1 align-top border border-black">'+document.getElementById(modalPr_id).innerHTML+'</td>';
        //         if(i % 2 != 0){
        //             printContents += '</tr>';
        //        }
        //    }
        // }
        //     printContents +='</tbody></table>';
        const idsNotprepared = this.tabId.filter(function (item) {
          return item.status != "prepared";
        });
        if (idsNotprepared.length > 0) {
          alert(
            `Delivery not est seulement autorisé pour les shoppings prepared !`,
            "warning"
          );
          return false;
        }



        this.loadingpdf = true;


        var printContents = "";
        var style = "";
        if (this.tabId.length > 1)
          style =
            "height: 101%;border: 1px solid white;page-break-after: avoid; page-break-before: avoid;";
        else
          style =
            "height: 99%;border: 1px solid white;page-break-after: avoid; page-break-before: avoid;";
        for (let i in this.tabId) {

          let modalPr_id = "Modalprint_" + this.tabId[i].id;
          console.log('document.getElementById(modalPr_id)', document.getElementById(modalPr_id))
          console.log('modalPr_id', modalPr_id)

          printContents +=
            '<div class="container shipp-print" style="height: 99%;border: 1px solid white;page-break-after: avoid; page-break-before: avoid;">';
          printContents += document.getElementById(modalPr_id).innerHTML;
          printContents += "</div>";

        }
        //
        var mywindow = window.open("", "PRINT", "height=400,width=600");
        mywindow.document.write(
          '<html lang="en"><head><meta charset="utf-8"><title>DELIVRY NOTE</title>'
        );
        mywindow.document.write(
          '<style type="text/css">.justify-center{justify-content: center;}.barcode-img svg{height:100px}p{margin:0}table{border-collapse: collapse;}table th, table td {border:1px solid #000;}.text-right{text-align:right}.flex-1{flex:1 1 0%}.w-40{width:10rem}.font-medium{font-weight:500}.py-1{padding-top:.25rem;padding-bottom:.25rem}.inline-block{display:inline-block}.mb-3{margin-bottom:.75rem}.py-2{padding-top:.5rem;padding-bottom:.5rem}.ml-auto{margin-left:auto}.border-black{border: 1px solid black;}.border{border-width:1px}.whitespace-no-wrap{white-space:nowrap}.text-center{text-align:center}.w-1/4{width: 25%}.text-left{text-align:left}.px-2{padding-left:.5rem;padding-right:.5rem}.min-w-full{min-width:100%}.mb-5{margin-bottom:1.25rem}.px-1{padding-left:.25rem;padding-right:.25rem}.mx-auto{margin-left:auto;margin-right:auto}body{font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";font-size:1em}.justify-between{justify-content:space-between}.w-full{width:100%}.block{display:block}.flex{display:flex}.uppercase{text-transform: uppercase;}.mb-2{margin-bottom: 0.5rem;}.mb-3{margin-bottom: 0.75rem;}.font-bold{font-weight: 700;}.font-semibold{font-weight:600}.text-xl{font-size: 1.25rem;line-height: 1.75rem;}.size-m{font-size: 12px;font-weight: 500;}@media print {@page {size:  auto; margin: 0mm;}}</style>'
        );
        mywindow.document.write('</head><body style="margin:2mm">');
        mywindow.document.write(printContents);
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/


        this.loadingpdf = false;


        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 500);

        return false;
      } else {
        if (this.tabId.length == 0) {
          alert(this.$t("no_payement_selected"), "warning");
          return false;
        }
        const doc = new jsPDF("p", "pt");
        const fontSizes = {
          TitleFontSize: 14,
          SubTitleFontSize: 12,
          NormalFontSize: 10,
          SmallFontSize: 9,
        };
        const lineSpacing = 12;
        let items;
        let expeditions;
        for (let k in this.tabId) {
          const filter = {
            _id: this.tabId[k],
          };
          const res = await this.$server.search("payments", filter);
          if (res.content.results) {
            items = res.content.results[0].details;
            expeditions = res.content.results[0].expeditions;
          }

          let expeditionsCount = 0;
          //let expeditions=content.expeditions;
          for (let i in expeditions) {
            expeditionsCount++;
          }
          let data = [];
          let dataExp = [];
          let i = 1;
          items.forEach((el) => {
            let obj = [
              i,
              el.order.id,
              el.order.customer.fullName,
              el.order.totalPrice,
              el.confirmation_vat,
              el.shipping_vat,
              el.shipping_refund_vat,
              el.shipping_change_vat,
              el.amount,
            ];
            i++;
            data.push(obj);
          });
          doc.autoTable({
            headerStyles: { fillColor: [0, 0, 0] },
            theme: "grid",
            head: [
              [
                "#",
                "ID",
                this.$t("customer"),
                this.$t("total_price"),
                this.$t("confirmation_fees"),
                this.$t("shipping_fees"),
                this.$t("refund_fees"),
                this.$t("change_fees"),
                this.$t("total"),
              ],
            ],
            margin: { top: 150 },
            body: data,
          });
          //Expedition fees
          if (expeditionsCount > 0) {
            doc.setFont("normal");
            doc.setFontSize(fontSizes.SubTitleFontSize);
            doc.text("Expeditions: ", 40, doc.autoTable.previous.finalY + 15);
            let j = 1;
            expeditions.forEach((el) => {
              let obj = [
                j,
                el.id.numberOfPackages,
                el.id.transportMode,
                el.id.details.length + " " + this.$t("products"),
                el.id.carrier.name,
                el.id.country,
                el.fees,
              ];
              j++;
              dataExp.push(obj);
            });
            doc.autoTable({
              // headerStyles: {fillColor: [0, 0, 0]},
              theme: "striped",
              head: [
                [
                  "#",
                  this.$t("nb_packages"),
                  this.$t("transport_mode"),
                  this.$t("details"),
                  this.$t("carrier"),
                  this.$t("country"),
                  this.$t("expedition_fees"),
                ],
              ],
              margin: { top: doc.autoTable.previous.finalY + 30 },
              body: dataExp,
            });
          }
          let startX = 40;
          const pageWidth = doc.internal.pageSize.width;
          const endX = pageWidth - startX;
          doc.setFont("arial");
          doc.setFontSize(fontSizes.SubTitleFontSize);

          // set fix value for Y to bring title in alignment with folding marks
          let startY = 50;
          doc.text(this.$t("payement_no") + " ", startX, startY);

          doc.setFont("bold");

          startX +=
            doc.getStringUnitWidth("Payement NO: ") *
            fontSizes.SubTitleFontSize;
          doc.text(res.content.results[0].id, startX, startY);

          doc.setFont("normal");
          doc.text(
            this.$moment(res.content.results[0].date).format("yyyy-MM-DD"),
            endX,
            startY,
            "right"
          );
          startY = 33;
          startY += lineSpacing * 2;
          startX = 40;
          doc.text(
            this.$t("seller") + ": " + res.content.results[0].seller.email,
            startX,
            (startY += lineSpacing + 2)
          );
          doc.text(
            this.$t("payement_methode") + ": " + res.content.results[0].method,
            startX,
            (startY += lineSpacing * 2)
          );

          //total amount orders
          startY += lineSpacing * 2;
          doc.text(
            this.$t("total") +
            ": " +
            this.getTotalAmoutPayemnt(res.content.results[0].details),
            endX,
            doc.autoTable.previous.finalY + 20,
            "right"
          );
          doc.text(
            this.$t("tva") + ": " + res.content.results[0].vat + " %",
            endX,
            doc.autoTable.previous.finalY + 40,
            "right"
          );
          doc.text(
            this.$t("transfert_fees") +
            ": " +
            res.content.results[0].transfert_fees,
            endX,
            doc.autoTable.previous.finalY + 60,
            "right"
          );
          doc.text(
            this.$t("grand_total") + ": " + res.content.results[0].amount,
            endX,
            doc.autoTable.previous.finalY + 80,
            "right"
          );
          doc.setLineWidth(0.5);
          startY += 4;
          doc.line(startX - 1, startY, endX + 1, startY);
          startY += 2;
          doc.line(startX - 2, startY, endX + 2, startY);
          startY += 20;
          doc.text(this.$t("orders") + ": ", startX, startY);
          // doc.line(450 , doc.autoTable.previous.finalY+30, endX + 2, doc.autoTable.previous.finalY+28);
          doc.addPage();
        }
        //delte black page
        var pageCount = doc.internal.getNumberOfPages();
        doc.deletePage(pageCount);
        doc.save("Payement.pdf");
      }
    },
  },
};
</script>

<!--  ************  -->
<style>
.dropdown-limit {
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.label-accept {
  background: #e22ae6;
}

.label-paid {
  background: #c2a81b;
}

.jvectormap-tip {
  padding: 3px 6px 3px;
  font-size: 10px;
  border-radius: 50px;
  text-transform: capitalize;
  font-weight: 400;
  display: block;
  margin-top: 4px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.vs-dropdown--limit--after {
  top: 0;
  right: 10px;
  position: absolute;
  width: 10px;
  height: 10px;
  display: block;
  background: #fff;
  transform: rotate(45deg) translate(-7px);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.prova:after {
  content: attr(data-ribbon);
  position: absolute;
  width: 90px;
  height: 40px;
  background: #428bca;
  color: white;
  top: -5px;
  text-align: center;
  line-height: 50px;
  right: -34px;
  transform: rotate(45deg);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked~.toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}

.empty-row {
  background: repeating-linear-gradient(53deg,
      #bfc0c2 4%,
      #fcfcff 11px 6%,
      #ffffff 10px 7%,
      #9d9c9c 15px);
}

.dropdown-action {
  width: 30% !important;
}

.border-teal {
  border-color: #4dc0b5;
}

.bg-teal-lightest {
  background-color: #e8fffe;
}

.text-red {
  color: #e3342f;
}

i.tiny {
  font-size: 12px;
  line-height: 20px;
}

i.small {
  font-size: 2rem;
}

.table-shipp,
.table-shipp thead {
  border: 2px solid #2c84c7;
}

.bg-light-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
  border-bottom: 1px solid #fff;
}

#loading {
  position: relative;
}

dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
